<script setup>

</script>

<template>
<v-card class="card">

    <Skeletor width="100%" height="50" />

</v-card>
</template>

<style scoped>

.card {
    background: var(--primary-color);
    box-shadow: rgb(0 0 0 / 25%) 0px 3px 8px;
    overflow: hidden;
    width: 412px!important;
    background-color: var(--primary-color);
    margin: 10px;
    height: 50px;
}
</style>
