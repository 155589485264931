<script setup>

    import { toRefs } from 'vue'

    const props = defineProps({
    data: Object
    })
    const {data} = toRefs(props)

</script>

<template>
  <v-card
    class="card"
    width="240"
  >
    <a :href="'https://teespring.com/' + data.url" target="_blank">
    <v-img
      :src="data.image_url"
      height="240"
    ></v-img>

    <v-card-title :title="data.product_name">
      {{ data.product_name }}
    </v-card-title>

    <v-card-subtitle>
      {{ data.name }}
    </v-card-subtitle>

    <v-card-actions>
      <v-btn
        variant="outlined"
      >
      {{ data.price }}
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn
        variant="outlined"
      >
      <v-icon icon="mdi-cart"></v-icon>
      </v-btn>
    </v-card-actions>
    </a>
  </v-card>
</template>

<style scoped>
    .card {
        background: var(--primary-color);
        margin: 25px;
    }

    .v-card-actions {
        margin: 10px 10px 0 10px;
    }
</style>