<script setup>

    import { toRefs } from 'vue'

    const props = defineProps({
    data: Object,
    test: Boolean
    })
    const {data, test} = toRefs(props)

</script>

<template>
  <v-card
    class="card"
    width="310"
  >

    <v-img
      :src="'https://cdn.nightriderz.world/images/website/moonset/cars/' + data.store_name + '.png'"
      height="135" style="margin-top: 6px;margin-left: 6px;margin-right: 6px;border-radius: 4px;"
    ></v-img>


    <v-card-subtitle style="background: #1f2430;margin-left: 6px;margin-right: 6px;margin-top: 6px;border-radius:4px">
      {{ data.manufactor }} {{ data.model }}
    </v-card-subtitle>
    <v-card-subtitle v-if="test" style="background: #1f2430;margin-left: 6px;margin-right: 6px;margin-top: 6px;border-radius:4px">
      Store ID: {{ data.store_name }}
    </v-card-subtitle>
    <v-card-subtitle v-if="test" style="background: #1f2430;margin-left: 6px;margin-right: 6px;margin-top: 6px;border-radius:4px;margin-bottom: 6px;">
      Physic hash: {{ data.hash }}
    </v-card-subtitle>

  </v-card>
</template>

<style scoped>

.v-card-subtitle {
    background: var(--primary-color);
    opacity: 1;
    display: flex;
    justify-content: center;
    padding: 10px 0;
    font-size: 12px;
    font-weight: bold;
    margin-bottom: 6px;
}
    .card {
        background: var(--primary-color);
        margin: 25px;
    }

    .v-card-actions {
        margin: 10px 10px 0 10px;
    }
</style>