<script setup>

</script>

<template>
<div class="livemap">
    <iframe src="https://livemap.nightriderz.world/"></iframe>
</div>
</template>

<style scoped>
.livemap {
    position: absolute;
    left: 60px;
    top: 0;
    bottom: 0;
    right: 0;
    overflow: hidden;
}

iframe {
    border: none;
    overflow: hidden;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
}
</style>
