<script setup>
</script>

<template>
    <div class="p404">
        <h1>404 - Page Not Found</h1>
    </div>
</template>

<style scoped>
    .p404 {
        background: url(https://cdn.nightriderz.world/images/website/moonset/404.png) center center;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        align-items: center;
        justify-content: center;
    }
</style>