<script setup>

    import { ref, getCurrentInstance } from 'vue'
    import carCard from '../components/carCard.vue'
import NewCard from '../components/newCard.vue'
import SKcarCard from '../components/skeletons/SKcarCard.vue'

    const { proxy } = getCurrentInstance()
    const items = ref([])
    const cars = ref([])

    getNewContent()
    getNewCars()

    async function getNewContent() {
        const res = await proxy.$api({serviceName: "server", methodName: "showcase_stats"})
        items.value = res
    }

    async function getNewCars() {
        const res = await proxy.$api({serviceName: "server", methodName: "showcase_cars"})
        cars.value = res
    }


</script>

<template>

    <v-app-bar :elevation="2">
        <v-icon icon="mdi-fire"></v-icon>
        <v-app-bar-title>New Content</v-app-bar-title>
        <template v-slot:extension>
            We are regularly updating the server to add in-game content. You can find all patch notes on Discord.
        </template>
    </v-app-bar>
        
    <div class="grid">
        <NewCard v-for="item in items" :id="item.categoyName" :data="item"/>
    </div>

    
    <transition name="fade" mode="out-in">
        <div key=1 class="grid" v-if="!cars.loop">
            <SKcarCard v-for="i in 12"/>
        </div>

        <div key=2 class="grid" v-else>
            <carCard v-for="car in cars.loop" :id="car.store_name" :data="car"/>         
        </div>
    </transition>

</template>

<style scoped>
    .grid {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin-top: 20px;
        margin-bottom: 20px;
        max-width: 1400px;
        margin: 0 auto;
    }
</style>