<script setup>
import { ref, getCurrentInstance, onMounted, onBeforeUnmount } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import DriverCard from '../components/driverCard.vue'

const route = useRoute()
const router = useRouter()
const { proxy } = getCurrentInstance()
const contributors = ref([])
const event = ref({})
const pourcent = ref(0)
const tab = ref(1)
const prize = ref([])

getEvent()
getContributors()

async function getEvent() {
  const res = await proxy.$api({
    serviceName: "events",
    methodName: "getEvent",
    parameters: [route.params.name]
  })
  event.value = res
  event.value.nb_prize = parseInt(res.nb_prize)
  event.value.nb_conditions = parseInt(res.nb_conditions)

  prize.value = JSON.parse(res.prize)

  pourcent.value = (res.nb_conditions) / res.nb_goal * 100
}

async function getContributors() {
  const res = await proxy.$api({
    serviceName: "events",
    methodName: "getContributors",
    parameters: [route.params.name]
  })
  contributors.value = res
}

function refresh() {
  getEvent()
  getContributors()
}

// Auto-refresh every 10 seconds
let intervalId = null;

onMounted(() => {
  intervalId = setInterval(refresh, 10000); // 10 seconds
});

onBeforeUnmount(() => {
  if (intervalId) {
    clearInterval(intervalId);
  }
});

</script>

<template>
  <v-container fluid>
    <v-app-bar :elevation="2">
      <v-icon icon="mdi-fire"></v-icon>
      <v-app-bar-title>
        {{ event.title }}
        <v-spacer></v-spacer>
        <v-btn variant="tonal" @click="refresh">REFRESH</v-btn>
      </v-app-bar-title>
      <template v-slot:extension>
        {{ event.date }}
      </template>
    </v-app-bar>

    <v-window v-model="tab">
      <v-window-item value="0">
        <v-container class="my-5">
          <v-card class="mb-5" style="background: var(--primary-color);border-radius: 6px;">
            <v-card-text v-html="event.description"></v-card-text>
          </v-card>

          <v-card class="mb-5" style="background: var(--primary-color);border-radius: 6px;">
            <v-card-text>
              <v-progress-linear v-model="pourcent" color="blue-darken-3" height="50" striped style="border-radius: 4px;">
                <template v-slot:default="{ value }">
                  <div class="d-flex justify-center" style="background: var(--primary-color);padding-left: 4px;padding-right: 4px;border-radius: 4px;">
                    <span class="font-weight-bold">{{ number_format(event.nb_conditions) }}</span> OF 
                    <span class="font-weight-bold mx-1">{{ number_format(event.nb_goal) }}</span> {{ event.name_condition }}
                  </div>
                </template>
              </v-progress-linear>
            </v-card-text>
          </v-card>
<!--REWARD #{{ index }}-->
          <v-row v-if="event.nb_prize" class="mb-10 justify-center">
            <v-col v-for="index in event.nb_prize" :key="index" class="d-flex justify-center">
              <v-card class="prize-card" style="background: var(--primary-color);border-radius: 6px;">
                <v-card style="font-size: 16px;font-weight: 600;margin-top: 6px;margin-left: 6px; margin-right: 6px;box-shadow: none" class="text-center">{{ prize[index - 1].text }}</v-card>
                  <v-img :src="prize[index - 1].image" class="d-flex justify-center align-center" style="size:cover;margin-left: 8px;margin-right: 8px;margin-top: 8px;border-radius: 4px;"></v-img>
                <v-card-actions>
                    <v-btn style="width: 100%; background: #FF3333; opacity: 1" v-if="(Math.floor(event.nb_goal / event.nb_prize) * index) >= (event.nb_conditions + 1)" variant="tonal" prepend-icon="mdi-lock" disabled>UNLOCKED AT {{ number_format(Math.floor(Math.floor(event.nb_goal) / event.nb_prize) * index) }}</v-btn>
                    <v-btn style="width: 100%; background: #01B636" v-else variant="tonal" prepend-icon="mdi-lock-open">REWARD UNLOCKED</v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>

          <!-- Contributeurs -->
          <div class="d-flex text-h4 justify-left" style="margin-top: 100px; margin-bottom: -35px; font-size: 24px!important; padding-left: 12%" v-if="contributors.length > 0">
            Contributors
          </div>

          <div class="grid" style="margin-top: 30px; max-width: 1500px;">
            <DriverCard v-for="contributor in contributors" :data="contributor" :event="event.name_condition_driver" />
            {{ event.name_condition_driver }}
          </div>
        </v-container>
      </v-window-item>
    </v-window>
  </v-container>
</template>

<style scoped>
.prize-card {
  background: var(--primary-color);
  width: 400px!important; /* Fixed width */
  height: 265px!important; /* Fixed height */
}

.grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 40px;
  margin-bottom: 20px;
  margin: 0 auto;
}

.prize {
  background: var(--primary-color);
  justify-content: space-between;
  max-width: 1400px;
}
</style>
