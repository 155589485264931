<template>
  <v-snackbar
    v-model="snackbar"
    location="top"
    transition="v-snackbar-transition"
    :color="'#4f1bff'"
    :centered="true"
    :elevation="3"
    :timeout="-1"
    style="margin-left: 60px; display: flex; align-items: center;">
    <v-icon @click=closeSnackbar style="margin-right: 10px;">mdi-information</v-icon>
    <span v-if="notificationMessage" v-html="notificationMessage.message" style="flex-grow: 1;"></span>
  </v-snackbar>
</template>

<script setup>
import { ref, getCurrentInstance, onMounted } from 'vue';

const { proxy } = getCurrentInstance();

const snackbar = ref(false);  // Initialement caché jusqu'à ce que le message soit chargé
const notificationMessage = ref(null);  // Contenu du message de notification

onMounted(() => {
  getNotificationMessage();
});

async function getNotificationMessage() {
  try {
    const res = await proxy.$api({
      serviceName: "notification",
      methodName: "getActiveNotification"
    });
    
    const currentDate = new Date();
    const startDate = new Date(res.startDate);
    const stopDate = new Date(res.stopDate);

    if (currentDate >= startDate && currentDate <= stopDate) {
      notificationMessage.value = res;
      snackbar.value = true;  // Affiche le snackbar
    } else {
      snackbar.value = false; // Cache le snackbar si en dehors de la plage de dates
    }
  } catch (error) {
    console.error('Erreur lors de la récupération de la notification:', error);
  }
}

function closeSnackbar() {
  snackbar.value = false;  // Ferme le snackbar manuellement
}
</script>

<style scoped>
</style>
