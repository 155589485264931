<script setup>
    import { toRefs } from 'vue'

    const props = defineProps({
    data: Object
    })
    const {data} = toRefs(props)
</script>

<template>

<div class="achievement_card">
    <div :style="'background:url(https://cdn.nightriderz.world/images/website/icon-achievement/' + data.border +'.png) center no-repeat,url(https://cdn.nightriderz.world/images/website/icon-achievement/' + data.background + '.png) center no-repeat;width:80px;'">
        <img :src="'https://cdn.nightriderz.world/images/website/icon-achievement/' + data.icon + '.png'" style="margin: 17px;width: 45px;">
    </div>
    <div>
        <h4>{{data.title}}</h4>
        <p style="margin: -1px 0 5px 0px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;max-width: 280px;">{{data.text}}</p>
        <span style="width:initial;margin-right: 5px;">
            <v-icon>mdi-alpha-d-circle-outline</v-icon> {{data.points}}
        </span>
        <span style="width:initial;">
            <v-icon>mdi-clock-outline</v-icon> {{data.date}}
        </span>
    </div>
</div>
</template>

<style scoped>
        h4 {
            margin: 3px 0px 0px;
            font-size: 20px;
            font-weight: initial;
        }

        .achievement_card {
            overflow: hidden;
            background: white;
            width: 378px;
            margin: 5px;
            background-color: #0b131e;
            display:flex;
            border-radius: 6px;
        }

        div {
            height: 80px;
            overflow: hidden;
            position: relative;
            color: white;
            margin-left: 5px;
        }

        span {
            width: 70px;
            display: inline-block;
            margin-bottom: 2px;
            zoom: 0.8;
        }

        p {
            zoom: 0.8;
            line-height: 15px;
            margin-top: 5px;
        }
</style>
