<script setup>
import drawerMenu from "./components/drawer.vue"
import Footer from "./components/Footer.vue"
import Notification from "./components/notification.vue"
import { useRoute } from 'vue-router'
import router from './router/index.js'

import {
    getCurrentInstance
} from 'vue'
import {
    DriverStore
} from './pinia/Driver.js'
import {
    ServerStore
} from './pinia/Server.js'

const route = useRoute()
const Driver = DriverStore()
const Server = ServerStore()
const {
    proxy
} = getCurrentInstance()
const dialog = true

/*
GetUserInfo()

async function GetUserInfo() {
    const res = await proxy.$api({serviceName: "session",methodName: "GetUserInfo"})

    Driver.setPersona(res.persona)
    Driver.setUser(res.user)
    Driver.setCrew(res.crew)
    Driver.setCurrentCrew(res.crew.current)
    Driver.setOthers(res.others)
    Driver.setLogged(true)
}*/
</script>

<template>
<v-card style="flex:1;">
    <v-layout style="display: flex;">
        <v-navigation-drawer mini rail rail-width="60" permanent v-if="route.path != '/discord'">
            <RouterLink to="/" style="height: 55px;">
                <img src="https://cdn.nightriderz.world/images/website/logo/NRZ_Normal.svg" style="padding: 5px;width: 55px;margin-top:10px" alt="NIGHTRIDERZ small logo">
            </RouterLink>
            <v-divider></v-divider>

            <drawerMenu />
        </v-navigation-drawer>

        <v-main>
            <Notification />
            <routerView style="top:0!important"/>
            <v-spacer></v-spacer>
            <Footer :isHome="route.path == '/'" v-if="route.path != '/livemap' && route.path != '/404' && !route.path.includes('leaderboard/') && route.path != '/discord'" />
        </v-main>

    </v-layout>

</v-card>
</template>

<style>

.v-main {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    flex:1;
}

footer {
    flex: initial!important;
}


a {
    color: white !important;
}

.v-divider {
    border-color: white !important;
}

code {
    background: black;
    padding: 3px;
}

.v-overlay__scrim {
    background: black !important;
    opacity: 50% !important;
}

.v-app-bar,
.v-toolbar {
    background: var(--primary-color) !important;
    padding-left: 20px;
    padding-right: 20px !important;
}

.v-navigation-drawer {
    border-right: solid 1px rgba(255, 255, 255, 0.2) !important;
    background: var(--primary-color) !important;
}

.v-navigation-drawer__content {
    display: flex;
    flex-direction: column;
    align-items: center;
}

#app {
    display: flex;
    min-height: 100vh;
}

.routerview {
    background: var(--secondary-color);
    flex: 1;
}

* {
    font-family: "Arial";
    line-height: 1.5;
    --primary-color: #11141c;
    --secondary-color: #1f2430;
    --tertiary-color: linear-gradient(90deg, rgba(136, 0, 255, 1) 0%, rgba(0, 65, 255, 1) 100%);
    --quaternary-color: linear-gradient(90deg, rgba(63, 0, 116, 1) 0%, rgba(0, 30, 116, 1) 100%);
    text-decoration: none;
}

*::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
}

*::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: rgba(178, 178, 178, 0.5);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}

*::-webkit-scrollbar-track {
    background: black;
}

body {
    margin: 0;
    padding: 0;
    background-color: #090b0d;
}

.container {
    display: flex;
    flex: 1;
}

.spacer {
    flex: 1;
}

button {
    background: var(--tertiary-color);
    font-size: 18px;
    border-radius: 6px;
    padding: 4px 16px;
    color: white;
    text-align: center;
    border: none;
    font-weight: 500;
    letter-spacing: 0;
    cursor: pointer;
    text-decoration: none;
    line-height: 36px;
    vertical-align: middle;
}

table {
    background: var(--secondary-color) !important;
}

button:hover {
    background: var(--quaternary-color);
    color: #f1f2f3;
}

h5 {
    font-size: 20px;
    font-weight: 500;
    line-height: 1;
    letter-spacing: .02em;
    margin: 16px 0;
}

.v-popper__popper {
    z-index: 10000;
    top: 0;
    left: 0;
    outline: none;
    margin-top: 2px;
}

.v-popper__popper.v-popper__popper--hidden {
    visibility: hidden;
    opacity: 0;
    transition: opacity .15s, visibility .15s;
    pointer-events: none
}

.v-popper__popper.v-popper__popper--shown {
    visibility: visible;
    opacity: 1;
    transition: opacity .15s
}

.v-popper__popper.v-popper__popper--skip-transition,
.v-popper__popper.v-popper__popper--skip-transition>.v-popper__wrapper {
    transition: none !important
}

.v-popper__backdrop {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: none
}

.v-popper__inner {
    position: relative;
    box-sizing: border-box;
    overflow-y: auto
}

.v-popper__inner>div {
    position: relative;
    z-index: 1;
    max-width: inherit;
    max-height: inherit
}

.v-popper__arrow-container {
    position: absolute;
    width: 10px;
    height: 10px
}

.v-popper__popper--arrow-overflow .v-popper__arrow-container,
.v-popper__popper--no-positioning .v-popper__arrow-container {
    display: none
}

.v-popper__arrow-inner,
.v-popper__arrow-outer {
    border-style: solid;
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 0
}

.v-popper__arrow-inner {
    visibility: hidden;
    border-width: 7px
}

.v-popper__arrow-outer {
    border-width: 6px
}

.v-popper__popper[data-popper-placement^=bottom] .v-popper__arrow-inner {
    left: -2px
}

.v-popper__popper[data-popper-placement^=bottom] .v-popper__arrow-outer {
    left: -1px
}

.v-popper__popper[data-popper-placement^=bottom] .v-popper__arrow-container {
    top: 0
}

.v-popper__popper[data-popper-placement^=bottom] .v-popper__arrow-inner,
.v-popper__popper[data-popper-placement^=bottom] .v-popper__arrow-outer {
    border-top-width: 0;
    border-left-color: transparent !important;
    border-right-color: transparent !important;
    border-top-color: transparent !important
}

.v-popper__popper[data-popper-placement^=bottom] .v-popper__arrow-inner {
    top: -4px
}

.v-popper__popper[data-popper-placement^=bottom] .v-popper__arrow-outer {
    top: -6px
}

.v-popper--theme-dropdown .v-popper__inner {
    background: #fff;
    color: #000;
    border-radius: 6px;
    border: 1px solid #ddd;
    box-shadow: 0 6px 30px #0000001a
}

.v-popper--theme-dropdown .v-popper__arrow-inner {
    visibility: visible;
    border-color: #fff
}

.v-popper--theme-dropdown .v-popper__arrow-outer {
    border-color: #ddd
}

.v-popper--theme-tooltip .v-popper__inner {
    background: black;
    color: #fff;
    border-radius: 6px;
    padding: 7px 12px 6px;
    font-size: 12px;
}

.v-popper--theme-tooltip .v-popper__arrow-outer {
    border-color: #000c
}

.VuePagination__pagination {
    display: inline-flex;
}

.VuePagination__pagination button.active {
    background: pink;
}

.v-alert {
    margin: 10px;
    display: flex !important;
    justify-content: center;
    align-items: center;
}

.v-alert__prepend {
    align-self: center !important;
}

.v-list-item-title {
    font-size: 18px !important;
    font-weight: bold !important;
    margin-bottom: 20px;
}
</style>
