<script setup>
import {
    getCurrentInstance,
    ref
} from 'vue'
import {
    DriverStore
} from '../pinia/Driver.js'
import {
    useRoute,
    useRouter
} from 'vue-router'

const {
    proxy
} = getCurrentInstance()
const Driver = DriverStore()

var Mail = ref("")
var RMail = ref("")
const MailF = ref("")
const tab = ref()
var Password = ref("")
var RPassword = ref("")
var RPassword2 = ref("")
var RNickname = ref("")
const resetp = ref("")
const registeracc = ref("")
const errorLogin = ref('') // Nouvelle variable d'erreur pour le composant "Login"
const errorRegister = ref('') // Nouvelle variable d'erreur pour le composant "Register"
const router = useRouter()
const registerClicked = ref(false)

async function reset() {
    const res = await proxy.$api({ "serviceName": "session", "methodName": "forgot", "parameters": [MailF.value] })
    resetp.value = true
}

function login() {

    proxy.$http.post("", JSON.stringify({
        serviceName: "session",
        methodName: "login",
        parameters: [Mail.value, Password.value, ""]
    })).then(response => {

        Driver.setEasharpptr_p(response.data.easharpptr_p)
        Driver.setEasharpptr_u(response.data.easharpptr_u)
        Driver.setPersona(response.data.persona)
        Driver.setLogged(true)
        Driver.showDrawer(false)

        GetUserInfo()
        errorLogin.value = "" // Mettre à jour la variable d'erreur du composant "Login"

    }).catch(function (e) {
        errorLogin.value = e.response.data // Mettre à jour la variable d'erreur du composant "Login"
    })

}

async function register() {
    if (registerClicked.value) return; // Prevent function execution if already clicked

    registerClicked.value = true; // Disable the button immediately when clicked

    // Timer to re-enable the button after 5 seconds
    setTimeout(() => {
        registerClicked.value = false;
        console.log('Button enabled after 5 seconds');
    }, 5000); // 5 seconds delay

    // Check if passwords are identical
    if (RPassword.value !== RPassword2.value) {
        errorRegister.value = 'Passwords do not match.';
        return;
    }

    // Regular expression to validate the password format
    const passwordRegex = new RegExp("^(?=.*[A-Z])(?=.*\\d)(?=.*[!@#$%^&*()\\-_=+{};:,<.>]).{8,}$");

    // Check if the password meets the required format
    if (!passwordRegex.test(RPassword.value)) {
        errorRegister.value = 'Password must contain at least 8 characters, including 1 uppercase letter, 1 digit, and 1 special character.';
        return;
    }

    // If everything is correct, proceed with the API call
    try {
        const res = await proxy.$api({ "serviceName": "session", "methodName": "registerUser", "parameters": [RMail.value, RPassword.value, ""] });
        errorRegister.value = res;
    } catch (e) {
        errorRegister.value = 'An error occurred during registration.';
        setTimeout(() => {
            registerClicked.value = false; // Ensure button is re-enabled if an error occurs
        }, 5000); // 5 seconds delay
    }
}


async function GetUserInfo() {
    const res = await proxy.$api({
        serviceName: "session",
        methodName: "GetUserInfo"
    })

    Driver.setPersona(res.persona)
    Driver.setUser(res.user)
    Driver.setCrew(res.crew)
    Driver.setCurrentCrew(res.crew.current)
    Driver.setOthers(res.others)
    Driver.setLogged(true)

    if (Driver.drawerTo) {
        router.push(Driver.drawerTo)
        Driver.drawerTo = ""
    }

}

function disconnect() {
    Driver.setUser({
        'premium': "0"
    })
}
</script>

<template>
    <v-btn v-if="Driver.logged" id="menu" style="color:red" @click="Driver.disconnect(); disconnect()"
        icon="mdi-logout"></v-btn>

    <v-btn v-if="!Driver.logged" id="menu" @click="Driver.showDrawer(true)" icon="mdi-login"></v-btn>

    <v-dialog transition="dialog-bottom-transition" :width="545" v-model="Driver.drawer">
        <v-card>

            <v-toolbar v-if="Driver.drawerTo" color="primary">
                <v-icon>mdi-login</v-icon>
                <v-toolbar-title>You must be connected</v-toolbar-title>
                <v-spacer></v-spacer>
                <template v-slot:extension>
                    <v-tabs v-model="tab" centered>
                        <v-tab value="1">
                            Sign in
                        </v-tab>
                        <v-tab value="2">
                            Register
                        </v-tab>
                        <v-tab value="3">
                            Forgot Password
                        </v-tab>
                    </v-tabs>
                </template>
            </v-toolbar>
            <v-toolbar v-else dark color="primary">
                <v-icon>mdi-login</v-icon>
                <v-toolbar-title>Sign in</v-toolbar-title>
                <v-spacer></v-spacer>
                <template v-slot:extension>
                    <v-tabs v-model="tab" centered>
                        <v-tab value="1">
                            Sign in
                        </v-tab>
                        <v-tab value="2">
                            Register
                        </v-tab>
                        <v-tab value="3">
                            Forgot Password
                        </v-tab>
                    </v-tabs>
                </template>
            </v-toolbar>
            <v-card-text>

                <v-window v-model="tab">
                    <v-window-item value="1">
                        <v-card>
                            <v-card-text>
                                <p>In order to sign-in to our website you have to register an account via the regiter
                                    tab.</p>
                                <br>
                                <v-alert class="error" v-if="errorLogin" :text="errorLogin" type="error"></v-alert>
                                <v-text-field prepend-icon="mdi-email" v-model="Mail" label="E-Mail"
                                    variant="outlined"></v-text-field>

                                <v-text-field prepend-icon="mdi-lock" type="password" v-model="Password"
                                    label="Password" variant="outlined"></v-text-field>

                                <div class="d-flex save">
                                    <v-spacer />
                                    <v-btn variant="tonal" @click="login">SIGN IN</v-btn>
                                </div>
                            </v-card-text>
                        </v-card>
                    </v-window-item>
                    <v-window-item value="2">
                        <v-card>
                            <v-alert class="error" :text="errorRegister" v-if="errorRegister" type="info"></v-alert>
                            <v-card-text>
                                <v-text-field prepend-icon="mdi-email" v-model="RMail" label="E-Mail"
                                    variant="outlined"></v-text-field>
                                <v-text-field prepend-icon="mdi-lock" type="password" v-model="RPassword"
                                    label="Password" variant="outlined"></v-text-field>
                                <v-text-field prepend-icon="mdi-lock" type="password" v-model="RPassword2"
                                    label="Confirm password" variant="outlined"></v-text-field>
                                <!--<v-text-field prepend-icon="mdi-racing-helmet" v-model="RNickname"
                                    label="Nickname" variant="outlined"></v-text-field>-->

                                <div class="d-flex save">
                                    <v-spacer />
                                    <v-btn variant="tonal" @click="register" :disabled="registerClicked">REGISTER</v-btn>
                                </div>
                            </v-card-text>
                        </v-card>
                    </v-window-item>
                    <v-window-item value="3">
                        <v-card>
                            <v-card-text>

                                <v-alert class="error" v-if="resetp"
                                    text="If this account exists in our database, you will receive an email to reset your password very soon. Check your inbox and spams."
                                    type="success"></v-alert>

                                <v-text-field prepend-icon="mdi-email" v-model="MailF" label="E-Mail"
                                    variant="outlined"></v-text-field>

                                <div class="d-flex save">
                                    <v-spacer />
                                    <v-btn variant="tonal" @click="reset">RESET</v-btn>
                                </div>
                            </v-card-text>
                        </v-card>
                    </v-window-item>
                </v-window>

            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<style>
input:-webkit-autofill,
input:-webkit-autofill:focus {
    transition: background-color 600000s 0s, color 600000s 0s !important
}

#menu {
    margin-bottom: 10px !important;
}

.save {
    margin-bottom: 10px;
}

.error {
    margin-bottom: 30px !important;
}
</style>
