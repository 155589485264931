<script setup>

</script>

<template>
    <p>Please note, the controllers only work for driving. It does not work in menus. <br>
        If your controller is in the list of supporting controllers, check that you do not have more than one
        controller plugged into your computer. </p>
        <br>
        <div>
            <h3>Unofficial Xbox 360/One controllers</h3>
            <v-divider></v-divider><br>
            <div>
                <p>
                    Open the start menu and enter <code>joy.cpl</code>. (If you have more than one controller plugged
                    in, unplug the other front controllers.) <br>
                    Keep the name in a notepad. <br>
                    Open the start menu and enter <code>regedit</code>. <br>
                    Open <code>Registry Editor</code>. <br>
                    Navigate to
                    <code>HKEY_CURRENT_USER\System\CurrentControlSet\Control\MediaProperties\PrivateProperties\Joystick\OEM\</code>.
                    <br>
                    Then you have <code>VID _...</code> folders, each folder corresponds to a controller that you have
                    already used on your computer. <br>
                    Look for the one that contains the name found in the <code>joy.cpl</code> window. <br>
                    Right click on <code>OEMName &gt; Modify...</code> then rename to
                    <code>Controller (XBOX 360 For Windows).</code>
                    Try your controller on World (only for driving). <br>
                    If it works, try it on others games. <br>
                    If it no longer works on other games, reset the name to default (saved in notepad) then follow the
                    <code>Other controllers</code> guide.
                </p>
            </div>
        </div>
        <br>
        <div>
            <h3> Other controllers </h3>
            <v-divider></v-divider><br>
            <div>
                <p>We are going to create a virtual controller.</p>
                <div>
                    <p>
                        Download <code>XOutput.zip</code> <a
                            href="https://github.com/csutorasa/XOutput/releases/tag/3.32" target="_blank"> here</a>.
                        <br>
                        Extract the contents of the archive. <br>
                        Open <code>XOutput.exe</code>. <br>
                        In the <code>Game controllers</code> part, click on <code>Add a controller</code>. <br>
                        A new line has appeared <code>controller</code>, click on <code>Edit</code>. <br>
                        Scroll down to the bottom of the window, in the <code>Other parameters</code> field, edit
                        <code>controller</code> to <code>Controller (XBOX 360 For Windows)</code>. <br>
                        Click on <code>Configure All</code>. <br>
                        Follow the steps to define the buttons on your controller. <br>
                        Once the mapping is complete, close the window. <br>
                        In the <code>Game Controllers</code> part, on the <code>Controller (XBOX 360 For Windows)</code>
                        line, click on <code>Start</code>. <br>
                        Finally, go to the <code>Tools &gt; Settings</code> menu, check
                        <code>Disable automatic device status detection</code>, otherwise your mouse will freeze from
                        time to time. <br>
                        You can now try on World (only for driving).
                    </p>
                </div>
            </div>
        </div>
</template>

<style scoped>
    p {
        line-height: 25px;
        font-size: 15px;
    }
</style>