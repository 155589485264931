import { defineStore } from "pinia"

var classes = []
var launchers = []

const ServerStore = defineStore('ServerStore', {
    state: () => {
        return {
          classes, launchers
        }
    },
    actions: {
        getClassesRanking() {
            var classs = this.classes.reverse()
            var res = ['ALL']
            classs.forEach((e,i)=> {
                res.push(e.name.replace('CLASS ', ''))

                if(i == classs.length) return res
            })
            
        },
        getLetterByHash(e) {
            var classs = this.classes.findIndex((item) => item.hash === e)
            if(classs != -1)
                return this.classes[classs].name.replace('CLASS ', '')
            else 
                return "O"
        },
        getHashByLetter() {

        }
    } 
})

export { ServerStore }