<script setup>
import {
    ref,
    toRefs,
    computed,
    getCurrentInstance
} from 'vue'
import { copyText } from 'vue3-clipboard'
import CarDetails from './CarDetails.vue';

const props = defineProps({
    id: Number,
    dialog: Boolean,
    hash: String,
    race: Object
})
const {
    id,
    dialog,
    hash,
    race
} = toRefs(props)

const {proxy} = getCurrentInstance()
const data = ref([])

getCarsInfo()

async function getCarsInfo() {
  const res = await proxy.$api({"serviceName":"players","methodName":"getCarsInfo","parameters": [id.value] })
  data.value = res
}

const emit = defineEmits(['closeDialog'])
const closeDialog = () => emit('closeDialog')

function copyLink() {
  const container = ref('https://nightriderz.world/car/' + id.value)
  copyText('ok', container.value, () => {})
}
</script>

<template>

<div class="text-center">
    <v-dialog transition="dialog-bottom-transition" v-model="dialog" @click:outside="closeDialog" :width="1100" :height="550">

        <v-toolbar dark color="primary">
            <v-icon>mdi-car-cog</v-icon>
            <v-toolbar-title class="test">{{ data.manufactor + ' ' + data.model }}</v-toolbar-title>
            <v-spacer></v-spacer>
            
        </v-toolbar>
        <v-card class="card" :style="'background: url(' + 'https://cdn.nightriderz.world/images/website/moonset/cars/' + data.name + '.png' + ') center center;background-size:cover;'">
            <div class="parts d-flex">
              <div class="d-flex flex-column">
                    <v-spacer></v-spacer>
                    <div v-for="perf in data.SKILLMODPART">
                      <v-tooltip
                        activator="parent"
                        location="end"
                      >{{ perf.longDescription }}</v-tooltip>
                        <img class="icon" :src="'https://cdn.nightriderz.world/images/website/moonset/parts/skill/' + perf.icon + '.png'">
                    </div>
                    <div class="cat">
                      <img src="https://cdn.nightriderz.world/images/website/moonset/parts/SKILLMOD.png">
                    </div>
                </div>
                <div class="d-flex flex-column">
                    <v-spacer></v-spacer>
                    <div v-for="perf in data.VISUALPART">
                      <v-tooltip
                        activator="parent"
                        location="end"
                      >{{ perf.entitlementTag.replaceAll('_', ' ') }}</v-tooltip>
                        <img class="icon" :src="'https://cdn.nightriderz.world/images/website/moonset/parts/visual/' + perf.icon + '.png'">
                    </div>
                    <div class="cat">
                      <img src="https://cdn.nightriderz.world/images/website/moonset/parts/AFTERMARKETPART.png">
                    </div>
                </div>
                <div class="d-flex flex-column">
                    <v-spacer></v-spacer>
                    <div v-for="perf in data.PERFORMANCEPART">
                      <v-tooltip
                        activator="parent"
                        location="end"
                      >{{ perf.longDescription }}</v-tooltip>
                        <img class="icon" :src="'https://cdn.nightriderz.world/images/website/moonset/parts/perf/' + perf.icon + '.png'">
                    </div>
                    <div class="cat">
                      <img src="https://cdn.nightriderz.world/images/website/moonset/parts/PERFORMANCEPART.png">
                    </div>
                </div>
            </div>

            <v-spacer></v-spacer>

            <div class="d-flex flex-column">
              <v-spacer></v-spacer>
              <div class="class">
                <img :src="'https://cdn.nightriderz.world/images/website/moonset/classes/' + data.carClassHash + '.png'" height="45" width="45">
                <v-tooltip
                        activator="parent"
                        location="start"
                      >Class {{ data.carClassHash }}</v-tooltip>
                <v-spacer></v-spacer>
                <span>{{ data.rating }}</span>
                <v-spacer></v-spacer>
              </div>
            </div>
            

        </v-card>

    </v-dialog>
</div>
</template>

<style scoped>

.v-card {
  flex-direction: row!important;
}
.card {
    height: 550px;
}

.parts {
    margin-left: 8px;
    height: 100%;
}

.parts .icon {
    height: 45px;
    padding: 2px;
    margin: 0 5px;
    background: var(--primary-color);
    border-radius: 0.250rem;
}

.cat {
  padding: 2px;
  margin: 5px;
  background: var(--primary-color);
  border-radius: 0.250rem;
  height: 45px;
  width: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.class {
  background: var(--primary-color);
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
    padding: 2px;
    margin: 5px 0px 5px 5px;
    display: flex;
    align-items: center;
    width: 115px;
    font-size: 22px;
}

</style>
