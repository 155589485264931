<script setup>
import {
    ref,
    getCurrentInstance, createApp
} from 'vue'
import {
    DriverStore
} from '../pinia/Driver.js'
import VueColorPicker from 'vue3-colorpicker'
const Driver = DriverStore()
const {
    proxy
} = getCurrentInstance()
const app = createApp(VueColorPicker)
const pseudo = ref("" + Driver.persona.name)
const snackbar = ref(false)
const error = ref('')
const avatar = ref(Driver.persona.iconIndex)
const color = ref(Driver.user.nicknameColor)

app.use(VueColorPicker)
function save() {
    if (pseudo.value != Driver.persona.name) {
        proxy.$http.post("", JSON.stringify({
            serviceName: "account",
            methodName: "UpdatePersonaName",
            parameters: [pseudo.value]
        })).then(response => {

            error.value = ''
            Driver.persona.name = pseudo.value
            snackbar.value = true

        }).catch(function (response) {
            error.value = response.response.data.error
        })
    } else error.value = ''

    if (avatar.value != Driver.persona.iconIndex) {

        proxy.$http.post("", JSON.stringify({
            serviceName: "account",
            methodName: "avatar",
            parameters: [avatar.value]
        })).then(response => {

            error.value = ''
            Driver.persona.iconIndex = avatar.value
            snackbar.value = true

        }).catch(function (response) {
            error.value = response.response.data.error
        })

    }

    if(color.value != Driver.user.nicknameColor) {

        proxy.$http.post("", JSON.stringify({
            serviceName: "account",
            methodName: "UpdateColor",
            parameters: [color.value]
        })).then(response => {

            error.value = ''
            Driver.user.nicknameColor = color.value
            snackbar.value = true

        }).catch(function (response) {
            error.value = response.response.data.error
        })


    }

}
</script>

<template>
<v-snackbar :timeout="2000" color="deep-purple-accent-4" elevation="24" v-model="snackbar">
    Your informations is saved.
</v-snackbar>

<v-alert class="error" v-if="error" :text="error" type="error"></v-alert>

<v-form>
    <v-container>
        <v-row>
            <div class="flexform">

                <span v-if="Driver.user.premium == '1' ? false : true">
                    <v-alert color="info" icon="mdi-information">You have to be a Premium member to edit your driver.</v-alert>
                    <br>
                </span>

                <div class="d-flex">
                    <v-text-field prepend-icon="mdi-account" v-model="pseudo" label="Name" variant="outlined" :disabled="Driver.user.premium == '1' ? false : true" style="margin-right: 1vh"></v-text-field>
                    <input type='color' class="color_input" style="cursor:pointer;width:55px;height:55px;margin-left: 100px;margin-left: auto;margin-right: 4px;min-width:34px;min-height:34px;border: 10px solid transparent" v-model="color" :disabled="Driver.user.premium == '1' ? false : true">
                    <!--<v-color-picker v-model="color" :disabled="Driver.user.premium == '1' ? false : true"></v-color-picker>-->
                </div>

                <div class="grid">
    <!-- Avatar avec l'ID 0 -->
    <span :class="'item' + 0" style="position: relative">
        <label :for="'label' + 0">
            <img :src="'https://cdn.nightriderz.world/images/website/icon-persona/0.jpg'" width="57" height="57" style="border-radius: 8px">
        </label>
        <input type="radio" :id="'label' + 0" name="avatar" @click="avatar = 0" :checked="parseInt(Driver.persona.iconIndex) === 0" :disabled="Driver.user.premium === '1' ? false : true">
    </span>

    <!-- Boucle pour les autres avatars -->
    <span :class="'item' + i" v-for="i in 72" style="position: relative">
        <label :for="'label' + i">
            <img v-if="i !== 26" :src="'https://cdn.nightriderz.world/images/website/icon-persona/' + i + '.jpg'" width="57" height="57" style="border-radius: 8px">
        </label>
        <input type="radio" :id="'label' + i" name="avatar" @click="avatar = i" :checked="parseInt(Driver.persona.iconIndex) === i" :disabled="Driver.user.premium === '1' ? false : true">
    </span>
</div>

                <v-btn class="save" variant="tonal" @click="save" v-if="Driver.user.premium == '1' ? true : false">Save</v-btn>
            </div>
        </v-row>
    </v-container>
</v-form>
</template>

<style scoped>
.flexform {
    margin-top: 10px;
}

.color_input::-webkit-color-swatch,
        .color_text::-webkit-color-swatch {
          border: none;
          border-radius: 100%;
          padding: 0;
        }
        .color_input::-webkit-color-swatch-wrapper,
        .color_text::-webkit-color-swatch-wrapper {
          border: none;
          border-radius: 100%;
          padding: 0;
        }

        input[type="color"] {
          background: initial;
          border: none;
        }

.error {
    margin-bottom: 25px !important;
}

.color {
    margin: 8px 0px 0px 10px;
}

hr {
    margin: 20px 0;
}

.grid {
    display: flex;
    flex-wrap: wrap;
    height: 200px;
    overflow-y: scroll;
    overflow-x: hidden;
    width: 100%;
    margin-bottom: 20px;
    margin-top: 5px;
}

.item26 {
    display: none;
}

[type='radio'] {
    position: absolute;
    left: 2px;
    top: 2px;
}

.grid span {
    margin: 5px;
}
</style><style>
.v-input__details {
    padding-inline-start: 2px !important;
}
</style>
