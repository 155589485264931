<script setup>
import { toRefs } from 'vue'

const props = defineProps({
  data: Object
})
const { data } = toRefs(props)

// Fonction pour obtenir le texte du rang et de la division
const getRankDivision = (rankName, rankingPoints) => {
  let rankText = ""
  let divisionText = ""

  // Fonction pour convertir les nombres en chiffres romains
  const toRoman = (num) => {
    const romanNumerals = ["I", "II", "III", "IV", "V", "VI", "VII", "VIII", "IX", "X"]
    return romanNumerals[num - 1] || num
  }

  if (rankingPoints >= 0 && rankingPoints <= 19) {
    rankText = "Bronze";
    divisionText = "I";
  } else if (rankingPoints >= 20 && rankingPoints <= 39) {
    rankText = "Bronze";
    divisionText = "II";
  } else if (rankingPoints >= 40 && rankingPoints <= 59) {
    rankText = "Bronze";
    divisionText = "III";
  } else if (rankingPoints >= 60 && rankingPoints <= 79) {
    rankText = "Bronze";
    divisionText = "IV";
  } else if (rankingPoints >= 80 && rankingPoints <= 99) {
    rankText = "Bronze";
    divisionText = "V";
  } else if (rankingPoints >= 100 && rankingPoints <= 129) {
    rankText = "Silver";
    divisionText = "I";
  } else if (rankingPoints >= 130 && rankingPoints <= 159) {
    rankText = "Silver";
    divisionText = "II";
  } else if (rankingPoints >= 160 && rankingPoints <= 189) {
    rankText = "Silver";
    divisionText = "III";
  } else if (rankingPoints >= 190 && rankingPoints <= 219) {
    rankText = "Silver";
    divisionText = "IV";
  } else if (rankingPoints >= 220 && rankingPoints <= 249) {
    rankText = "Silver";
    divisionText = "V";
  } else if (rankingPoints >= 250 && rankingPoints <= 289) {
    rankText = "Gold";
    divisionText = "I";
  } else if (rankingPoints >= 290 && rankingPoints <= 339) {
    rankText = "Gold";
    divisionText = "II";
  } else if (rankingPoints >= 340 && rankingPoints <= 379) {
    rankText = "Gold";
    divisionText = "III";
  } else if (rankingPoints >= 380 && rankingPoints <= 419) {
    rankText = "Gold";
    divisionText = "IV";
  } else if (rankingPoints >= 420 && rankingPoints <= 459) {
    rankText = "Gold";
    divisionText = "V";
  } else if (rankingPoints >= 460 && rankingPoints <= 509) {
    rankText = "Platinum";
    divisionText = "I";
  } else if (rankingPoints >= 510 && rankingPoints <= 559) {
    rankText = "Platinum";
    divisionText = "II";
  } else if (rankingPoints >= 560 && rankingPoints <= 609) {
    rankText = "Platinum";
    divisionText = "III";
  } else if (rankingPoints >= 610 && rankingPoints <= 659) {
    rankText = "Platinum";
    divisionText = "IV";
  } else if (rankingPoints >= 660 && rankingPoints <= 709) {
    rankText = "Platinum";
    divisionText = "V";
  } else if (rankingPoints >= 710 && rankingPoints <= 769) {
    rankText = "Diamond";
    divisionText = "I";
  } else if (rankingPoints >= 770 && rankingPoints <= 829) {
    rankText = "Diamond";
    divisionText = "II";
  } else if (rankingPoints >= 830 && rankingPoints <= 889) {
    rankText = "Diamond";
    divisionText = "III";
  } else if (rankingPoints >= 890 && rankingPoints <= 949) {
    rankText = "Diamond";
    divisionText = "IV";
  } else if (rankingPoints >= 950 && rankingPoints <= 1009) {
    rankText = "Diamond";
    divisionText = "V";
  } else if (rankingPoints >= 1010 && rankingPoints <= 1079) {
    rankText = "Riderz";
    divisionText = "I";
  } else if (rankingPoints >= 1080 && rankingPoints <= 1149) {
    rankText = "Riderz";
    divisionText = "II";
  } else if (rankingPoints >= 1150 && rankingPoints <= 1219) {
    rankText = "Riderz";
    divisionText = "III";
  } else if (rankingPoints >= 1220 && rankingPoints <= 1289) {
    rankText = "Riderz";
    divisionText = "IV";
  } else if (rankingPoints >= 1290 && rankingPoints <= 1360) {
    rankText = "Riderz";
    divisionText = "V";
  } else {
    rankText = "Unranked";
    divisionText = "";
  }

  // Ajoutez la classe pour l'opacité aux points RP
  const rankDivision = `${rankText} ${divisionText} <span style="opacity:15%">[ ${rankingPoints} RP ]</span>`;
  return rankDivision;
}

// Fonction pour obtenir l'image du rang
const getRankImage = (rankingPoints) => {
  if (rankingPoints >= 0 && rankingPoints <= 99) {
    return "https://cdn.nightriderz.world/images/website/ranked/Bronze.png";
  } else if (rankingPoints >= 100 && rankingPoints <= 249) {
    return "https://cdn.nightriderz.world/images/website/ranked/Silver.png";
  } else if (rankingPoints >= 250 && rankingPoints <= 459) {
    return "https://cdn.nightriderz.world/images/website/ranked/Gold.png";
  } else if (rankingPoints >= 460 && rankingPoints <= 709) {
    return "https://cdn.nightriderz.world/images/website/ranked/Platinum.png";
  } else if (rankingPoints >= 710 && rankingPoints <= 1009) {
    return "https://cdn.nightriderz.world/images/website/ranked/Diamond.png";
  } else if (rankingPoints >= 1010 && rankingPoints <= 1360) {
    return "https://cdn.nightriderz.world/images/website/ranked/Riderz.png";
  }
}
</script>
<template>
  <v-container>
    <v-row no-gutters>
      <v-col>
        <v-sheet :class="data.role" class="pa-2 ma-2">
          <svg :class="data.role" v-html="levelIcon(data.level)"></svg>
          <br>
          LEVEL {{ data.level }}
        </v-sheet>
      </v-col>
      <v-col>
        <v-sheet class="pa-2 ma-2">
          Prestige<br>{{ data.prestige }}
        </v-sheet>
      </v-col>
      <v-col>
        <v-sheet class="pa-2 ma-2">
          Driver Score<br>{{ data.score }}
        </v-sheet>
      </v-col>
      <v-col>
        <v-sheet class="pa-2 ma-2">
          CASH<br>{{ data.cash }} $
        </v-sheet>
      </v-col>
      <v-col>
        <v-sheet class="pa-2 ma-2">
          SPEEDBOOST<br>{{ data.boost }} SB
        </v-sheet>
      </v-col>
      <v-col>
        <v-sheet class="pa-2 ma-2">
          <img :src="getRankImage(data.rankingPoints)" style="height:75px;width:75px" :alt="data.role">
          <br>
          <span v-html="getRankDivision(data.role, data.rankingPoints)"></span>
        </v-sheet>
      </v-col>
    </v-row>

    <v-row no-gutters>
      <v-col>
        <v-sheet class="pa-2 ma-2">
          CARS owned<br>{{ data.cars }}
        </v-sheet>
      </v-col>
      <v-col>
        <v-sheet class="pa-2 ma-2">
          EVENTS completed<br>{{ data.races }}
        </v-sheet>
      </v-col>
      <v-col>
        <v-sheet class="pa-2 ma-2">
          Challenges completed<br>{{ data.challenges }}
        </v-sheet>
      </v-col>
      <v-col>
        <v-sheet class="pa-2 ma-2">
          Treasure Hunt<br>{{ data.streak }} days
        </v-sheet>
      </v-col>
    </v-row>

    <!--
    <v-row no-gutters>
      <v-col>
        <v-sheet class="pa-2 ma-2">
          FAVORITE RACE<br>AGATHE STREET
        </v-sheet>
      </v-col>
      <v-col>
        <v-sheet class="pa-2 ma-2">
          FAVORITE GAMEMODE<br>CIRCUIT
        </v-sheet>
      </v-col>
    </v-row>

    <v-row no-gutters>
      <v-col>
        <v-sheet class="pa-2 ma-2">
          RACES WON<br>42
        </v-sheet>
      </v-col>
      <v-col>
        <v-sheet class="pa-2 ma-2">
          RACES LOST<br>42
        </v-sheet>
      </v-col>
      <v-col>
        <v-sheet class="pa-2 ma-2">
          Win %<br>50%
        </v-sheet>
      </v-col>
      <v-col>
        <v-sheet class="pa-2 ma-2">
          Average Finishing Place<br>2nd
        </v-sheet>
      </v-col>
    </v-row>
-->
    <v-row no-gutters>
      <v-col>
        <v-sheet class="pa-2 ma-2">
          Created<br>{{ data.created }}
        </v-sheet>
      </v-col>
      <v-col>
        <v-sheet class="pa-2 ma-2">
          Last login<br>{{ data.last_login }}
        </v-sheet>
      </v-col>
    </v-row>
  </v-container>
</template>

<style scoped>
.v-sheet {
  color: white;
  display: flex;
  flex-direction: column;
  height: 200px;
  background: #11141c;
  background: var(--primary-color);
  font-size: 20px;
  font-weight: 400;
  text-transform: uppercase;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-bottom: 4px solid white;
  border-radius: 6px;
}

/* Style pour l'opacité des points RP */

svg {
  height: 75px;
  fill: white;
}

.PREMIUM {
  color: #d9be00;
  fill: #d9be00;
}

svg,
.FREEMIUM {
  color: #0054a5;
  fill: #0054a5;
}

.STAFF {
  color: rgb(173 74 255);
  fill: rgb(173 74 255);
}

.DEVELOPER {
  color: rgb(17, 252, 74);
  fill: rgb(17, 252, 74);
}

.CONTENT_CREATOR {
  color: rgb(255 5 33);
  fill: rgb(255 5 33);
}
</style>
