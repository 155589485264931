<script setup>

    import { toRefs } from 'vue'

    const props = defineProps({
    data: Object
    })
    const {data} = toRefs(props)

</script>

<template>
  <v-card
    class="card"
    width="240"
  >

    <v-img
      :src="'https://cdn.nightriderz.world/images/website/controllers/' + data.image_url"
      height="240"
    ></v-img>

    <v-card-subtitle>
      {{ data.name }}
    </v-card-subtitle>


  </v-card>
</template>

<style scoped>

.v-card-subtitle {
    background: var(--tertiary-color);
    opacity: 1;
    display: flex;
    justify-content: center;
    padding: 10px 0;
    font-size: 12px;
    font-weight: bold;
}
    .card {
        background: var(--primary-color);
        margin: 25px;
    }

    .v-card-actions {
        margin: 10px 10px 0 10px;
    }
</style>