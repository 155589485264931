<script setup>
import { ref, toRefs } from 'vue'
import Rules from '../components/Rules.vue'
import Launchers from '../configs/launcher.json'

var dialog = ref(false)
var model = ref()

const props = defineProps({
home: Boolean
})
const {home} = toRefs(props)

</script>

<template>

<v-btn size="large" class="play" @click="dialog = true" v-if="home">PLAY NOW !</v-btn>
<div id="menu" @click="dialog = true" v-else>
  <v-icon icon="mdi-download"></v-icon>
<v-tooltip activator="parent" location="right">Download</v-tooltip>
    </div>

  <v-dialog transition="dialog-bottom-transition" :width="500" v-model="dialog" scrollable>
    <v-card>
      <v-toolbar dark color="primary">
        <v-icon>mdi-download</v-icon>
        <v-toolbar-title>Download</v-toolbar-title>
        <v-spacer></v-spacer>

        <template v-slot:extension>
          <v-tabs v-model="model" centered>
            <v-tab value="1">
              Rules
            </v-tab>
            <v-tab value="2">
              requirements
            </v-tab>
            <v-tab value="3">
              Download
            </v-tab>
          </v-tabs>
        </template>

      </v-toolbar>

      <v-window v-model="model">
        <v-window-item value="1">
          <v-card>
            <v-card-text>
              <Rules />
            </v-card-text>
          </v-card>
        </v-window-item>
        <v-window-item value="2">
          <v-card>
            <v-card-text>
              <v-list lines="one">
                <v-list-item title="Recommended CPU" subtitle="Intel Pentium 4 2.0GHz / AMD Athlon XP 2000+"><template
                    v-slot:prepend>
                    <v-icon icon="mdi-cpu-64-bit"></v-icon>
                  </template></v-list-item>
                <v-list-item title="Recommended GPU" subtitle="DirectX 9 Compatible Graphics Card"><template v-slot:prepend>
                    <v-icon icon="mdi-expansion-card"></v-icon>
                  </template></v-list-item>
                <v-list-item title="Recommended RAM" subtitle="1.5 GB RAM"><template v-slot:prepend>
                    <v-icon icon="mdi-memory"></v-icon>
                  </template></v-list-item>
                <v-list-item title="Recommended storage" subtitle="6 GB HDD or SSD"><template v-slot:prepend>
                    <v-icon icon="mdi-harddisk"></v-icon>
                  </template></v-list-item>
              </v-list>

            </v-card-text>
          </v-card>
        </v-window-item>
        <v-window-item value="3">
          <v-card>
            <v-card-text>
              <div class="downloadbtn">By downloading the launcher, you accept the rules of the server.</div>
              <br>
              <div v-for="Launcher in Launchers" :key="Launcher.name" class="downloadbtn">
                <a :href="Launcher.url" target="_blank">
                  <v-btn v-if="Launcher.active" variant="tonal">{{ Launcher.name }}</v-btn>
                </a>

              </div>
              <br>
              <a class="downloadbtn" href="https://lutris.net/games/soapbox-race-world-need-for-speed-world/"
                target="_blank">
                Running on Linux?
              </a>
            </v-card-text>
          </v-card>
        </v-window-item>
      </v-window>

    </v-card>
  </v-dialog>
</template>
<style>
.v-list-item__prepend {
  font-size: 30px!important;
  margin-top: 10px;
}
</style>
<style scoped>
.downloadbtn {
  display: flex;
  justify-content: center;
  margin: 10px 0;
}

button {
  margin: initial !important;
}

#menu {
    font-weight: bold;
    color: white;
    text-decoration: none;
    border-radius: 6px;
    margin-bottom: 10px;
    height: 40px;
    width: 40px;
    background: transparent;
    transition: all 0.15s ease-in-out;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    cursor: pointer;
}

#menu i {
    margin: 0 7px;
}

.play {
    margin-bottom: 20px!important;
    background: var(--tertiary-color);
    margin-top: 30px;
}

.router-link-active {
    font-weight: bold;
    border-left: 2px solid white;
    background: var(--tertiary-color) !important;
    color: white;
    vertical-align: middle;
}

#menu:hover {
    font-weight: bold;
    background: var(--quaternary-color);
    color: white;
    vertical-align: middle;
}
</style>

<style>
.v-list-item-title {
  margin: 10px 0 !important;
}
</style>