<script setup>
import {
    ref,
    getCurrentInstance
} from 'vue'
import {
    useRoute, useRouter
} from 'vue-router'
import {
    DriverStore
} from '../pinia/Driver.js'

import driverCard from '../components/driverCard.vue'
import ActivityCrew from '../components/ActivityCrew.vue'
import SKactivityCrew from '../components/skeletons/SKactivityCrew.vue'
import SKdriverCard from '../components/skeletons/SKdriverCard.vue'
import crewSettings from '../components/crewSettings.vue'
import crewCard from '../components/crewCard.vue'

const {
    proxy
} = getCurrentInstance()
const Driver = DriverStore()
const route = useRoute()
const router = useRouter()
const crew = ref([])
const tab = ref(0)
const members = ref([])
const admins = ref([])
const requests = ref([])
const activity = ref([])
const loading = ref(false)
const dialog = ref(false)
const model = ref(0)
const tag = ref("")
const name = ref("")
const motto = ref("")
const color = ref("")
const discord = ref("")
const levels = ref([])
const succes = ref(false)
const errors = ref([])
const open = ref(crew.open)
const discordPublic = ref(crew.discordVisibility)

const tagRules = [
    v => !!v || 'Tag is required',
    v => (v && v.length >= 2 && v.length <= 4) || 'Tag must be 2-4 characters in length.',
    v => (v && v.length >= 2 && v.length <= 4 && /^[a-zA-Z]+$/.test(v)) || 'Tag must be contain only letters.'
]

const nameRules = [
    v => !!v || 'Name is required',
    v => (v && v.length >= 3 && v.length <= 25) || 'Name must be 3-20 characters.',
]

const mottoRules = [
    v => !!v || 'Motto is required'
]

const ColorRules = [
    v => !!v || 'Color is required'
]

callcrew()

async function callcrew() {
    const res = await proxy.$api({
        serviceName: "crew",
        methodName: "GetInfo",
        parameters: [route.params.id]
    })
    crew.value = res
    crew.value.crewid = route.params.id
    tag.value = res.tag
    name.value = res.name
    motto.value = res.motto
    color.value = res.color
    discord.value = res.discord
    open.value = res.open
    discordPublic.value = res.discordVisibility
    callactivity()
}

async function callmembers() {
    const res = await proxy.$api({
        serviceName: "crew",
        methodName: "GetMembers",
        parameters: [route.params.id]
    })
    members.value = res.filter(i => crew.value.ownerUserId != i.personaid && i.canManage == "0")
    admins.value = res.filter(i => i.canManage == "1" || crew.value.ownerUserId == i.personaid)

}

async function callactivity() {
    const res = await proxy.$api({
        serviceName: "crew",
        methodName: "GetActivity",
        parameters: [route.params.id, "all"]
    })
    activity.value = res
    callmembers()
    getRequest()
}

async function openSettings() {
    dialog.value = true
    getLevel()
}

async function getLevel() {
    const res = await proxy.$api({
        serviceName: "crew",
        methodName: "GetLevel",
        parameters: [route.params.id]
    })
    levels.value = res
}

async function buyLevel() {
    const res = await proxy.$api({
        serviceName: "crew",
        methodName: "LevelUp",
        parameters: [route.params.id]
    })
    getLevel()
}

async function getRequest() {
    const res = await proxy.$api({
        serviceName: "crew",
        methodName: "GetAsk",
        parameters: [route.params.id]
    })
    requests.value = res
}

function uploadCover(e) {

    var fd = new FormData()
    fd.append("file", fileupload.files[0])
    fd.append('easharpptr-u', localStorage.getItem('easharpptr_u'))
    fd.append('easharpptr-p', localStorage.getItem('easharpptr_p'))
    fd.append('crew_id', route.params.id)
    fd.append('type', 'thumb')

    var XHR = new XMLHttpRequest()
    XHR.onreadystatechange = function () {
        if (this.readyState == 4 && this.status == 200) {
            crew.value.thumb_version = parseInt(crew.value.thumb_version) + 1
        }
    };
    XHR.open('POST', 'https://api.nightriderz.world/upload.php')
    XHR.send(fd)

}

function openFile() {
    document.querySelector('.file').click()
}

function convertToBoolean(value) {
  return value === 1;
}

async function save() {

    var req = []

    if (!!tag.value && tag.value != crew.value.tag) {
        req.push(tag.value)
    } else {
        req.push(null)
    }

    if (!!name.value && name.value != crew.value.name) {
        req.push(name.value)
    } else {
        req.push(null)
    }

    if (!!motto.value && motto.value != crew.value.motto) {
        req.push(motto.value)
    } else {
        req.push(null)
    }

    if (!!color.value && color.value != crew.value.color) {
        req.push(color.value)
    } else {
        req.push(null)
    }

    if (!!discord.value && discord.value != crew.value.discord) {
        req.push(discord.value)
    } else {
        req.push(null)
    }

    if (!req.every(i => i === null)) {

        req.push(route.params.id)

        try {
            const res = await proxy.$api({
                serviceName: "crew",
                methodName: "edit_moonset",
                parameters: req
            })
            errors.value = []
            succes.value = true
        } catch (error) {
            errors.value = error.response.data.errors
        }

    }
}

async function join() {

    loading.value = true

    const res = await proxy.$api({
        serviceName: "crew",
        methodName: "join",
        parameters: [route.params.id]
    })

    Driver.setCrew({
        "have": true,
        "can_join": false
    })
    Driver.setCurrentCrew({
        "canManage": "0",
        "id": route.params.id
    })
    loading.value = false
    callactivity()
}

async function leave() {

    loading.value = true

    if (confirm("Are you sure? All your points will be lost.")) {

        const res = await proxy.$api({
            serviceName: "crew",
            methodName: "leave",
            parameters: [route.params.id]
        })

        Driver.setCrew({
            "have": false,
            "can_join": true
        })
        Driver.setCurrentCrew({})
        loading.value = false
        callactivity()
    }

    loading.value = false

}

async function requestjoin() {
    loading.value = true

    const res = await proxy.$api({
        serviceName: "crew",
        methodName: "askjoin",
        parameters: [route.params.id]
    })

    Driver.setCrew({
        "have": false,
        "can_join": false,
        "ask": route.params.id
    })
    Driver.setCurrentCrew({})
    loading.value = false
}

async function canceljoin() {
    loading.value = true

    const res = await proxy.$api({
        serviceName: "crew",
        methodName: "removeaskjoin",
        parameters: [route.params.id]
    })

    Driver.setCrew({
        "have": false,
        "can_join": true
    })
    Driver.setCurrentCrew({})
    loading.value = false
}

async function OpenSwitch(e) {

    var value = false

    if (open.value) {
        value = false
        open.value = false
    } else {
        open.value = true
        value = true
    }


    const res = await proxy.$api({
        serviceName: "crew",
        methodName: "open",
        parameters: [value, route.params.id]
    })


}

async function discordVisibility(e) {

var value = false


if (discordPublic.value) {
    value = false
    discordPublic.value = false
} else {
    discordPublic.value = true
    value = true
}




const res = await proxy.$api({
    serviceName: "crew",
    methodName: "discordVisibility",
    parameters: [value, route.params.id]
})


}

async function deleteforever() {
    if (confirm("Are you sure?")) {
        const res = await proxy.$api({
            serviceName: "crew",
            methodName: "delete",
            parameters: [route.params.id]

        })
        router.push("/crews")
    }
}


</script>

<template>
    <v-app-bar :elevation="0" :style="'background:' + crew.color + '!important;border-radius:6px;margin-left:18px;width: calc(100% - 96px);margin-top:18px;position:fixed'">
        <v-icon icon="mdi-account-group"></v-icon>
        <v-app-bar-title><b>[{{ crew.tag }}] {{ crew.name }}</b></v-app-bar-title>
        <v-spacer></v-spacer>
        <span v-if="admins.length > 0">
            <v-btn variant="flat" :loading="loading" size="small" @click="join"
                v-if="Driver.crew.can_join && crew.open == '1' && (members.length + admins.length) < (crew.level * 100)" style="background:#11141c">Join</v-btn>
            <v-btn variant="flat" :loading="loading" disabled
                v-if="(members.length + admins.length) >= (crew.level * 100)" style="background:#11141c">Crew full</v-btn>
            <v-btn variant="flat" :loading="loading" size="small" v-if="crew.discord !== undefined" :href="crew.discord"
                prepend-icon="mdi-discord" style="margin-left:8px;background:#11141c">DISCORD SERVER</v-btn>
            <v-btn variant="flat" :loading="loading" size="small" style="margin-left:8px;background:#11141c" @click="requestjoin"
                v-if="Driver.crew.can_join && crew.open == '0'">JOIN REQUEST</v-btn>
            <v-btn variant="flat" :loading="loading" size="small" style="margin-left:8px;background:#11141c" @click="canceljoin"
                v-if="route.params.id == Driver.crew.ask">CANCEL REQUEST</v-btn>
            <span v-if="Driver.currentCrew">
                <v-btn variant="flat" style="margin-left:8px;background:#11141c" :loading="loading" size="small"
                    prepend-icon="mdi-exitrun" @click="leave"
                    v-if="route.params.id == Driver.currentCrew.id && Driver.persona.ID != crew.ownerUserId">Leave
                    {{ Driver.currentCrew.name }}</v-btn>
                <v-btn variant="flat" style="margin-left:8px;;background:#11141c" size="small" @click="openSettings()"
                    v-if="(Driver.persona.ID == crew.ownerUserId || Driver.currentCrew.canManage == '1') && Driver.currentCrew.id == route.params.id"><v-icon>mdi-cog</v-icon></v-btn>
            </span>
        </span>
        <template v-slot:extension>
            <div class="motto">
                <div>{{ crew.motto }}</div>
                <v-spacer></v-spacer>
                <div>
                    <v-dialog v-model="dialog" :width="545" transition="none">
                        <v-card>
                            <v-toolbar color="primary">
                                <v-icon>mdi-cog</v-icon>
                                <v-toolbar-title>Settings</v-toolbar-title>
                                <v-spacer></v-spacer>

                                <template v-slot:extension>
                                    <v-tabs v-model="model" centered>
                                        <v-tab value="1">
                                            Crew
                                        </v-tab>
                                        <v-tab value="2">
                                            Cover
                                        </v-tab>
                                        <v-tab value="3">
                                            Level
                                        </v-tab>
                                        <v-tab value="4" v-if="Driver.persona.ID == crew.ownerUserId">
                                            Delete
                                        </v-tab>
                                    </v-tabs>
                                </template>

                            </v-toolbar>
                            <v-card-text>
                                <v-window v-model="model">
                                    <v-window-item value="1">
                                        <v-card>
                                            <v-card-text>

                                                <div v-if="errors.length > 0">
                                                    <v-alert icon="mdi-alert-circle" type="error">
                                                        <ul>
                                                            <li v-for="error in errors">• {{ error }}</li>
                                                        </ul>
                                                    </v-alert>
                                                    <br>
                                                </div>

                                                <div v-if="succes">
                                                    <v-alert icon="mdi-check-circle" type="success">
                                                        Changes successfully saved.
                                                    </v-alert>
                                                    <br>
                                                </div>

                                                <v-text-field v-model="tag" :rules="tagRules" label="Tag"
                                                    variant="outlined"></v-text-field>
                                                <v-text-field v-model="name" :rules="nameRules" label="Name"
                                                    variant="outlined"></v-text-field>
                                                <v-text-field v-model="motto" :rules="mottoRules" label="Motto"
                                                    variant="outlined"></v-text-field>
                                                <v-text-field v-model="color" :rules="ColorRules" label="Color"
                                                    variant="outlined"></v-text-field>
                                                <v-text-field v-model="discord" label="Discord server invitation"
                                                    variant="outlined"></v-text-field>
                                                <div>
                                                    <v-switch @change="discordVisibility" color="primary" :model-value="crew.discordVisibility"
                                                        label="Allow non-members to see the Discord invitation."></v-switch>
                                                    <v-switch @change="OpenSwitch" color="primary" :model-value="crew.open"
                                                        label="Accept new members without confirmation."></v-switch>
                                                    <v-spacer></v-spacer>
                                                </div>
                                                <div class="d-flex justify-end">
                                                    <v-btn block variant="tonal" @click="save">SAVE</v-btn>
                                                </div>
                                            </v-card-text>
                                        </v-card>
                                    </v-window-item>
                                    <v-window-item value="2">
                                        <v-card>
                                            <v-card-text>
                                                <div class="d-flex justify-center preview">
                                                    <crewCard :data="crew" />
                                                </div>
                                                <div class="d-flex justify-center">
                                                    <v-btn variant="tonal" @click="openFile">Change</v-btn>

                                                    <input type="file" id="fileupload" class="file" @change="uploadCover"
                                                        hidden>
                                                </div>
                                            </v-card-text>
                                        </v-card>
                                    </v-window-item>
                                    <v-window-item value="3">
                                        <v-card>
                                            <v-card-text>
                                                <v-alert class="level" v-for="level in levels">
                                                    <img src="https://cdn.nightriderz.world/images/website/boost.png">
                                                    <span>{{ level.price }}</span>
                                                    <v-spacer></v-spacer>
                                                    <span>Level {{ level.level }}</span>
                                                    <v-btn @click="buyLevel" :icon="'mdi-' + level.icon" :class="level.icon"
                                                        :disabled="level.icon != 'payment'"></v-btn>
                                                </v-alert>
                                            </v-card-text>
                                        </v-card>
                                    </v-window-item>
                                    <v-window-item value="4">
                                        <v-card>
                                            <v-card-text>
                                                <v-btn color="error" @click="deleteforever">DELETE THE CREW</v-btn>
                                            </v-card-text>
                                        </v-card>
                                    </v-window-item>
                                </v-window>
                            </v-card-text>
                        </v-card>
                    </v-dialog>

                </div>
            </div>

        </template>
    </v-app-bar>
    <div class="crew" style="padding-top: 10px;">
        <v-row no-gutters>
            <v-col>
                <v-sheet class="pa-2 ma-2" :style="'border-bottom: 4px solid ' + crew.color">
                    LEVEL<br>{{ crew.level }}
                </v-sheet>
            </v-col>
            <v-col>
                <v-sheet class="pa-2 ma-2" :style="'border-bottom: 4px solid ' + crew.color">
                    POINTS<br>{{ number_format(crew.maxPoints) }}
                </v-sheet>
            </v-col>
            <v-col>
                <v-sheet class="pa-2 ma-2" :style="'border-bottom: 4px solid ' + crew.color">
                    LOCATION<br>{{ crew.city }}<br>{{ crew.district }}
                </v-sheet>
            </v-col>
            <v-col>
                <v-sheet class="pa-2 ma-2" :style="'border-bottom: 4px solid ' + crew.color">
                    CREATED THE<br>
                    <timeago :datetime="crew.create_date" :title="crew.create_date" />
                </v-sheet>
            </v-col>
            <v-col>
                <v-sheet class="pa-2 ma-2" :style="'border-bottom: 4px solid ' + crew.color">
                    OWNER<br>{{ crew.ownername }}
                </v-sheet>
            </v-col>
        </v-row>

        <div style="display:flex">
            <div style="flex:1">
                <h5>CREW ACTIVITY</h5>
                <transition name="fade" mode="out-in">
                    <div key=1 v-if="activity.length == 0">
                        <SKactivityCrew v-for="i in 10" />
                    </div>

                    <div key=2 v-else>
                        <ActivityCrew :activity="activity" />
                    </div>
                </transition>

            </div>
            <div style="width: 460px;">

                <span v-if="Driver.currentCrew">

                    <div
                        v-if="(Driver.persona.ID == crew.ownerUserId || Driver.crew.current.canManage == '1') && crew.open == '0' && requests.length > 0 && Driver.crew.current.id === crew.crewid">
                        <h5>Pending requests ‒ {{ requests.length }}</h5>

                        <driverCard @refreshmembers="callmembers" v-for="driver in requests" :request="true"
                            :key="driver.id" :data="driver" />
                    </div>
                </span>

                <h5>{{ admins.length < 2 ? "Admin" : "Admins" }} {{ admins.length ? ' ‒ ' + admins.length : '' }}</h5>
                        <transition name="fade" mode="out-in">
                            <div key=1 v-if="admins.length == 0">
                                <SKdriverCard v-for="i in 3" />
                            </div>

                            <div key=2 v-else>

                                <span v-if="Driver.currentCrew">
                                    <driverCard @refreshmembers="callmembers" v-for="driver in admins"
                                        :crewmanage="(Driver.crew.current.canManage == '1' || Driver.persona.ID == crew.ownerUserId) && Driver.crew.current.id === crew.crewid"
                                        :key="driver.id" :data="driver" />
                                </span>
                                <span v-else>
                                    <driverCard @refreshmembers="callmembers" v-for="driver in admins" :key="driver.id"
                                        :data="driver" />
                                </span>

                            </div>
                        </transition>
                        <h5 v-if="crew.nb_member > 1">{{ members.length < 2 ? "Member" : "Members" }} {{ members.length
                            ? ' ‒ ' + members.length + '' : '' }}</h5>
                                <transition name="fade" mode="out-in">
                                    <div key=1 v-if="members.length == 0 && crew.nb_member > 1">
                                        <SKdriverCard v-for="i in 10" />
                                    </div>

                                    <div key=2 v-else>
                                        <span v-if="Driver.currentCrew">
                                            <driverCard @refreshmembers="callmembers" v-for="driver in members"
                                                :crewmanage="(Driver.crew.current.canManage == '1' || Driver.persona.ID == crew.ownerUserId) && Driver.crew.current.id === crew.crewid"
                                                :key="driver.id" :data="driver" />
                                        </span>
                                        <span v-else>
                                            <driverCard @refreshmembers="callmembers" v-for="driver in members"
                                                :crewmanage="false" :key="driver.id" :data="driver" />
                                        </span>
                                    </div>
                                </transition>
            </div>
        </div>
    </div>
</template>

<style scoped>
.grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    background: var(--secondary-color);
    margin-top: 20px;
}

.motto {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.v-sheet {
    color: white;
    display: flex;
    flex-direction: column;
    height: 120px;
    background: #11141c;
    background: var(--primary-color);
    font-size: 20px;
    font-weight: 400;
    text-transform: uppercase;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-radius: 6px;
    border-bottom: 4px solid white;
}

h5 {
    margin-left: 10px;
}

.v-row {
    margin: 5px 0 !important;
}

.crew {
    margin: 10px;
}

.level {
    background-color: var(--primary-color);
    padding: 8px;
}

.v-alert::v-deep(.v-alert__content) {
    display: flex !important;
    align-items: center;
    padding: 0;
}

.preview {
    pointer-events: none;
    flex-direction: column;
    align-items: center;
}

.level .v-btn {
    margin-left: 10px;
}

.payment {
    background: var(--tertiary-color);
}

.v-text-field {
    margin-bottom: 10px;
}</style>
