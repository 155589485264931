<script setup>

</script>

<template>
<div class="discord">
    <img src="https://assets-global.website-files.com/6257adef93867e50d84d30e2/636e0b5493894cf60b300587_full_logo_white_RGB.svg" height="50">
    <br>
    <br>
    <h2>account successfully linked</h2>
    <br>
    <br>
    <span>You can close this page</span></div>
</template>

<style scoped>
span {
    font-size: 14px;
}
h2 {
    text-transform: uppercase;
}
.discord {
    background: #2b2d31;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
</style>
