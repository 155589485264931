<script setup>
import {
    getCurrentInstance,
    ref
} from 'vue'
import {
    useRoute,
    useRouter
} from 'vue-router'

const {
    proxy
} = getCurrentInstance()
const route = useRoute()
const error = ref('')

async function activate() {
        const res = await proxy.$api({"serviceName":"session","methodName":"confirmAccount","parameters": [route.params.token] })
        if (res == "true"){
            error.value = "Your account have been activated."
        } else if (res == "false") {
            error.value = "This activation token is invalid."
        }
}
</script>

<template>
    <div class="d-flex justify-center align-center" style="height: 100vh;">
        <v-card width="400px" variant="tonal" style="padding: 10px 20px 20px 20px;">
            <v-alert v-if="error" class="error d-flex justify-center" :text="error" type="info"></v-alert>
    <h4>In order to access our website and server you have to activate your account. Click below.</h4>
        <br>
      <div class="d-flex justify-center">
        <v-btn variant="flat" color="green" @click="activate" style="width:500px;">Activate my account</v-btn>
      </div>

    </v-card>
  </div>
</template>


<style scoped>
    .p404 {
        display: flex;
        justify-content: center;
        padding: 40px;
    }
    button:hover {
    background: #4caf50b8!important;
}
</style>
