<script setup>
import {
    ref,
    getCurrentInstance,
    watch,
    toRefs
} from 'vue'

import {
    useRoute,
    useRouter
} from 'vue-router'
import LeaderboardRow from './leaderboardRow.vue'
import CarDialog from '../pages/CarDialogHash.vue'
import {
    ServerStore
} from "../pinia/Server.js"
import {
    DriverStore
} from '../pinia/Driver.js'
const Driver = DriverStore()

const route = useRoute()
const router = useRouter()
const {
    proxy
} = getCurrentInstance()

const Server = ServerStore()
const race = ref([])
const raceDispay = ref(false)
const rows = ref([])
const page = ref(1)
const nbHits = ref(0)
const paginationdisplay = ref(false)
const perPage = ref(0)
const cars = ref([])
const classes = ref([])
const chips_class = ref(0)
const dialog = ref(false)
const hash = ref('')
const hashrace = ref('')
const carfiltre = ref('')
const ordertime = ref('asc')

const props = defineProps({
    data: String,
    race_id: String
    })
    const {data, race_id} = toRefs(props)

function dialogData(e, f) {

    hash.value = e
    hashrace.value = f
    dialog.value = true
}

callRace()


async function callRace() {
    var res = await proxy.$api({
        serviceName: "ranking",
        methodName: "GetRace",
        parameters: [race_id.value]
    })
    race.value = res
    raceDispay.value = true
    callRows()
}

async function callRows(newPage = 0) {
    try {
    const res = await proxy.$api({serviceName: "leaderboard",methodName: "getDataDriver",parameters: [race_id.value, 1, data.value]})
    
    rows.value = res.event.hits
    perPage.value = res.event.request_params.per_page
    nbHits.value = res.event.found
    paginationdisplay.value = true

    } catch (e) {
        rows.value = []
    }
    
}

async function deleteRow() {
    const res = await proxy.$api({
        serviceName: "leaderboard",
        methodName: "deleteRow",
        parameters: [data.value.race.id, route.params.id]
    })
    refresh()
}

</script>

<template>
    <v-app-bar-title style="display: flex;background: var(--primary-color);padding: 15px;align-items: center;">
        <img :src="'https://cdn.nightriderz.world/images/website/moonset/classes/' + carClasshHash(race.carClassHash) + '.png'" height="26"><div>{{ race.name }}</div>
    </v-app-bar-title>

<CarDialog v-if="dialog" :dialog="dialog" :hash="hash" :race="hashrace" @close-dialog="dialog = false" />

<v-table theme="dark"  density="compact">
    <thead>
        <tr>
            <th class="text-left">RANK</th>
            <th>CAR</th>
            <th class="text-left">DETAILS</th>
            <th class="text-left">TIME <!--<v-icon @click="filterTime" icon="mdi-chevron-up" size="x-small"></v-icon>--></th>
            <th class="text-left" v-if="race.eventModeId == 4">BEST LAP</th>
            <th class="text-left">TOP SPEED</th>
            <th class="text-left">DATE</th>
            <th class="action" v-if="Driver.user.isAdmin == '1'"></th>
        </tr>
    </thead>
    <tbody>
        <LeaderboardRow :showDriver="false" v-for="(row, position) in rows" :key="row.race.id" :data="row" 
        :position="ordertime == 'asc' ? (position + 1) + (page == 1 ? 0 : 100 * (page - 1)) : (nbHits - position) - (page == 1 ? 0 : 100 * (page - 1))"
        @dialogData="dialogData" @refresh="callRows(route.params.page)" @delete-row="deleteRow" />
    </tbody>
</v-table>
</template>

<style>
.leaderdialog .v-overlay__scrim {
    display: none;
}

.v-toolbar-title__placeholder {
    display: flex !important;
}

.v-toolbar-title__placeholder img {
    margin-right: 10px;
}

.v-toolbar-title div:nth-child(2) {
    display: flex;
    flex-direction: column;
}

.v-pagination {
    margin: 0 !important;
}

.v-pagination__item,
.v-pagination__first,
.v-pagination__prev,
.v-pagination__next,
.v-pagination__last {
    margin: 0 5px !important;
}
</style><style scoped>
.action {
    width: 25px;
}

.search {
    width: 450px;
    background: rgb(var(--v-theme-surface));
    border-radius: 6px;
    padding-right: 3px;
}

.v-toolbar::v-deep(.v-toolbar-title__placeholder) {
    flex-direction: column;
}

.v-select::v-deep(li) {
    margin: 0;
    overflow: hidden;
}

.v-select::v-deep(v-chip) {
    margin: 0;
    padding: 0;
}

.v-select::v-deep(button) {
    background: transparent;
}

.v-main {
    --v-layout-top: initial !important;
    padding-top: 115px !important;
}

html {
    overflow: hidden !important;
}

.v-table::v-deep(th) {
    background: var(--primary-color) !important;
}

.v-table::v-deep(.v-table__wrapper) {
    background: #1f242f;
}

.type {
    font-size: 12px;
    text-transform: uppercase;
}

nav {
    font-size: 14px;
}

.col {
    flex: 1;
}

.size {
    width: 150px;
}

.title {
    display: flex;
    align-items: center;
}

.title h1 {
    margin: 0 15px;
}

.rank {
    width: 50px;
    text-align: center;
}

.router-link-active button,
.v-pagination::v-deep(.v-pagination__item--is-active button),
.v-pagination::v-deep(button:hover),
.v-chip--selected,
.v-chip:hover {
    color: white !important;
    background: var(--tertiary-color);
}
</style><style>
* {
    --vs-controls-color: white;
    --vs-border-color: transparent;
    --vs-dropdown-bg: var(--primary-color);
    --vs-dropdown-color: white;
    --vs-dropdown-option-color: white;
    --vs-selected-bg: rgb(38, 107, 173);
    --vs-selected-color: #eeeeee;
    --vs-search-input-color: #eeeeee;
    --vs-dropdown-option--active-bg: var(--tertiary-color);
    --vs-dropdown-option--active-color: #eeeeee;
}

::-webkit-calendar-picker-indicator {
    filter: invert(1);
}

.select {
    background: var(--secondary-color);
    width: 250px;
    padding: 8px 10px;
    overflow: initial;
    border: none;
    height: 46px;
    border-radius: 6px;
}

.select button {
    background: transparent;
}

textarea:focus,
input:focus {
    outline: none;
}

.vs__actions {
    height: 27px;
}
</style>
