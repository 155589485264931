<script setup>
import {
    ref,
    getCurrentInstance,
    watch
} from 'vue'
import carCard from '../components/carCard.vue'

const {
    proxy
} = getCurrentInstance()

const cars = ref([])

getNewCars()

async function getNewCars() {
    const res = await proxy.$api({
        serviceName: "server",
        methodName: "showcase_cars_all"
    })
    cars.value = res
}
</script>

<template>
    <div class="grid">
        <carCard v-for="car in cars.loop" :id="car.store_name" :test="true" :data="car" />
    </div>

</template>

<style scoped>
.grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    background: var(--secondary-color);
    margin-top: 20px;
}
</style>
