<script setup>
import {
    getCurrentInstance,
    toRefs
} from 'vue'
import {
    useRoute
} from 'vue-router'
import {
    DriverStore
} from '../pinia/Driver.js'
const {
    proxy
} = getCurrentInstance()

const Driver = DriverStore()
const route = useRoute()

const props = defineProps({
    data: Object,
    crewmanage: Boolean,
    request: Boolean,
    change: Boolean,
    event: String
})
const {
    data,
    crewmanage,
    request,
    event,
    change
} = toRefs(props)

const emit = defineEmits(['refreshmembers', 'closeDialog'])
const refreshmembers = () => emit('refreshmembers')
const closeDialog = () => emit('closeDialog')

async function action(type) {

    const res = await proxy.$api({
        serviceName: "crew",
        methodName: type,
        parameters: [route.params.id, data.value.personaID, ""]
    })

}

async function accept() {

    const res = await proxy.$api({
        serviceName: "crew",
        methodName: "acceptjoin",
        parameters: [data.value.personaID, route.params.id]
    })

    refreshmembers()
}

async function reject() {

    const res = await proxy.$api({
        serviceName: "crew",
        methodName: "rejectjoin",
        parameters: [data.value.personaID, route.params.id]
    })

    refreshmembers()
}

async function admin() {

    var value = "0"

    if (data.value.canManage == "0")
        value = "1"

    const res = await proxy.$api({
        serviceName: "crew",
        methodName: "canmanage",
        parameters: [route.params.id, data.value.personaID, value]
    })

    refreshmembers()

}

function changePersona() {
    Driver.setEasharpptr_p(data.value.ID)
    GetUserInfo()
    closeDialog()
}

async function GetUserInfo() {
    const res = await proxy.$api({
        serviceName: "session",
        methodName: "GetUserInfo"
    })

    Driver.setPersona(res.persona)
    Driver.setUser(res.user)
    Driver.setCrew(res.crew)
    Driver.setCurrentCrew(res.crew.current)
    Driver.setOthers(res.others)
    Driver.setLogged(true)
}
</script>

<template>
    <div v-if="data.reward != 1" class="driverCard rounded-lg" :xmpp="data.xmpp ? true : false" :event="event ? true : false" :change="change"
        :crew="data.points ? true : false" :crewmanage="crewmanage"
        :style="'background: linear-gradient(to right, rgba(136, 0, 255, 0) -80%, var(--primary-color) 25%), url(' + 'https://cdn.nightriderz.world/images/website/icon-persona/' + data.iconIndex + '.jpg' + ') no-repeat;background-position: left center;'">
        <span :title="data.role" :class="'role ' + data.role + 'I'"></span>
        <router-link :to="'/driver/' + data.name">
            <h4 :class="data.role" :style="'color:' + data.color ? data.color : ''"><b>{{ data.tag !== null ? '[' + data.tag +
                '] ' : '' }}
                {{ data.name }}</b></h4>
            <small style="margin-left: 7px;display: block;" v-if="data.xmpp">{{ data.xmpp }}</small>
            <small style="margin-left: 7px;display: block;" v-if="data.points">Joined
                <timeago :datetime="data.date_join" />
            </small>
            <small style="margin-left: 7px;display: block;" v-if="change && data.first_login">Created at
                <timeago :datetime="data.first_login" />
            </small>
            <small style="margin-left: 7px;display: block;" v-if="data.points">{{ number_format(data.points) }}
                points</small>
            <small style="margin-left: 7px;display: block;" v-if="data.contributor">{{ number_format(data.contributor) }} {{
                event }}</small>
        </router-link>
        <v-spacer></v-spacer>

        <div :class="'d-flex align-center level ' + data.role" v-if="!crewmanage">
            <span class="levelcount">{{ data.level }}</span>
            <svg :class="data.role" v-html="levelIcon(data.level)"></svg>
        </div>
        <div :class="'d-flex align-center level ' + data.role" v-if="crewmanage">
            <v-btn class="modo" @click="admin()" title="" icon="mdi-shield-crown-outline"
                :color="data.canManage == '1' ? 'primary' : ''"></v-btn>
            <v-btn class="modo" @click="action('Kick')" title="Kick" icon="mdi-hand-wave"></v-btn>
            <v-btn class="modo" @click="action('Ban')" title="Ban" icon="mdi-gavel"></v-btn>
        </div>
        <div :class="'d-flex align-center level ' + data.role" v-if="request">
            <v-btn class="modo" @click="accept" title="Accept" icon="mdi-checkbox-marked-circle-outline"></v-btn>
            <v-btn class="modo" @click="reject" title="Decline" icon="mdi-close-circle-outline"></v-btn>
        </div>
        <div :class="'d-flex align-center level ' + data.role" v-if="change">
            <v-btn class="modo" @click="changePersona" title="Change" icon="mdi-swap-horizontal"></v-btn>
        </div>
    </div>

    <div v-if="data.reward == 1" class="driverCard rounded-lg" :xmpp="data.xmpp ? true : false" :event="event ? true : false" :change="change"
        :crew="data.points ? true : false" :crewmanage="crewmanage"
        :style="'border: 2px solid #73AD21;background: linear-gradient(to right, rgba(136, 0, 255, 0) -80%, var(--primary-color) 25%), url(' + 'https://cdn.nightriderz.world/images/website/icon-persona/' + data.iconIndex + '.jpg' + ') no-repeat;background-position: left center;'">
        <span :title="data.role" :class="'role ' + data.role + 'I'"></span>
        <router-link :to="'/driver/' + data.name">
            <h4 :class="data.role" :style="'color:' + data.color ? data.color : ''"><b>{{ data.tag !== null ? '[' + data.tag +
                '] ' : '' }}
                {{ data.name }}</b></h4>
            <small style="margin-left: 7px;display: block;" v-if="data.xmpp">{{ data.xmpp }}</small>
            <small style="margin-left: 7px;display: block;" v-if="data.points">Joined
                <timeago :datetime="data.date_join" />
            </small>
            <small style="margin-left: 7px;display: block;" v-if="change && data.first_login">Created at
                <timeago :datetime="data.first_login" />
            </small>
            <small style="margin-left: 7px;display: block;" v-if="data.points">{{ number_format(data.points) }}
                points</small>
            <small style="margin-left: 7px;display: block;" v-if="data.contributor">{{ number_format(data.contributor) }} {{
                event }}</small>
        </router-link>
        <v-spacer></v-spacer>

        <div :class="'d-flex align-center level ' + data.role" v-if="!crewmanage">
            <span class="levelcount">{{ data.level }}</span>
            <svg :class="data.role" v-html="levelIcon(data.level)"></svg>
        </div>
        <div :class="'d-flex align-center level ' + data.role" v-if="crewmanage">
            <v-btn class="modo" @click="admin()" title="" icon="mdi-shield-crown-outline"
                :color="data.canManage == '1' ? 'primary' : ''"></v-btn>
            <v-btn class="modo" @click="action('Kick')" title="Kick" icon="mdi-hand-wave"></v-btn>
            <v-btn class="modo" @click="action('Ban')" title="Ban" icon="mdi-gavel"></v-btn>
        </div>
        <div :class="'d-flex align-center level ' + data.role" v-if="request">
            <v-btn class="modo" @click="accept" title="Accept" icon="mdi-checkbox-marked-circle-outline"></v-btn>
            <v-btn class="modo" @click="reject" title="Decline" icon="mdi-close-circle-outline"></v-btn>
        </div>
        <div :class="'d-flex align-center level ' + data.role" v-if="change">
            <v-btn class="modo" @click="changePersona" title="Change" icon="mdi-swap-horizontal"></v-btn>
        </div>
    </div>
</template>

<style scoped>
.level .levelIcon::v-deep(svg) {
    height: 34px;
}

.level {
    font-weight: bold;
}

.levelcount {
    margin-right: 10px;
}

svg {
    height: 34px;
    width: 34px;
}

.level.premium,
.PREMIUM,
.PREMIUM .levelcount,
.PREMIUM svg {
    color: #d9be00;
    fill: #d9be00;
}

.level,
svg,
.FREEMIUM {
    color: #0054a5;
    fill: #0054a5;
}

.driverCard {
    box-shadow: rgb(0 0 0 / 25%) 0px 3px 8px;
    overflow: hidden;
    background: white;
    width: 442px;
    padding: 15px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--primary-color);
    margin: 10px;
    height: 50px;
}

[crew='true'] {
    height: 85px;
}

[xmpp='true'],
[event="true"] {
    height: 70px;
}

[change="true"],
[changebis="true"] {
    height: 70px;
    width: 100% !important;
    margin: 10px 0;
}

h4 {
    margin: 0px 7px;
    font-size: 20px;
    font-weight: 500;
}

.STAFF {
    color: rgb(173 74 255);
    fill: rgb(173 74 255);
}

.DEVELOPER {
    color: rgb(17, 252, 74);
    fill: rgb(17, 252, 74);
}

.CONTENT_CREATOR {
    color: rgb(255 5 33);
    fill: rgb(255 5 33);
}

.v-btn {
    margin-left: 8px;
    width: 40px !important;
    height: 40px !important;
    font-size: 12px !important;
}</style>
