<script setup>
import {
    ref,
    getCurrentInstance,
    toRefs
} from 'vue'
import {
    useRoute
} from 'vue-router'
const {
    proxy
} = getCurrentInstance()


const props = defineProps({
   hash: String
})
const {
    hash
} = toRefs(props)

const data = ref([])
const route = useRoute()
getCarsInfo()

async function getCarsInfo() {
    const res = await proxy.$api({
        "serviceName": "players",
        "methodName": "getCarsInfoHash",
        "parameters": [route.params.hash ?? hash.value]
    })
    data.value = res
}

async function copyLink() {
    await navigator.clipboard.writeText('https://nightriderz.world/car/' + hash.value)
}
</script>

<template>
<v-card class="mx-auto my-12" width="500" style="background: var(--primary-color)">
    <template v-slot:loader="{ isActive }">
        <v-progress-linear :active="isActive" color="deep-purple" height="4" indeterminate></v-progress-linear>
    </template>

    <v-img cover height="250" :src="'https://cdn.nightriderz.world/images/website/moonset/cars/' + data.carName + '.png'">
        <v-btn v-if="hash" variant="tonal" @click="copyLink" style="position: absolute;right: 10px;top: 10px;">COPY LINK</v-btn></v-img>

    <v-card-item>
        <div style="display: flex;">
            <v-card-title>{{ data.manufactor + " " + data.model }}
                <v-card-subtitle>
                    <span class="me-1">Owned by {{ data.persona }}</span>
                </v-card-subtitle>
            </v-card-title>

            <v-spacer></v-spacer>
            <img :title="data.carRating" :src="'https://cdn.nightriderz.world/images/website/moonset/classes/' + carClasshHash(data.carClassHash) + '.png'" height="45" width="45">
        </div>
    </v-card-item>

    <v-divider class="mx-4 mb-1"></v-divider>
    <v-card-item>
    <v-card-title v-if="data.SKILLMODPART">SKILLS</v-card-title>
    <div class="d-flex" style="flex-wrap: wrap;margin-top: 5px;">
        <div v-for="perf in data.SKILLMODPART" style="margin-right: 10px;">
            <v-tooltip activator="parent" location="end">{{ perf.longDescription }}</v-tooltip>
            <img class="icon" :src="'https://cdn.nightriderz.world/images/website/moonset/parts/skill/' + perf.icon + '.png'" width="40">
        </div>
    </div>

    <v-card-title v-if="data.VISUALPART">AFTERMARKETS</v-card-title>
    <div class="d-flex" style="flex-wrap: wrap;margin-top: 5px;">
        <div v-for="perf in data.VISUALPART" style="margin-right: 10px;">
            <v-tooltip activator="parent" location="end">{{ perf.entitlementTag.replaceAll('_', ' ') }}</v-tooltip>
            <img class="icon" :src="'https://cdn.nightriderz.world/images/website/moonset/parts/visual/' + perf.icon + '.png'" width="40">
        </div>
    </div>

    <v-card-title v-if="data.PERFORMANCEPART">PERFORMANCES</v-card-title>
    <div class="d-flex" style="flex-wrap: wrap;margin-top: 5px;">
        <div v-for="perf in data.PERFORMANCEPART" style="margin-right: 10px;">
            <v-tooltip activator="parent" location="end">{{ perf.longDescription }}</v-tooltip>
            <img class="icon" :src="'https://cdn.nightriderz.world/images/website/moonset/parts/perf/' + perf.icon + '.png'" width="40">
        </div>
    </div>
    </v-card-item>
</v-card>
</template>

<style scoped>
.nohash {
    position: absolute;
    bottom: 5px;
    left: 10px;
}

.card {
    height: 550px;
}

.parts {
    margin-left: 8px;
    height: 100%;
}

.parts .icon {
    height: 45px;
    padding: 2px;
    margin: 0 5px;
    background: var(--primary-color);
    border-radius: 0.250rem;
}

.cat {
    padding: 2px;
    margin: 5px;
    background: var(--primary-color);
    border-radius: 0.250rem;
    height: 45px;
    width: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.class {
    background: var(--primary-color);
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
    padding: 2px;
    margin: 5px 0px 5px 5px;
    display: flex;
    align-items: center;
    width: 115px;
    font-size: 22px;
}
</style>
