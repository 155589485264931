import { createWebHistory, createRouter } from "vue-router"
import Home from "../pages/Home.vue"
import Download from "../pages/Download.vue"
import Leaderboards from "../pages/Leaderboards.vue"
import LeaderboardsRace from "../pages/Leaderboard.vue"
import Store from "../pages/Store.vue"
import LiveMap from "../pages/LiveMap.vue"
import Guide from "../pages/Guide.vue"
import PageNotFound from "../pages/404.vue"
import Challenges from "../pages/Challenges.vue"
import Additional from "../pages/Additional.vue"
import CarDetails from "../pages/CarDetails.vue"
import Drivers from "../pages/Drivers.vue"
import Driver from "../pages/Driver.vue"
import Crews from "../pages/Crews.vue"
import Crew from "../pages/Crew.vue"
import Carlist from "../pages/Carlist.vue"
import Discord from "../pages/Discord.vue"
import Reset from "../pages/Reset.vue"
import Confirm from "../pages/Confirm.vue"
import bugReport from "../pages/bugReport.vue"
import Rotation from "../pages/Rotation.vue"
import Login from "../pages/Login.vue"
import Event from "../pages/Event.vue"


const routes = [
  {
    path: "/carlist",
    name: "Carlist",
    component: Carlist,
    meta: {
      requireAuth: true
    }
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: {
      requireAuth: false
    }
  },
  {
    path: "/discord",
    name: "Discord",
    component: Discord,
    meta: {
      requireAuth: true
    }
  },
  {
    path: "/rotations",
    name: "Rotations",
    component: Rotation,
    meta: {
      requireAuth: true
    }
  },
  {
    path: "/car/:hash",
    name: "Car Dialog",
    component: CarDetails,
    meta: {
      requireAuth: true
    }
  },
    {
      path: "/",
      name: "Home",
      component: Home,
      meta: {
        requireAuth: false
      }
    },
    {
      path: "/challenges/:year?/:week?",
      name: "Challenges",
      component: Challenges,
      meta: {
        requireAuth: true
      }
    },
    {
      path: "/download",
      name: "Download",
      component: Download,
      meta: {
        requireAuth: false
      }
    },
    {
      path: "/leaderboards/:type/:active?/:class?",
      name: "Leaderboards",
      component: Leaderboards,
      meta: {
        requireAuth: true
      }
    },
    {
      path: "/leaderboards",
      name: "Leaderboards ",
      component: Leaderboards,
      meta: {
        requireAuth: true
      }
    },
    {
      path: "/leaderboard/:id/:page/:pu/:class?",
      name: "Leaderboards race",
      component: LeaderboardsRace,
      meta: {
        requireAuth: true
      }
    },
    {
      path: '/leaderboard/:id/',
      meta: {
        requireAuth: true
      },
      redirect: to => {
        return to.path + "/1/nopu"
      }
    },
    {
      path: "/drivers",
      name: "Drivers",
      component: Drivers,
      meta: {
        requireAuth: true
      }
    },
    {
      path: "/driver/:name/:tab?/:race_id?",
      name: "Driver",
      component: Driver,
      meta: {
        requireAuth: true
      }
    },
    {
      path: "/crews",
      name: "Crews",
      component: Crews,
      meta: {
        requireAuth: true
      }
    },
    {
      path: "/crew/headquarters/:id",
      name: "Crew",
      component: Crew,
      meta: {
        requireAuth: true
      }
    },
    {
      path: '/livemap',
      name: "Live Map",
      component: LiveMap,
      meta: {
        requireAuth: false
      }
    },
    {
      path : '/store',
      name: "Store",
      component: Store,
      meta: {
        requireAuth: false
      }
    },
    {
      path : '/guide',
      name: "Guide",
      component: Guide,
      meta: {
        requireAuth: false
      }
    },
    {
      path: '/additional',
      name: "Additional Content",
      component: Additional,
      meta: {
        requireAuth: false
      }
    },
    {
      path: '/reset/:token',
      name: "Reset",
      component: Reset,
      meta: {
        requireAuth: false
      }
    },
    {
      path: '/confirm/:token',
      name: "Confirm",
      component: Confirm,
      meta: {
        requireAuth: false
      }
    },
    {
      path: '/bugreport',
      name: "Bug Report",
      component: bugReport,
      meta: {
        requireAuth: false
      }
    },
    {
      path: '/event/:name',
      name: "Community Event",
      component: Event,
      meta: {
        requireAuth: false
      }
    },
    { path: "/404", name: "Page Not Found", component: PageNotFound },
    {
      path: "/:pathMatch(.*)*", name: "PageNotFound2",
      redirect: to => {
        return "/404"
      }
    }
  ]
  
  const router = createRouter({
    history: createWebHistory(),
    routes,
  })
  
  export default router