<script>

</script>

<template>

    <v-expansion-panels variant="accordion">
        <v-expansion-panel title="Any nickname containing NSFW, and defamation are subject to renaming.">
            <v-expansion-panel-text>
                <ol type="A">
                    <li>First recidivism of that rule will lead to a deletion of the profile in fault.</li>
                    <li>Second recidivism of that rule will lead to a permanent ban of the account linked to the profile
                        at
                        fault.</li>
                </ol>
            </v-expansion-panel-text>
        </v-expansion-panel>
        <v-expansion-panel title="Ramming is not allowed in our server, despite that it is part of racing there is limit to not cross.">
            <v-expansion-panel-text>
                <ol type="A">
                    <li>If there is video proofs that shows that someone ramming the player is subject to a temporary ban.</li>
                    <li>If there is recidivism after a temp ban, the faulty account will be definitively banned.</li>
                </ol>
            </v-expansion-panel-text>
        </v-expansion-panel>
        <v-expansion-panel title="Everyone have to respect in-game language chats, if you select EN chat you must speak english.">
            <v-expansion-panel-text>
                <ol type="A">
                    <li>If there is any proofs of someone not respecting this, the player is subject to a temporary ban.</li>
                    <li>If there is recidivism after a temp ban, a longer temp ban will be initiated.</li>
                </ol>
            </v-expansion-panel-text>
        </v-expansion-panel>
        <v-expansion-panel title="Any car liveries containing NSFW, defamation, political and religious sings are subject to a deletion.">
            <v-expansion-panel-text>
                <ol type="A">
                    <li>At the first recidivism the car in fault will be deleted and the player will be temporary banned.</li>
                    <li>At the second recidivism the account of the player will be definitively banned.</li>
                </ol>
            </v-expansion-panel-text>
        </v-expansion-panel>
        <v-expansion-panel title="Cheating is prohibited, cheaters are subject to a temporary or definitive ban depending the gravity of cheats.">
            <v-expansion-panel-text>
                <ol type="A">
                    <li>If there is recidivism the account in fault will be definitively banned.</li>
                </ol>
            </v-expansion-panel-text>
        </v-expansion-panel>
        <v-expansion-panel title="Any game modifications except the ones available in Discord and/or shader mods such as ReShade, SweetFX,
                ENB, WorldShader are prohibited.">
            <v-expansion-panel-text>
                <ol type="A">
                    <li>Depending the degree of the modifications of the game the player is subject to a temporary or definitive ban.</li>
                </ol>
            </v-expansion-panel-text>
        </v-expansion-panel>
        <v-expansion-panel title="Every clashes via the public in-game chats are prohibited and subject to a kick of the players concerned.">
            <v-expansion-panel-text>
                <ol type="A">
                    <li>If there is recidivism the players concerned will be temporary banned.</li>
                </ol>
            </v-expansion-panel-text>
        </v-expansion-panel>
        <v-expansion-panel title="Begging for money is not allowed, if someone refuses to give money you have to respect his choice.">
            <v-expansion-panel-text>
                <ol type="A">
                    <li>If an user keep begging after being warned he will be temporarily banned.</li>
                </ol>
            </v-expansion-panel-text>
        </v-expansion-panel>
    </v-expansion-panels>
</template>

<style>

.v-expansion-panel-text {
    padding: 5px;
}

.v-expansion-panel-title {
    margin: 0;
}

.v-expansion-panels {
    margin-bottom: 10px;
}

.v-expansion-panel {
    background: var(--primary-color)!important;
}
</style>