<script setup>
import {
    ref,
    getCurrentInstance,
    onMounted
} from 'vue'
import axios from 'axios'

const {
    proxy
} = getCurrentInstance()

const streams = ref([]);

async function fetchTwitchStreams() {
    try {
        const response = await axios.get('https://api.nightriderz.world/twitch/streams.php');
        streams.value = response.data.data.map(stream => ({
            ...stream,
            thumbnail_url: stream.thumbnail_url.replace('{width}x{height}', '318x178')
        }));
    } catch (error) {
        console.error("Failed to fetch Twitch streams:", error);
    }
}

onMounted(() => {
    fetchTwitchStreams();
    setInterval(fetchTwitchStreams, 60000); // Appel périodique toutes les 10 secondes
});

</script>

<template>
            <v-row align="start">
              <v-col cols="12" sm="6" md="6" lg="4" v-for="stream in streams" :key="stream.id">
                    <v-card style="width: 330px;padding-left: 0;padding-right: 0;background: #34275e;border-radius: 8px;box-shadow: none;">
                        <v-card style="position: absolute;z-index: 500;padding: 0 5px 0 5px ;background: red;font-size: 12px;left: 12px;top:12px;border-radius: 2px;box-shadow: none;">LIVE</v-card>
                        <v-card style="position: absolute;z-index: 500;padding: 0 5px 0 3px ;background: #12141c;font-size: 12px;right: 12px;top:160px;border-radius: 2px;box-shadow: none;"><v-icon style="font-size: 12px;margin-right: 2px;">mdi-account</v-icon>{{ stream.viewer_count}}</v-card>
                        <v-card style="position: relative; z-index: 404;background: #11141c;width: 318px!important ;height: 178px!important;margin-left: auto;margin-right: auto;border-radius: 4px;margin-top: 6px;box-shadow: none;"><v-icon style="display:flex; margin-left: auto;margin-right: auto;margin-top: 50px;font-size: 60px;">mdi-reload</v-icon><v-img :src="stream.thumbnail_url" style="position: absolute;z-index: 405;background: #11141c;width: 318px!important;top: 0px;"></v-img></v-card>
                        <v-card class="d-flex align-center justify-center" style="margin-left: 6px;margin-right: 6px;margin-top: 6px;padding-left: 4px;padding-right:4px;box-shadow: none;background: #12141c;font-size: 16px;font-weight: 600;text-align: center;">{{ stream.user_name }}</v-card>
                        <v-card class="d-flex align-center justify-center"style="margin-left: 6px;margin-right: 6px;margin-top: 6px;padding-left: 4px;padding-right:4px;box-shadow: none;background: #12141c;font-size: 14px;height: 50px;">{{ stream.title }}</v-card>
                        <v-btn :href="'https://www.twitch.tv/' + stream.user_name" target="_blank" style="width: 318px;margin-left: 6px;margin-right: 6px;margin-top: 6px;margin-bottom: 6px;box-shadow: none;background: #12141c;font-weight: 600;"><v-icon style="margin-right: 10px;">mdi-twitch</v-icon>WATCH ON TWITCH</v-btn>
                    </v-card>
                </v-col>
            </v-row>
            <p style="margin-top: 30px;font-size: 16px;">In order to list your <b>stream</b> here you need to set your <b>game category</b> to <b>Need for Speed: World</b> and have <b>nightriderz</b> or <b>nrz</b> in your stream <b>title</b>.</p>
</template>

<style scoped>
footer {
    background: var(--primary-color);
    padding-top: 40px;
    padding-bottom: 40px;
    margin-top: 40px;
}

.grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    background: var(--secondary-color);
    margin-top: 20px;
}

[home='true'] {
    margin-top: 0;
}

button {
    margin: 20px 10px;
}
</style>