<script setup>
import {
    ref,
    getCurrentInstance
} from 'vue'
import {
    DriverStore
} from '../pinia/Driver.js'
import PayPal from './PayPal.vue'
const Driver = DriverStore()

const {
    proxy
} = getCurrentInstance()

async function capture(data) {

    const res = await proxy.$api({
        serviceName: "paypal",
        methodName: "verify",
        parameters: [data.orderID]
    })
    if (res == "ok") {
        Driver.user.premium = "1"
    }

}
</script>

<template>
<div v-if="Driver.user.premium == '0'">

    <div v-if="Driver.user.discord == null">
        <p>To be able to subscribe to the premium, you must have a discord account linked to your NightRiderz account.</p>
    </div>

    <div v-if="Driver.user.discord != null">
        <p>
            The Premium option is there to help us support the cost of the dedicated server, in return you profit of things that a normal player cannot have. You can only buy the Premium once per account, there will be more options available in the future. 
        </p>
        <br>
        <p>
            The benefits given with Premium will automatically land on the driver currently selected on website.
        </p>
        <br>
        <p>The price is <b>5.99€</b>, lifetime.</p>
        <br>
        <PayPal @capture="capture" />
    </div>

</div>

<div v-else="Driver.user.premium">
    <p>Your premium benefits are active, thanks you for the support.</p>
</div>
</template>

<style scoped>

</style>
