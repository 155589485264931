<script setup>
import leaderboardCard from "../components/leaderboardCard.vue"
import SKleaderboardCard from "../components/skeletons/SKleaderboardCard.vue"
import {
    getCurrentInstance,
    ref,
    computed,
    watch
} from 'vue'
import {
    useRoute
} from 'vue-router'
import {
    ServerStore
} from "../pinia/Server.js"

const {
    proxy
} = getCurrentInstance()

const Server = ServerStore()
const route = useRoute()
const classes = ref([])
const chips_class = ref(0)
const isTimeAttackMode = ref(false) // Propriété réactive pour le mode Time-Attack

GetServerInfo()

async function GetServerInfo() {
    var classs = await proxy.$api({
        serviceName: "server",
        methodName: "moonset"
    })
    Server.classes = classs.classes
    var res = ['ALL', 'O']
    classs.classes.reverse().forEach((e, i) => {
        res.push(e.name.replace('CLASS ', ''))

    })
    classes.value = res
    chips_class.value = classes.value.indexOf(route.params.class) > 0 ? classes.value.indexOf(route.params.class) : 0
}

const modes = [{
    name: "ALL",
    value: "all",
    path: "all"
}, {
    name: "CIRCUIT",
    value: "4",
    path: "circuit"
}, {
    name: "SPRINT",
    value: "9",
    path: "sprint"
}, {
    name: "DRAG",
    value: "19",
    path: "drag"
}, {
    name: "TEAM ESCAPE",
    value: "24",
    path: "team_escape"
}]
const inputsearch = ref("")
var races = ref([])

const computedRaces = computed(() => {
    var result = []
    var racesTemp = races.value

    if (route.params.type != "all" && !route.params.class)
        result = racesTemp.filter(i => i.race.eventModeId == modes[modes.findIndex((item) => item.path === route.params.type)].value && i.race.name.toLowerCase().includes(inputsearch.value.toLowerCase()))
    else if (route.params.type != "all" && route.params.class && route.params.class != "ALL")
        result = racesTemp.filter(i => i.race.eventModeId == modes[modes.findIndex((item) => item.path === route.params.type)].value && Server.getLetterByHash(i.race.carClassHash) == route.params.class && i.race.name.toLowerCase().includes(inputsearch.value.toLowerCase()))
    else if (route.params.type == "all" && route.params.class && route.params.class != "ALL")
        result = racesTemp.filter(i => Server.getLetterByHash(i.race.carClassHash) == route.params.class && i.race.name.toLowerCase().includes(inputsearch.value.toLowerCase()))
    else if (inputsearch.value)
        result = racesTemp.filter(i => i.race.name.toLowerCase().includes(inputsearch.value.toLowerCase()))
    else
        result = racesTemp

    if (route.params.active == "rotation")
        result = result.filter(i => i.race.isEnabled == 1)

    // Filtrer les courses avec un id entre 0 et 4999 ou 5000 et 5999 en fonction du mode Time-Attack
    if (isTimeAttackMode.value) {
        result = result.filter(i => i.race.id >= 5000 && i.race.id <= 5999)
    } else {
        result = result.filter(i => i.race.id >= 0 && i.race.id <= 4999)
    }

    if (result.length > 0) {
        document.querySelector('.skeleton').style.display = "none"
        return result
    }
})

function search(e) {
    inputsearch.value = e
}

function toggleTimeAttackMode() {
    isTimeAttackMode.value = !isTimeAttackMode.value
}

watch(route, (newValue, oldValue) => {
    chips_class.value = classes.value.indexOf(newValue.params.class) > 0 ? classes.value.indexOf(newValue.params.class) : 0
})

callCards()

async function callCards() {
    const res = await proxy.$api({
        serviceName: "leaderboard",
        methodName: "GetCards",
        parameters: []
    })
    races.value = res
}
</script>

<template>
<v-app-bar :elevation="2">
    <v-icon icon="mdi-timer"></v-icon>
    <v-app-bar-title>Leaderboards</v-app-bar-title>
    <v-spacer></v-spacer>
    <v-btn @click="toggleTimeAttackMode" prepend-icon="mdi-clock-outline" variant="tonal">{{ isTimeAttackMode ? 'Normal Mode' : 'Time-Attack Mode' }}</v-btn>
    <template v-slot:extension>
        <v-tabs>
            <v-tab v-for="mode in modes" :to="'/leaderboards/' + mode.path + '/' + route.params.active + '/' + route.params.class">{{ mode.name }}</v-tab>
        </v-tabs>
        <v-spacer></v-spacer>
        <v-tabs>
            <v-tab :to="'/leaderboards/' + route.params.type + '/rotation' + '/' + route.params.class">Active Races</v-tab>
            <v-tab :to="'/leaderboards/' + route.params.type + '/all' + '/' + route.params.class">All races</v-tab>
        </v-tabs>
    </template>
</v-app-bar>

<div class="leaderboards">

    <div class="d-flex" style="margin: 30px 30px 0px 30px; justify-content: center;align-items: center;">
        <v-text-field @update:model-value="search" style="flex:1;margin-right: 20px;" label="Search a race" prepend-icon="mdi-magnify" variant="underlined"></v-text-field>
        <v-chip-group filter v-model="chips_class">
            <v-chip :value="index" v-for="(classe, index) in classes" :to="'/leaderboards/' + route.params.type + '/' + route.params.active + (classe != 'ALL' ? '/'+classe : '') + ''">{{classe}}</v-chip>
        </v-chip-group>

    </div>

    <div class="grid skeleton">
        <SKleaderboardCard v-for="i in 40" />
    </div>

    <div class="grid">
        <leaderboardCard :key="race.id" :data="race" v-for="race in computedRaces" v-if="computedRaces" />
        <div v-else>Sorry, no matching race.</div>
    </div>
</div>
</template>

<style scoped>
.grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    background: var(--secondary-color);
    margin-top: 20px;
}

.button {
    box-shadow: none;
    background: 0 0;
    border: none;
    border-radius: 6px;
    position: relative;
    height: 36px;
    padding: 0 16px;
    display: inline-block;
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 0;
    overflow: hidden;
    will-change: box-shadow;
    transition: box-shadow .2s cubic-bezier(.4, 0, 1, 1), background .2s cubic-bezier(.4, 0, .2, 1), color .2s cubic-bezier(.4, 0, .2, 1);
    outline: none;
    cursor: pointer;
    text-decoration: none;
    text-align: center;
    line-height: 36px;
}

.nav {
    background-color: var(--secondary-color);
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 10px 10px 10px;
    padding: 5px;
}

.containernav {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.button:hover {
    background: var(--quaternary-color);
    color: #f1f2f3;
}

.button.router-link-active {
    color: white !important;
    border-left: 2px solid white;
    background: var(--tertiary-color);
}

.header {
    display: flex;
    align-items: center;
    background: var(--primary-color);
}
</style>
