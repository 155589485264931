<script setup>
import {
    ref,
    getCurrentInstance,
    onMounted
} from 'vue'
import axios from 'axios'
import homeSection from '../configs/home.json'
import Download from './Download.vue'
import Streams from '../components/Streams.vue'
import background from '../configs/background.json'

const {
    proxy
} = getCurrentInstance()
var DiscordOnline = ref(0)
var backgroundset = ref(false)
var ServerInformation = ref([])
var number = Math.random() * (10 - 1) + 1;
var bgr = background[Math.floor(number)]

getDiscord()
getServerInformation()

function getDiscord() {
    proxy.$http.get("https://discordapp.com/api/guilds/441259702454845450/widget.json").then(response => {

        DiscordOnline.value = response.data.presence_count

    }).catch(function (error) {
    })
}

const streams = ref([]);

async function fetchTwitchStreams() {
    try {
        const response = await axios.get('https://api.nightriderz.world/twitch/streams.php');
        streams.value = response.data.data.map(stream => ({
            ...stream,
            thumbnail_url: stream.thumbnail_url.replace('{width}x{height}', '281x158')
        }));
    } catch (error) {
        console.error("Failed to fetch Twitch streams:", error);
    }
}

onMounted(() => {
    fetchTwitchStreams();
    setInterval(fetchTwitchStreams, 60000); // Appel périodique toutes les 10 secondes
});

async function getServerInformation() {
    const res = await proxy.$api({
        serviceName: "server",
        methodName: "GetServerInformation"
    })
    ServerInformation.value = res
}

var items = [{
        id: 1,
        color: 'info',
        icon: 'mdi-information',
    },
    {
        id: 2,
        color: 'error',
        icon: 'mdi-alert-circle',
    }
]

function getBackground() {
    var number = Math.random() * (10 - 1) + 1;
    var bgr = background[Math.floor(number)]
    return 'background:linear-gradient(to bottom, rgba(136, 0, 255, 0) 53%, rgb(52 39 94) 100%),url(https://cdn.nightriderz.world/images/website/moonset/bgr/' + bgr.index + '.png) no-repeat ' + bgr.position +';background-size:cover;'
}
</script>

<template>
<div class="home">

    <div class="bgr" :style="'background:linear-gradient(to bottom, rgba(136, 0, 255, 0) 53%, rgb(52 39 94) 100%),url(https://cdn.nightriderz.world/images/website/moonset/bgr/' + bgr.index + '.png) no-repeat ' + bgr.position +';background-size:cover;'"></div>
    <!-- BEGIN OF EVENT ALERT
    <v-alert title="COMMUNITY EVENT - HALLOWEEN" type="info" icon="mdi-calendar-star" style="flex: initial;margin: 20px !important;background-color: rgb(255, 94, 0)!important;">
    <div class="d-flex" style="align-items: center;">24 October 2023 to 31 October 2023<div class="spacer"></div>
    <Router-link to="/event/halloween23">
    <v-btn variant="outlined" append-icon="mdi-arrow-right" style="margin-top:0;background: initial;">
  More infos
</v-btn></Router-link></div>
</v-alert>
END OF EVENT ALERT -->
    <section class="wrapper" style="margin-bottom:100px">

        <img src="https://cdn.nightriderz.world/images/website/logo/NRZ_Normal.svg" alt="NIGHTRIDERZ small logo" style="padding-top:100px;padding-bottom:100px;margin-top:50px">
        <span>The Horizon of Need for Speed: World is waiting for you.</span>
        <p>NightRiderz is an open-source project which has started in May 2018, our main goal is to restore the game in its original state, with more features and cars.<br>We are affiliated with <b>Soapbox Race World</b>, a team of developers that has basically re-created <b>NFS: World</b> servers and shared it with us.</p>

        <Download :home="true" />

        <!--<small>{{ ServerInformation.onlineNumber }} / {{ number_format(ServerInformation.numberOfRegistered) }} {{ ServerInformation.onlineNumber > 1 ? "PLAYERS ONLINE" : "PLAYER ONLINE" }}</small>-->

    </section> 
    <!--<section class="section" v-if="streams.length < 1" style="background: #12141c;">
        <div class="container">
            <div style="flex:1">
                <h2>Streamers</h2>
                    <p style="font-size: 16px;">There is currently no streamer on <b>Need for Speed: World</b> playing on our server.</p>
                    <p style="font-size: 16px;margin-top: 6px;">In order to list your <b>stream</b> here you need to set your <b>game category</b> to <b>Need for Speed: World</b> and have <b>nightriderz</b> or <b>nrz</b> in your stream <b>title</b>.</p>
            </div>
        </div>
    </section>-->
    <section v-if="streams.length > 0" class="section" style="background: var(--primary-color)!important;">
        <v-expand-transition>
        <div class="container">
            <div style="flex:1;">
                <h2>Streamers on NRZ</h2>
                <Streams />
            </div>
        </div>
    </v-expand-transition>
    </section>
    <!--
    <section class="section" v-show="streams.length > 0" style="background: #12141c;">
        <div class="container">
            <div style="flex:1">
                <h2>Streamers on NRZ</h2>
                <v-row>
                <v-col cols="2" md="4" v-for="stream in streams" :key="stream.id">
                    <v-card style="width: 294px;padding-left: 0;padding-right: 0;background: #34275e;border-radius: 8px;box-shadow: none;margin-left: -36px;">
                        <v-card style="position: absolute;z-index: 500;padding: 0 5px 0 5px ;background: red;font-size: 12px;left: 12px;top:12px;border-radius: 2px;box-shadow: none;">LIVE</v-card>
                        <v-card style="position: absolute;z-index: 500;padding: 0 5px 0 3px ;background: #12141c;font-size: 12px;right: 12px;top:142px;border-radius: 2px;box-shadow: none;"><v-icon style="font-size: 12px;margin-right: 2px;">mdi-account</v-icon>{{ stream.viewer_count}}</v-card>
                        <v-card style="position: relative; z-index: 404;background: #11141c;width: 281px!important ;height: 158px!important;margin-left: auto;margin-right: auto;border-radius: 4px;margin-top: 6px;box-shadow: none;"><v-icon style="display:flex; margin-left: auto;margin-right: auto;margin-top: 50px;font-size: 60px;">mdi-reload</v-icon><v-img :src="stream.thumbnail_url" style="position: absolute;z-index: 405;background: #11141c;width: 281px!important;top: 0px;"></v-img></v-card>
                        <v-card class="d-flex align-center justify-center" style="margin-left: 7px;margin-right: 7px;margin-top: 7px;padding-left: 4px;padding-right:4px;box-shadow: none;background: #12141c;font-size: 16px;font-weight: 600;text-align: center;">{{ stream.user_name }}</v-card>
                        <v-card class="d-flex align-center justify-center"style="margin-left: 7px;margin-right: 7px;margin-top: 7px;padding-left: 4px;padding-right:4px;box-shadow: none;background: #12141c;font-size: 14px;height: 50px;">{{ stream.title }}</v-card>
                        <v-btn :href="'https://www.twitch.tv/' + stream.user_name" target="_blank" style="width: 281px;margin-left: 7px;margin-right: 7px;margin-top: 7px;margin-bottom: 7px;box-shadow: none;background: #12141c;font-weight: 600;"><v-icon style="margin-right: 10px;">mdi-twitch</v-icon>WATCH ON TWITCH</v-btn>
                    </v-card>
                </v-col>
            </v-row>
            <p style="margin-top: 30px;font-size: 16px;">In order to list your <b>stream</b> here you need to set your <b>game category</b> to <b>Need for Speed: World</b> and have <b>nightriderz</b> or <b>nrz</b> in your stream <b>title</b>.</p>
            </div>
        </div>
    </section>-->
    <section class="section" v-for="(section, index) in homeSection" :key="section.title" :style="index%2 > 0 ? 'background: var(--primary-color)!important;':''">
        <div class="container">
            <div v-if="index%2 > 0">
                <a :href="section.image_big" target="_blank"><img class="elevation-3 rounded" :src="section.image_url" :alt="section.title" style="height: auto;box-shadow: none!important"></a>
            </div>
            <div>
                <h2>{{ section.title }}</h2>
                <p>{{ section.description }}</p>

                <router-link :to="'/' + section.button_href" v-if="section.button_href != 'discord'">
                    <v-btn v-if="section.button" variant="tonal">{{ section.button_label }}</v-btn>
                </router-link>
                <a href="https://discord.com/invite/VarWbyq" target="_blank" v-else>
                    <v-btn v-if="section.button" variant="tonal">{{ section.button_label }}</v-btn>
                </a>
            </div>
            <div v-if="index%2 == 0">
                <a :href="section.image_big" target="_blank"><img class="elevation-3 rounded" :src="section.image_url" :alt="section.title" style="height: auto;box-shadow: none!important"></a>
            </div>

        </div>
    </section>
    <section class="section">
        <div class="container">
            <div style="flex:1">
                <h2>Community</h2>
                <p>
                    Find all the game info and an active community on our Discord.<br>
                    Anyone can speak in any language to just chat, share or ask for help.
                </p>
                <a href="https://discord.com/invite/VarWbyq" target="_blank">
                    <v-btn prepend-icon="mdi-discord" variant="tonal">
                        Join Discord ({{ number_format(DiscordOnline) }} online)
                    </v-btn>
                </a>
            </div>
        </div>
    </section>


</div>
</template>

<style scoped>
.slide-fade-enter-active, .slide-fade-leave-active {
  transition: all 5s ease;
}
.slide-fade-enter, .slide-fade-leave-to /* Début et fin de l'état de transition */ {
  transform: translateY(-100%);
  opacity: 0;
}


.right {
    text-align: right;
}

.rounded {
    border-radius: 6px !important;
}

.section {
    padding-bottom: 4rem;
    padding-top: 4rem;
    display: flex;
    justify-content: center;
    margin-left: 24px;
    margin-right: 24px;
    border-radius: 6px!important;
}

.container {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 1140px;
}

.container img {
    width: 475px;
}

.container h2 {
    font-size: 2.0rem;
    text-transform: uppercase;
    margin-bottom: 10px;
}

.container p {
    font-size: 1.25rem;
    font-weight: 400;
}

.container div:first-child {
    padding-left: 3rem;
    padding-right: 3rem;
}

.section-title {
    font-size: 30px;
    margin: 35px auto 0 0;
    text-align: center;
    line-height: 36px;
    font-weight: 500 !important;
}

button {
    background: var(--tertiary-color);
    margin-top: 30px;
}

.home {
    display: flex;
    flex-direction: column;
    flex: 1;
    background: rgb(52 39 94);
}

.wrapper {
    padding-bottom: 0px;
    margin-bottom: 35px;
    display: block;
    min-height: 200px;
    max-height: 633px;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    z-index: 1;
}

.wrapper img {
    height: 300px;
    filter: drop-shadow(0 0 0.75rem black);
    margin-bottom: -45px;
    margin-top: -45px;
}

.wrapper span {
    text-shadow: 0px 0px 5px black;
    border-radius: 5px;
    padding: 15px;
    display: block;
    margin: 15px;
    font-size: 34px;
}

.wrapper p {
    margin: 0px 0 60px 0;
    font-size: 15px;
}

.about,
.status,
.strong,
.infos {
    max-width: 1100px;
    margin: 0 auto;
    padding: 20px 0px;
    color: white;
    text-align: center;
}

.infos {
    align-items: center;
    flex: 1;
    margin: 30px 0;
    padding: initial;
    display: flex;
    justify-content: center;
    max-width: initial;
}

.about div {
    display: flex;
    flex: 1;
}

.infos div {
    flex: 1;
    max-width: 250px;
}

.about div p {
    padding: 25px;
}

.v-card {
    flex-direction: column;
}

.strong {
    text-align: left;
    display: flex;
}

.strong .v-list {
    flex: 1;
    margin: 20px;
}

.bgr {

    display: block;
    height: 633px;
    position: absolute;
    left: 60px;
    right: 0;
    z-index: 0;
    background-size: cover;
}
</style>
