<script setup>
    import { toRefs } from 'vue'

const props = defineProps({
isHome: Boolean
})
const {isHome} = toRefs(props)

</script>

<template>
<div v-if="isHome" style="background: #34275e;">
    <v-footer class="text-center d-flex flex-column" :home="isHome" style="margin-left: 24px;margin-right: 24px;margin-bottom: 24px;border-radius: 6px;margin-top: 24px;">

<img src="https://cdn.nightriderz.world/images/website/logo/NRZ_Wide.svg" height="30"><br>
  <div class="pt-0">© NIGHTRIDERZ {{ new Date().getFullYear() }}. All Rights Reserved.<br>
    This project is not affiliated with <b>Electronic Arts</b> or the <b>Need for Speed™</b> brand in any way, shape or form. <b>Need for Speed</b> is a trademark of <b>Electronic Arts</b>.
  </div>

</v-footer>
</div>
<div v-else>
    <v-footer class="text-center d-flex flex-column" :home="isHome" style="margin-left: 24px;margin-right: 24px;margin-bottom: 24px;border-radius: 6px;margin-top: 24px;">

<img src="https://cdn.nightriderz.world/images/website/logo/NRZ_Wide.svg" height="30"><br>
  <div class="pt-0">© NIGHTRIDERZ {{ new Date().getFullYear() }}. All Rights Reserved.<br>
    This project is not affiliated with <b>Electronic Arts</b> or the <b>Need for Speed™</b> brand in any way, shape or form. <b>Need for Speed</b> is a trademark of <b>Electronic Arts</b>.
  </div>

</v-footer>
</div>
</template>

<style scoped>
footer {
    background: var(--primary-color);
    padding-top: 40px;
    padding-bottom: 40px;
    margin-top: 40px;
}

[home='true'] {
    margin-top: 0;
}

button {
    margin: 20px 10px;
}
</style>