import { defineStore } from 'pinia'
import router from '../router/index.js'

export const DriverStore = defineStore('DriverStore', {
    state: () => ({
        // État initialisé directement dans la fonction pour assurer la réactivité
        persona: [],
        user: [],
        logged: false,
        others: [],
        crew: [],
        drawer: false,
        drawerTo: "",
        currentCrew: []
    }),

    actions: {
        // Actions pour manipuler le localStorage
        setEasharpptr_u(e) {
            localStorage.setItem('easharpptr_u', e);
        },
        setEasharpptr_p(e) {
            localStorage.setItem('easharpptr_p', e);
        },
        getEasharpptr_u() {
            return localStorage.getItem('easharpptr_u');
        },
        getEasharpptr_p() {
            return localStorage.getItem('easharpptr_p');
        },

        // Actions pour modifier l'état
        setPersona(e) {
            this.persona = e;
        },
        setUser(e) {
            this.user = e;
        },
        setCrew(e) {
            this.crew = e;
        },
        setCurrentCrew(e) {
            this.currentCrew = e;
        },
        setOthers(e) {
            this.others = e;
        },
        setLogged(e) {
            this.logged = e;
        },
        showDrawer(e) {
            this.drawer = e;
        },
        showDrawerAuth(e, f) {
            this.drawer = e;
            this.drawerTo = f;
        },
        
        // Déconnexion et réinitialisation de l'état
        disconnect() {
            localStorage.clear(); // Effacer le localStorage
            this.$reset(); // Réinitialiser l'état du store
            router.push('/'); // Rediriger vers la page d'accueil
        }
    }
});
