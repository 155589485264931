<script setup>
import {
    ref,
    getCurrentInstance
} from 'vue'
import { useRoute, useRouter } from 'vue-router'
import districts from '../configs/districts.json'

const dialog = ref(false)
const model = ref()
const form_district = ref("")
const form_tag = ref()
const form_name = ref()
const form_motto = ref()
const color = ref("#8E43AD")
const valid = ref()
const error = ref("")
const router = useRouter()

const {
    proxy
} = getCurrentInstance()

const tagRules = [
    v => !!v || 'required',
    v => (v && v.length >= 2 && v.length <= 4) || '2-4 characters',
    v => (v && v.length >= 2 && v.length <= 4 && /^[a-zA-Z]+$/.test(v)) || 'Only letters'
]

const nameRules = [
    v => !!v || 'required',
    v => (v && v.length >= 3 && v.length <= 25) || 'Name must be 3-20 characters',
]

const mottoRules = [
    v => !!v || 'required'
]

const ColorRules = [
    v => !!v || 'required'
]

async function save() {

    if (!!form_district.value && !!form_tag.value && !!form_name.value && !!form_motto.value && !!color.value && valid.value) {

        const res = await proxy.$api({
            serviceName: "crew",
            methodName: "create",
            parameters: [form_tag.value, form_name.value, form_motto.value, color.value, form_district.value]
        })

        if(res == "name") 
            error.value = "Name is already used."

        if(res.state == "ok")
            router.push("/crew/headquarters/" + res.crew_id)

    }
}
</script>

<template>
<v-btn prepend-icon="mdi-plus-box-multiple" variant="tonal" @click="dialog = true">Create a crew</v-btn>

<v-dialog transition="dialog-bottom-transition" :width="500" v-model="dialog" scrollable>
    <v-card>
        <v-toolbar dark color="primary">
            <v-icon>mdi-plus-box-multiple</v-icon>
            <v-toolbar-title>Create a crew</v-toolbar-title>
            <v-spacer></v-spacer>

            <template v-slot:extension>
                <v-tabs v-model="model" centered>
                    <v-tab value="1">
                        Rules
                    </v-tab>
                    <v-tab value="2">
                        Create
                    </v-tab>
                </v-tabs>
            </template>

        </v-toolbar>

        <v-window v-model="model">
            <v-window-item value="1">
                <v-card>
                    <v-card-text style="padding: 25px;">
                        <p>From here you will be able to personalize your crew like you want.</p><br>

                        <p>Make sure that your crew respect the following rules :</p>
                        <ul>
                            <li>Do not create a crew with an offensive name</li>
                            <li>Do not create a crew with an offensive tag</li>
                            <li>Do not create a crew with an offensive motto</li>
                            <li>Do not upload a custom crew banner that shows NSFW stuff</li>
                        </ul>
                        <br>
                        <p>Our crew system is here to give you the ability to group up with team-mates in order to build a good crew.<br>
                            The crew system will keep evolving in the time, instead of just letting you the ability to create groups, we will provide crew battles, with rewards for the winners.<br>
                            You will get more informations about it later on NightRiderz's Discord server.
                        </p>
                        <br>
                        <p>If your crew isn't approved by our moderation you might be banned from the crew section.<br>
                            Based on the gravity of the infractions regarding crews, we can see the problem with you, the owner.<br>
                            Your crew will be DESTROYED if it doesn't respect the rules and if we cannot get in a agreement with the owner.<br>
                            Every bans from this system are DEFINITIVE and not appealable.</p>
                        <br>
                        <div>By creating a crew, you automatically accept the rules.</div>
                    </v-card-text>
                </v-card>
            </v-window-item>
            <v-window-item value="2">
                <v-card>
                    <v-card-text>

                        <v-alert icon="mdi-alert-circle" type="error" v-if="error">
                            {{ error }}
                        </v-alert>
                        <br>

                        <v-form v-model="valid">
                            <div class="d-flex" style="margin-top: 5px;">
                                <v-text-field v-model="form_tag" :rules="tagRules" label="Tag" variant="outlined" style="width: 100px;flex: initial;margin-right: 10px;"></v-text-field>
                                <v-text-field v-model="form_name" :rules="nameRules" label="Name" variant="outlined"></v-text-field>

                                <v-swatches class="color" row-length="8" v-model="color" popover-x="left" show-fallback></v-swatches>
                            </div>

                            <v-text-field v-model="form_motto" :rules="mottoRules" label="Motto" variant="outlined"></v-text-field>

                            <select v-model="form_district" class="v-field__input">
                                <option value="" disabled selected>Select your district</option>
                                <template v-for="district in districts">
                                    <option class="hr" disabled>{{ district.city }}</option>
                                    <option :value="quartier.id" v-for="quartier in district.loop">{{ quartier.name.replace(/ *\([^)]*\) */g, "")  }}</option>

                                </template>

                            </select>

                            <v-alert class="d-flex" color="primary" style="align-items: center;">
                                <div>
                                    <img src="https://cdn.nightriderz.world/images/website/boost.png">
                                    <span>40,000</span>
                                </div>
                                <v-spacer></v-spacer>
                                <div>
                                    <v-btn @click="save">Create</v-btn>
                                </div>
                            </v-alert>
                        </v-form>
                    </v-card-text>
                </v-card>
            </v-window-item>
        </v-window>

    </v-card>
</v-dialog>
</template>

<style>
li {
    margin-left: 40px;
    padding: 2px;
}

.v-list-item__prepend {
    font-size: 30px !important;
    margin-top: 10px;
}
</style><style scoped>
.hr {
    background: var(--primary-color);
    color: white;
    padding-left: 10px !important;
}

option {
    background: var(--secondary-color);
    padding-left: 5px;
}

select {
    border: 1px solid #686c73;
    padding: 12px;
    border-radius: 4px;
}

select:hover {
    border: 1px solid white;
}

.v-alert {
    margin-top: 20px !important;
}

.v-alert::v-deep(.v-alert__content) {
    display: flex !important;
    align-items: center;
    padding: 0;
}

.color {
    margin: 8px 0px 0px 10px;
}

button {
    margin: initial !important;
}

.v-text-field {
    margin-bottom: 10px;
}
</style><style>
.v-list-item-title {
    margin: 10px 0 !important;
}
</style>
