<script setup>
    import storeCardVue from '../components/storeCard.vue'
    import { ref, getCurrentInstance } from 'vue'

    const { proxy } = getCurrentInstance()
    const items = ref([])

    getStore()

    async function getStore() {
        const res = await proxy.$api({serviceName: "store", methodName: "GetProduct"})
        items.value = res
    }
</script>

<template>
    <v-app-bar :elevation="2">
        <v-icon icon="mdi-store"></v-icon>
        <v-app-bar-title>Merch</v-app-bar-title>
        <v-spacer></v-spacer>
        <a href="https://teespring.com/stores/nightriderz" target="_blank">
            <v-btn>
                Visit store
            <v-icon
            end icon="mdi-open-in-new"
            ></v-icon>
        </v-btn>
        </a>
    </v-app-bar>
    <div class="grid">
        <storeCardVue v-for="item in items" :key="item.id" :data="item"/>        
    </div>
</template>

<style scoped>
    .grid {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin-top: 20px;
        margin-bottom: 20px;
    }
</style>