<script setup>

</script>

<template>
  <v-card
    class="card"
    width="300"
  >

  <Skeletor width="100%" height="171"/> 

    <v-card-subtitle>
      <Skeletor width="100%" height="36px" /> 
    </v-card-subtitle>


  </v-card>
</template>

<style scoped>

.v-card-subtitle {
    background: var(--tertiary-color);
    padding: 0;
}
    .card {
        background: var(--primary-color);
        margin: 25px;
    }
</style>