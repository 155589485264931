<script setup>
import {
    ref,
    getCurrentInstance
} from 'vue'
import Rules from '../components/Rules.vue'
import benefits from '../configs/premium.json'
import Launchers from '../configs/launcher.json'
import PremiumForm from '../components/premiumForm.vue'
import {
    DriverStore
} from '../pinia/Driver.js'
const Driver = DriverStore()

var dialog = ref(false)
var model = ref()

</script>

<template>
<div id="menu" @click="dialog = true">
    <v-icon :icon="'mdi-star'" v-if="!Driver.logged"></v-icon>
    <v-icon v-else :icon="Driver.user.premium == '1' ? 'mdi-star-check' : 'mdi-star'"></v-icon>

    <v-tooltip activator="parent" location="right">Premium</v-tooltip>
</div>

<v-dialog transition="dialog-bottom-transition" :width="500" v-model="dialog" scrollable>
    <v-card>
        <v-toolbar dark color="primary">
            <v-icon>mdi-star</v-icon>
            <v-toolbar-title>Premium</v-toolbar-title>
            <v-spacer></v-spacer>

            <template v-slot:extension>
                <v-tabs v-model="model" centered>
                    <v-tab value="1">
                        About
                    </v-tab>
                    <v-tab value="2">
                        Benefits
                    </v-tab>
                    <v-tab value="3" v-if="Driver.logged">
                        Buy
                    </v-tab>
                </v-tabs>
            </template>

        </v-toolbar>

        <v-window v-model="model">
            <v-window-item value="1">
                <v-card>
                    <v-card-text>
                        <p>
                            The Premium option is there to help us support the cost of the dedicated server, in return you profit of things that a normal player cannot have.<br><br>
                            You can only buy the Premium once per account, there will be more options available in the future.<br><br>
                            More things will happen in the future for Premium members.<br><br>
                            Connect on Discord to stay aware about upcoming changes.<br><br>
                        </p>
                        <br>
                        <p>The price is <b>5.99€</b>, lifetime.</p>
                    </v-card-text>
                </v-card>
            </v-window-item>
            <v-window-item value="2">
                <v-card>
                    <v-card-text>
                        <v-list lines="two">
                            <v-list-item v-for="benefit in benefits" :title="benefit.title">
                                <span v-html="benefit.text"></span>
                                <template v-slot:prepend>
                                    <v-icon :icon="'mdi-' + benefit.icone"></v-icon>
                                </template>
                            </v-list-item>
                        </v-list>

                    </v-card-text>
                </v-card>
            </v-window-item>
            <v-window-item value="3" v-if="Driver.logged">
                <v-card>
                    <v-card-text>
                        <PremiumForm />
                    </v-card-text>
                </v-card>
            </v-window-item>
        </v-window>

    </v-card>
</v-dialog>
</template>

<style scoped>
.paypal {
    margin: 10px;
}

.v-list-item {
    padding: 3px;
}

.v-list-item::v-deep(.v-list-item-title) {
    margin-bottom: 2px !important;
}

.v-toolbar {
    background: linear-gradient(90deg, rgb(255 165 0) 0%, rgb(255 235 0) 100%) !important;
}

p {
    text-align: center;
}

.downloadbtn {
    display: flex;
    justify-content: center;
    margin: 10px 0;
}

button {
    margin: initial !important;
}

#menu {
    font-weight: bold;
    color: white;
    text-decoration: none;
    border-radius: 6px;
    margin-bottom: 10px;
    height: 40px;
    width: 40px;
    background: transparent;
    transition: all 0.15s ease-in-out;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    cursor: pointer;
    background: linear-gradient(90deg, rgb(255 165 0) 0%, rgb(255 235 0) 100%) !important;
}

button:hover {
  background: rgb(255 179 37 / 55%)!important;
}

#menu i {
    margin: 0 7px;
}

.router-link-active {
    font-weight: bold;
    border-left: 2px solid white;
    background: var(--tertiary-color) !important;
    color: white;
    vertical-align: middle;
}

#menu:hover {
    font-weight: bold;
    background: var(--quaternary-color);
    color: white;
    vertical-align: middle;
}
</style>
