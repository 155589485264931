<script setup>
import { ref, getCurrentInstance } from 'vue'
import { DriverStore } from '../pinia/Driver.js'

const { proxy } = getCurrentInstance()
const Driver = DriverStore()

const currency = ref('d')
const amount = ref(0)
const recipient = ref('')
const snackbar = ref(false)
const error = ref('')
const recipientRules = [
  v => /^[a-zA-Z0-9]*$/.test(v) || 'Le champ ne peut contenir que des lettres et des chiffres'
]

function save() {
  if (amount.value > 0 && recipient.value.length > 0 && recipientRules[0](recipient.value) === true) {
    proxy.$http.post("", JSON.stringify({ serviceName: "account", methodName: "money", parameters: [currency.value, amount.value, recipient.value] })
    ).then(response => {

      if (currency.value == "d")
        Driver.persona.cash = Driver.persona.cash - amount.value
      else
        Driver.persona.boost = Driver.persona.boost - amount.value

      currency.value = "d"
      amount.value = 0
      recipient.value = ''
      error.value = ''
      snackbar.value = true

    }).catch(function (response) {
      error.value = response.response.data.error
    })
  } else {
    error.value = 'Please enter a valid amount and recipient.'
  }
}

function filterRecipientInput() {
  recipient.value = recipient.value.replace(/[^a-zA-Z0-9]/g, '')
}
</script>

<template>

<h4>Welcome to Konopa Bank, {{ Driver.persona.name }}.</h4>
<br>
<v-snackbar :timeout="2000" color="deep-purple-accent-4" elevation="24" v-model="snackbar">
    Your transaction is successful.
</v-snackbar>

<v-alert class="error" v-if="error" :text="error" type="error"></v-alert>

<v-form v-if="Driver.persona.level >= 15">
  <v-container>
    <v-row>
      <div class="flexform">
        <v-label>Currencies</v-label>
        <v-radio-group v-model="currency" inline>
          <v-radio :label="number_format(Driver.persona.cash) + ' $'" value="d"></v-radio>
          <v-radio :label="number_format(Driver.persona.boost) + ' SB'" value="b"></v-radio>
        </v-radio-group>

        <v-text-field v-model="amount" type="number" min="0" max="1500000" label="Amount" variant="outlined"></v-text-field>

        <v-text-field 
          v-model="recipient" 
          label="Recipient" 
          variant="outlined" 
          :rules="recipientRules" 
          @input="filterRecipientInput"
        ></v-text-field>

        <v-btn class="save" variant="tonal" @click="save">SEND</v-btn>
      </div>
    </v-row>
  </v-container>
</v-form>
<div class="level15" v-else>
  You are new in the city.<br><br>
  Your bank account is currently in the process of transfer.<br><br>
  Please come back later.<br><br>
  (Level 15 required for the sender and recipient)
</div>

<br>
<div class="center">Any abuse for personal benefits will result by a ban.</div>

</template>

<style scoped>
.level15 {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 15px 0;
}
.center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.flexform {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.save {
  margin: 0 0 20px 0;
}
.error {
    margin-bottom: 25px !important;
}
</style>
