<script setup>
import {
    toRefs,
    ref,
    getCurrentInstance,
    computed
} from 'vue'
import {
    useRoute
} from 'vue-router'
import {
    DriverStore
} from '../pinia/Driver.js'
const Driver = DriverStore()

const route = useRoute()
const {
    proxy
} = getCurrentInstance()
const props = defineProps({
    data: Object,
    position: Number,
    showDriver: {
        type: Boolean,
        default: true
    }
})
const {
    data,
    position
} = toRefs(props)

const emit = defineEmits(['dialogData', 'refresh'])
const dialogData = () => {
    emit('dialogData', data.value.car.eventDataSetupHash, data.value)
    console.log(data)
}
const refresh = () => {
    emit('refresh')
}

const isSameId = computed(() => {
    return data.value.persona[0].ID === Driver.persona.ID;
});

async function deleteRow() {
    let race_id = route.params.id ? route.params.id : route.params.race_id

    const res = await proxy.$api({
        serviceName: "leaderboard",
        methodName: "deleteRow",
        parameters: [data.value.race.id, race_id]
    })
    emit('deleteRowEvent', data.value.race.id, race_id);
    refresh()
}

</script>



<template>
    <tr class="leaderboardrow" :class="{ 'highlight-red': isSameId }">
        <td><v-btn :class="'trophy trophy' + position" :prepend-icon="position < 4 ? 'mdi-trophy' : ''" disabled
                style="background: var(--primary-color);color:rgba(var(--v-theme-on-surface), 0)">{{ position }}</v-btn>
        </td>
        <td v-if="showDriver">
            <div class="containericone">
                <router-link :to="'/driver/' + data.persona[0].name">
                    <v-btn class="carclass" size="x-small">
                        <img :src="'https://cdn.nightriderz.world/images/website/icon-persona/' + data.persona[0].iconIndex + '.jpg'"
                            style="border-radius:50px!important">
                        <span>{{ data.persona[0].tag ? '[' + data.persona[0].tag + '] ' : '' }}{{ data.persona[0].name
                            }}</span>
                    </v-btn>
                </router-link>
            </div>
        </td>
        <td>
            <v-btn class="carclass" prepend-icon="mdi-car-cog" size="x-small" @click="dialogData"
                v-if="data.car.eventDataSetupHash">
                <img :src="'https://cdn.nightriderz.world/images/website/moonset/classes/' + carClasshHash(data.car.class) + '.png'"
                    :title="data.car.rating">
                <span>{{ data.car.manufactor }} {{ data.car.model }}</span>
            </v-btn>

            <v-btn class="carclass disabled" size="x-small" v-else>
                <img :src="'https://cdn.nightriderz.world/images/website/moonset/classes/' + carClasshHash(data.car.class) + '.png'"
                    :title="data.car.rating" style="pointer-events: initial;">
                <span>{{ data.car.manufactor }} {{ data.car.model }}</span>
            </v-btn>
        </td>
        <td class="details">

            <div class="containericone">
                <div class="icone" v-if="parseInt(data.race.lobbyId)">
                    <img src="https://cdn.nightriderz.world/images/website/Multiplayer.png"
                        title="Time made in multiplayer">
                </div>
                <div class="icone" v-else>
                    <img src="https://cdn.nightriderz.world/images/website/Singleplayer.png"
                        title="Time made in singleplayer">
                </div>
                <div class="icone" v-if="parseInt(data.race.perfectStart)">
                    <img src="https://cdn.nightriderz.world/images/website/icon-powerup/perfect.png"
                        title="Perfect Start">
                </div>
                <div class="icone" v-else>
                    <img class="gray" src="https://cdn.nightriderz.world/images/website/icon-powerup/perfect.png"
                        title="No Perfect Start">
                </div>

                <v-btn class="carclass pu" size="x-small" v-for="pu in JSON.parse(data.powerups)" :key="pu.id"
                    :title="pu.longDescription">
                    <img
                        :src="'https://cdn.nightriderz.world/images/website/moonset/parts/powerup/' + pu.icon.replaceAll('_no_bg', '').toUpperCase() + '.png'">
                    x {{ pu.count }}
                </v-btn>

            </div>
        </td>

        <!--
            <div v-if="data.document.eventModeId == '24">
                <div v-for="pu in data.cops" :key="pu.id">
                    <img :title="pu.description" :src="'https://cdn.nightriderz.world/images/website/icon-skill/' + pu.type + '.png"> x {{pu.value}}
                </div>
            </div>-->

        <td class=""><span
                style="background: #11141c;padding-top: 8px;padding-bottom:8px;padding-left: 6px;padding-right:6px;border-radius: 6px;margin-top: 6px!important;">{{
        msToHMS(data.race.eventDurationInMilliseconds) }}</span></td>
        <td class="" v-if="data.race.eventModeId == 4"><span
                style="background: #11141c;padding-top: 8px;padding-bottom:8px;padding-left: 6px;padding-right:6px;border-radius: 6px;margin-top: 6px!important;">{{
        msToHMS(data.race.bestLapDurationInMilliseconds) }}</span></td>
        <td class=""><span
                style="background: #11141c;padding-top: 8px;padding-bottom:8px;padding-left: 6px;padding-right:6px;border-radius: 6px;margin-top: 6px!important;">{{
        mpstokmh(data.race.topSpeed) }} km/h<v-tooltip activator="parent" location="bottom">{{
        mpstomph(data.race.topSpeed) }} mph</v-tooltip></span></td>
        <td class=""><span
                style="background: #11141c;padding-top: 8px;padding-bottom:8px;padding-left: 6px;padding-right:6px;border-radius: 6px;margin-top: 6px!important;">{{
                formatDate(data.race.date) }}</span></td>
        <td v-if="Driver.user.isAdmin == '1'"><v-btn size="x-small" icon="mdi-eye-off" @click="deleteRow"
                style="background: #11141c;"></v-btn></td>
    </tr>
</template>

<style scoped>
.disabled {
    pointer-events: none;
    background: #3b3b3b !important;
}

.v-btn--variant-tonal .v-btn__underlay {
    opacity: 0 !important;
}

.pu {
    pointer-events: none;
}

.pu img {
    pointer-events: initial;
}

.gray {
    filter: grayscale(1);
}

.trophy1 {
    background: #FFD700 !important;
}

.trophy2 {
    background: #C0C0C0 !important;
}

.trophy3 {
    background: #CD7F32 !important;
}

.trophy::v-deep(i),
.trophy::v-deep(.v-btn__content) {
    color: white;
}

.trophy1::v-deep(i),
.trophy1::v-deep(.v-btn__content),
.trophy2::v-deep(i),
.trophy2::v-deep(.v-btn__content),
.trophy3::v-deep(i),
.trophy3::v-deep(.v-btn__content) {
    color: black;
}

.leaderboardrow {
    margin: 10px 0;
    font-size: 13px;
}

.highlight-red {
    background: var(--tertiary-color) !important;
}

.details {
    padding-top: 2.5px !important;
    padding-bottom: 2.5px !important;
}

.leaderboardrow {
    margin: 10px 0;
    font-size: 13px;
}

.pus {
    display: flex;
}

.pu,
.icone,
.carclass {
    display: flex;
    align-items: center;
    margin: 0 10px 0 0;
    background-color: var(--primary-color);
    padding: 5px 8px;
    font-size: 12px;
    border-radius: 6px;
    cursor: help;
    height: 30px !important;
}

.persona {
    display: flex;
    align-items: center;
    margin: 0 10px 0 0;
    background-color: var(--primary-color);
    padding: 5px 8px;
    font-size: 12px;
    border-radius: 6px;
}

.persona img {
    margin-right: 5px;
    border-radius: 2px;
}

.persona span {
    display: flex;
    justify-content: center;
    margin: 0 5px;
}

.containericone {
    display: flex;
    margin: 5px 0;
}

.carclass {
    padding: 5px;
    margin-right: 4px;
    font-size: 13px;
    cursor: initial;
    padding-left: 8px;
    cursor: zoom-in;
}

.carclass::v-deep(.v-btn__prepend) {
    margin-right: 10px;
}

.carclass img {
    cursor: help;
    margin-right: 5px;
}

.carclass span {
    display: flex;
    flex: 1;
    justify-content: center;
    margin: 0 5px;
}

.icone {
    padding: 5px;
    margin-right: 4px;
}

.pu img {
    margin-right: 8px;
}

.flex {
    flex: 1;
}

img {
    height: 20px;
}

.size {
    width: 150px;
}

.rank {
    width: 50px;
    text-align: center;
}
</style>
