<script setup>
import DriverCard from "../components/driverCard.vue"

import {
    ref,
    getCurrentInstance
} from 'vue'
import SKdriverCard from '../components/skeletons/SKdriverCard.vue'
import {
    createPinia
} from "pinia"

const {
    proxy
} = getCurrentInstance()
const drivers = ref([])
const racers = ref([])
const active = ref("")
const count = ref("")
const search = ref('')
const delayTimer = ref(0)
const searchBoxClosed = ref(true)

getCards('online')

async function getCards(type) {
    drivers.value = []
    count.value = ''
    const res = await proxy.$api({
        "serviceName": "players",
        "methodName": "GetCards",
        "parameters": [type]
    })

    if (type == "online") {

        drivers.value = res.filter(i => i.xmpp == "IN FREEROAM")
        racers.value = res.filter(i => i.xmpp != "IN FREEROAM").sort((e, b) => {e.xmpp - b.xmpp})

    } else {
        drivers.value = res
        racers.value = []
    }

    active.value = type
    count.value = "(" + res.length + ")"
}

function searchF() {

    if (search.value.length > 0) {
        drivers.value = []
        racers.value = []
        clearTimeout(delayTimer.value);
        delayTimer.value = setTimeout(async () => {

            active.value = "search"
            const res = await proxy.$api({
                "serviceName": "players",
                "methodName": "GetCards",
                "parameters": [search.value]
            })
            drivers.value = res
        }, 100);

    } else {
        getCards('online')
    }
}
</script>

<template>

<v-app-bar :elevation="2">
    <v-icon icon="mdi-racing-helmet"></v-icon>
    <v-app-bar-title>Drivers</v-app-bar-title>
    <v-spacer></v-spacer>
    <v-responsive class="mx-auto" max-width="344">
        <v-text-field v-model="search" @keyup="searchF" class="search" density="compact" variant="solo" label="Search a driver" placeholder="Search a driver" append-inner-icon="mdi-magnify" single-line hide-details></v-text-field>
    </v-responsive>
    <template v-slot:extension>
        <v-tabs v-model="active">
            <v-tab value="online" @click="getCards('online')">Online {{ active == 'online' ? count : '' }}</v-tab>
            <v-tab value="friends" @click="getCards('friends')">Friends {{ active == 'friends' ? count : '' }}</v-tab>
            <v-tab value="creator" @click="getCards('creator')">Content Creator {{ active == 'creator' ? count : '' }}</v-tab>
            <v-tab value="staff" @click="getCards('staff')">Staff {{ active == 'staff' ? count : '' }}</v-tab>
            <v-tab value="search" v-if="search.length > 0">Search</v-tab>
        </v-tabs>
        <v-spacer></v-spacer>

    </template>

</v-app-bar>

<transition name="fade" mode="out-in">
    <div key=1 class="grid" v-if="drivers.length == 0">
        <SKdriverCard v-for="i in 60" />
    </div>

    <div key=2 class="grid" v-else>
        <driverCard v-for="driver in racers" :key="driver.id" :data="driver" />
        <driverCard v-for="driver in drivers" :key="driver.id" :data="driver" />
    </div>
</transition>
</template>

<style scoped>
.grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    background: var(--secondary-color);
    margin-top: 20px;
}

.v-btn {
    margin-right: 10px;
}

.active {
    color: white !important;
    border-left: 2px solid white;
    background: var(--tertiary-color);
}
</style>
