<script setup>
    import { ref } from 'vue'
    import controllers from '../configs/controllers.json'
    import controllerCard from '../components/controllerCard.vue'
    import unsupportedController from '../components/unsupportedController.vue'
    import performanceParts from './performanceParts.vue'

    const tab = ref(1)
</script>

<template>
    <v-app-bar :elevation="2">
        <v-icon icon="mdi-frequently-asked-questions"></v-icon>
        <v-app-bar-title>Guides</v-app-bar-title>
        <v-spacer></v-spacer>
        
        <a href="https://discord.gg/XJavNVH" target="_blank">
            <v-chip prepend-icon="mdi-discord" label>#need-help</v-chip>
        </a>

        <template v-slot:extension>
                <v-tabs
                v-model="tab"
                >
                <v-tab value="1">SUPPORTED CONTROLLERS</v-tab>
                <v-tab value="2">UNSUPPORTED CONTROLLERS</v-tab>
                <v-tab value="3">THIRD PARTY SOFTWARES</v-tab>
                <v-tab value="4">PERFORMANCE PARTS</v-tab>
                </v-tabs>
              
        </template>
    </v-app-bar>
    
    <div class="guide">

        <v-window v-model="tab">
            <v-window-item value="1">
                <p>Here is the list of controllers that work without third-party software. Be sure to plug in before starting the game.</p>

                <div class="grid">
                    <controllerCard v-for="controller in controllers" :id="controller.image_url" :data="controller"/>
                </div>
            </v-window-item>

            <v-window-item value="2">
                <unsupportedController />
            </v-window-item>

            <v-window-item value="3">
                <v-alert type="success" variant="tonal" title="Drift">
                    <span><b>Freeroam ONLY</b><br><small>Remember to restart your game to completely disable the behavior change.</small></span>
                </v-alert>

                <v-alert type="success" variant="tonal" title="Camera" />
                <v-alert type="success" variant="tonal" title="Vinyl" />
                <v-alert type="success" variant="tonal" title="ENB" />


                <v-alert type="error" variant="tonal" title="GMZ" />
                <v-alert type="error" variant="tonal" title="WallHack" />
                <v-alert type="error" variant="tonal" title="TankMod" />
                <v-alert type="error" variant="tonal" title="Speedhack" />
                <v-alert type="error" variant="tonal" title="GhostMods" />
                <v-alert type="error" variant="tonal" title="Car Changer" />
                <v-alert type="error" variant="tonal" title="Auto-Finish" />
                <v-alert type="error" variant="tonal" title="Auto-Start" />
                <v-alert type="error" variant="tonal" title="Auto-Pilot" />
                <v-alert type="error" variant="tonal" title="Kick Race Player" />
                <v-alert type="error" variant="tonal" title="PowerUps Hack" />
                <v-alert type="error" variant="tonal" title="P2P Lags" />
                <v-alert type="error" variant="tonal" title="Treasure Hunters Hack" />
                <v-alert type="error" variant="tonal" title="Cash Hack" />

            </v-window-item>

            <v-window-item value="4">
                <performanceParts />
            </v-window-item>
        </v-window>
    </div>
</template>

<style scoped>
.v-tabs {
    height: initial;
}

.guide {
    margin: 20px;
}

.grid {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin-top: 20px;
        margin-bottom: 20px;
    }
</style>
<style>
    .v-alert {
    margin: 10px 0px!important;
    display: flex!important;
    justify-content: initial!important;
    align-items: initial!important;
}

.v-chip {
    cursor: pointer!important;
}

</style>