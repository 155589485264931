<script setup>
import { toRefs } from 'vue'

const props = defineProps({
  data: Object
})
const { data } = toRefs(props)
// Fonction pour obtenir le texte du rang et de la division
const getRankDivision = (rankName, rankingPoints) => {
  let rankText = ""
  let divisionText = ""

  // Fonction pour convertir les nombres en chiffres romains
  const toRoman = (num) => {
    const romanNumerals = ["I", "II", "III", "IV", "V", "V"]
    return romanNumerals[num - 1] || num
  }

  if (rankingPoints >= 0 && rankingPoints <= 19) {
    rankText = "Bronze";
    divisionText = "I";
  } else if (rankingPoints >= 20 && rankingPoints <= 39) {
    rankText = "Bronze";
    divisionText = "II";
  } else if (rankingPoints >= 40 && rankingPoints <= 59) {
    rankText = "Bronze";
    divisionText = "III";
  } else if (rankingPoints >= 60 && rankingPoints <= 79) {
    rankText = "Bronze";
    divisionText = "IV";
  } else if (rankingPoints >= 80 && rankingPoints <= 99) {
    rankText = "Bronze";
    divisionText = "V";
  } else if (rankingPoints >= 100 && rankingPoints <= 129) {
    rankText = "Silver";
    divisionText = "I";
  } else if (rankingPoints >= 130 && rankingPoints <= 159) {
    rankText = "Silver";
    divisionText = "II";
  } else if (rankingPoints >= 160 && rankingPoints <= 189) {
    rankText = "Silver";
    divisionText = "III";
  } else if (rankingPoints >= 190 && rankingPoints <= 219) {
    rankText = "Silver";
    divisionText = "IV";
  } else if (rankingPoints >= 220 && rankingPoints <= 249) {
    rankText = "Silver";
    divisionText = "V";
  } else if (rankingPoints >= 250 && rankingPoints <= 289) {
    rankText = "Gold";
    divisionText = "I";
  } else if (rankingPoints >= 290 && rankingPoints <= 339) {
    rankText = "Gold";
    divisionText = "II";
  } else if (rankingPoints >= 340 && rankingPoints <= 379) {
    rankText = "Gold";
    divisionText = "III";
  } else if (rankingPoints >= 380 && rankingPoints <= 419) {
    rankText = "Gold";
    divisionText = "IV";
  } else if (rankingPoints >= 420 && rankingPoints <= 459) {
    rankText = "Gold";
    divisionText = "V";
  } else if (rankingPoints >= 460 && rankingPoints <= 509) {
    rankText = "Platinum";
    divisionText = "I";
  } else if (rankingPoints >= 510 && rankingPoints <= 559) {
    rankText = "Platinum";
    divisionText = "II";
  } else if (rankingPoints >= 560 && rankingPoints <= 609) {
    rankText = "Platinum";
    divisionText = "III";
  } else if (rankingPoints >= 610 && rankingPoints <= 659) {
    rankText = "Platinum";
    divisionText = "IV";
  } else if (rankingPoints >= 660 && rankingPoints <= 709) {
    rankText = "Platinum";
    divisionText = "V";
  } else if (rankingPoints >= 710 && rankingPoints <= 769) {
    rankText = "Diamond";
    divisionText = "I";
  } else if (rankingPoints >= 770 && rankingPoints <= 829) {
    rankText = "Diamond";
    divisionText = "II";
  } else if (rankingPoints >= 830 && rankingPoints <= 889) {
    rankText = "Diamond";
    divisionText = "III";
  } else if (rankingPoints >= 890 && rankingPoints <= 949) {
    rankText = "Diamond";
    divisionText = "IV";
  } else if (rankingPoints >= 950 && rankingPoints <= 1009) {
    rankText = "Diamond";
    divisionText = "V";
  } else if (rankingPoints >= 1010 && rankingPoints <= 1079) {
    rankText = "Riderz";
    divisionText = "I";
  } else if (rankingPoints >= 1080 && rankingPoints <= 1149) {
    rankText = "Riderz";
    divisionText = "II";
  } else if (rankingPoints >= 1150 && rankingPoints <= 1219) {
    rankText = "Riderz";
    divisionText = "III";
  } else if (rankingPoints >= 1220 && rankingPoints <= 1289) {
    rankText = "Riderz";
    divisionText = "IV";
  } else if (rankingPoints >= 1290 && rankingPoints <= 1360) {
    rankText = "Riderz";
    divisionText = "V";
  } else {
    rankText = "Unranked";
    divisionText = "";
  }

  // Ajoutez la classe pour l'opacité aux points RP
  const rankDivision = `${rankText} ${divisionText} <span style="opacity:15%">[ ${rankingPoints} RP ]</span>`;
  return rankDivision;
}

// Fonction pour obtenir l'image du rang
const getRankImage = (rankingPoints, nextRank) => {
  const rankImages = {
    Bronze: "https://cdn.nightriderz.world/images/website/ranked/Bronze.png",
    Silver: "https://cdn.nightriderz.world/images/website/ranked/Silver.png",
    Gold: "https://cdn.nightriderz.world/images/website/ranked/Gold.png",
    Platinum: "https://cdn.nightriderz.world/images/website/ranked/Platinum.png",
    Diamond: "https://cdn.nightriderz.world/images/website/ranked/Diamond.png",
    Riderz: "https://cdn.nightriderz.world/images/website/ranked/Riderz.png"
  };

  // Si le prochain rang est disponible, utilisez l'image de ce rang
  if (nextRank && rankImages[nextRank]) {
    return rankImages[nextRank];
  }

  // Sinon, utilisez l'image du rang actuel
  if (rankingPoints >= 0 && rankingPoints <= 99) {
    return rankImages["Bronze"];
  } else if (rankingPoints >= 100 && rankingPoints <= 249) {
    return rankImages["Silver"];
  } else if (rankingPoints >= 250 && rankingPoints <= 459) {
    return rankImages["Gold"];
  } else if (rankingPoints >= 460 && rankingPoints <= 709) {
    return rankImages["Platinum"];
  } else if (rankingPoints >= 710 && rankingPoints <= 1009) {
    return rankImages["Diamond"];
  } else if (rankingPoints >= 1010 && rankingPoints <= 1360) {
    return rankImages["Riderz"];
  }
}

const getNextRankInfo = (rankingPoints) => {
  // Helper function to map ranking points to rank, division, and next division start points
  const determineRankAndDivision = (points) => {
    if (points <= 19) return { rank: 'Bronze', division: 'I', nextStart: 20 };
    else if (points <= 39) return { rank: 'Bronze', division: 'II', nextStart: 40 };
    else if (points <= 59) return { rank: 'Bronze', division: 'III', nextStart: 60 };
    else if (points <= 79) return { rank: 'Bronze', division: 'IV', nextStart: 80 };
    else if (points <= 99) return { rank: 'Bronze', division: 'V', nextStart: 100 };
    else if (points <= 129) return { rank: 'Silver', division: 'I', nextStart: 130 };
    else if (points <= 159) return { rank: 'Silver', division: 'II', nextStart: 160 };
    else if (points <= 189) return { rank: 'Silver', division: 'III', nextStart: 190 };
    else if (points <= 219) return { rank: 'Silver', division: 'IV', nextStart: 220 };
    else if (points <= 249) return { rank: 'Silver', division: 'V', nextStart: 250 };
    else if (points <= 289) return { rank: 'Gold', division: 'I', nextStart: 290 };
    else if (points <= 339) return { rank: 'Gold', division: 'II', nextStart: 340 };
    else if (points <= 379) return { rank: 'Gold', division: 'III', nextStart: 380 };
    else if (points <= 419) return { rank: 'Gold', division: 'IV', nextStart: 420 };
    else if (points <= 459) return { rank: 'Gold', division: 'V', nextStart: 460 };
    else if (points <= 509) return { rank: 'Platinum', division: 'I', nextStart: 510 };
    else if (points <= 559) return { rank: 'Platinum', division: 'II', nextStart: 560 };
    else if (points <= 609) return { rank: 'Platinum', division: 'III', nextStart: 610 };
    else if (points <= 659) return { rank: 'Platinum', division: 'IV', nextStart: 660 };
    else if (points <= 709) return { rank: 'Platinum', division: 'V', nextStart: 710 };
    else if (points <= 769) return { rank: 'Diamond', division: 'I', nextStart: 770 };
    else if (points <= 829) return { rank: 'Diamond', division: 'II', nextStart: 830 };
    else if (points <= 889) return { rank: 'Diamond', division: 'III', nextStart: 890 };
    else if (points <= 949) return { rank: 'Diamond', division: 'IV', nextStart: 950 };
    else if (points <= 1009) return { rank: 'Diamond', division: 'V', nextStart: 1010 };
    else if (points <= 1079) return { rank: 'Riderz', division: 'I', nextStart: 1080 };
    else if (points <= 1149) return { rank: 'Riderz', division: 'II', nextStart: 1150 };
    else if (points <= 1219) return { rank: 'Riderz', division: 'III', nextStart: 1220 };
    else if (points <= 1289) return { rank: 'Riderz', division: 'IV', nextStart: 1290 };
    else if (points <= 1360) return { rank: 'Riderz', division: 'V', nextStart: null }; // Last division has no next start
    else return null; // Points are outside of defined ranges
  };

  const current = determineRankAndDivision(rankingPoints);
  if (!current) return { nextRank: 'Out of Range', nextDivision: '', pointsToNextRank: 'N/A' };

  const pointsToNext = current.nextStart !== null ? current.nextStart - rankingPoints : 'N/A';

  if (current.nextStart === null) {
    // Maximum rank and division achieved
    return {
      nextRank: 'Maximum Rank Achieved',
      nextDivision: '',
      pointsToNextRank: 'N/A'
    };
  } else {
    // Determine the next rank and division
    const next = determineRankAndDivision(current.nextStart);
    return {
      nextRank: next ? next.rank : 'Maximum Rank Achieved',
      nextDivision: next ? next.division : '',
      pointsToNextRank: pointsToNext
    };
  }
};
</script>
<template>
  <v-container>
    <v-row no-gutters>
      <v-col>
        <v-sheet class="pa-2 ma-2">
          <img :src="getRankImage(data.rankingPoints)" style="height:75px;width:75px" :alt="data.role">
          <br>
          <span>CURRENT RANK</span>
          <span v-html="getRankDivision(data.role, data.rankingPoints)"></span>
        </v-sheet>
      </v-col>
      <v-col>
        <v-sheet class="pa-2 ma-2">
          <span>WINS</span>
          <span>{{ data.victories }}</span>
        </v-sheet>
      </v-col>
      <v-col>
        <v-sheet class="pa-2 ma-2">
          <span>LOSSES</span>
          <span>{{ data.defeats }}</span>
        </v-sheet>
      </v-col>
      <v-col>
        <v-sheet class="pa-2 ma-2">
          <img :src="getRankImage(data.rankingPoints, getNextRankInfo(data.rankingPoints).nextRank)"
            style="height:75px;width:75px" :alt="data.role">
          <br>
          <span>NEXT RANK</span>
          <span>{{ getNextRankInfo(data.rankingPoints).nextRank + " " + getNextRankInfo(data.rankingPoints).nextDivision
          }}<span style='opacity:15%' v-if="data.rankingPoints < 1290"> {{ " [ IN " +
  getNextRankInfo(data.rankingPoints).pointsToNextRank + " RP ]" }}</span></span>
        </v-sheet>
      </v-col>
    </v-row>
    <br>
    <v-row no-gutters>
      <v-col class="pa-2 ma-2">
        <h2>History</h2>
        <!-- Utilisation de v-for pour parcourir chaque match dans l'historique -->
        <!-- Utilisation de v-card pour afficher chaque résultat dans une carte -->
        <div v-for="(match, index) in data.rankedActivity" :key="index">
          <v-card v-if="match.pointsWon > 0" class="mb-3" style="background: linear-gradient(275deg, rgba(18,20,28,1) 20%, rgb(0, 159, 56) 100%);border-radius: 6px;">
  <v-card-text class="d-flex justify-space-between">
    <div>
      <h2>MATCH WON</h2>
      <!--Match {{ index + 1 }}:-->
      {{ match.date }}
    </div>
    <div class="d-flex align-center">
      <h2 class="align-center" style="font-weight: 600;margin-right:16px;">
        + {{ match.pointsWon }} RP
      </h2>
    </div>
  </v-card-text>
</v-card>

<v-card v-if="match.pointsLost > 0" class="mb-3" style="background: linear-gradient(275deg, rgba(18,20,28,1) 20%, rgb(146, 0, 17) 100%);border-radius: 6px;">
  <v-card-text class="d-flex justify-space-between">
    <div>
      <h2>MATCH LOST</h2>
      <!--Match {{ index + 1 }}:-->
      {{ match.date }}
    </div>
    <div class="d-flex align-center">
      <h2 class="align-center" style="font-weight: 600;margin-right:16px;">
        - {{ Math.abs(match.pointsLost) }} RP
      </h2>
    </div>
  </v-card-text>
</v-card>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<style scoped>
.v-sheet {
  color: white;
  display: flex;
  flex-direction: column;
  height: 200px;
  background: #11141c;
  background: var(--primary-color);
  font-size: 20px;
  font-weight: 400;
  text-transform: uppercase;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-bottom: 4px solid white;
  border-radius: 6px;
}

/* Style pour l'opacité des points RP */

svg {
  height: 75px;
  fill: white;
}

.PREMIUM {
  color: #d9be00;
  fill: #d9be00;
}

svg,
.FREEMIUM {
  color: #0054a5;
  fill: #0054a5;
}

.STAFF {
  color: rgb(173 74 255);
  fill: rgb(173 74 255);
}

.DEVELOPER {
  color: rgb(17, 252, 74);
  fill: rgb(17, 252, 74);
}

.CONTENT_CREATOR {
  color: rgb(255 5 33);
  fill: rgb(255 5 33);
}</style>
