<script setup>

    import { toRefs } from 'vue'

    const props = defineProps({
    data: Object
    })
    const {data} = toRefs(props)

</script>

<template>
  <v-card
    class="card"
  >

      <span>{{ data.nb }}</span>
      <h5>{{ data.categoryName }}</h5>

  </v-card>
</template>

<style scoped>

    span {
      font-size: 42px;
    }
    .card {
      min-height: 20px;
      margin-bottom: 20px;
      overflow: hidden;
      color: white;
      display: flex;
      flex-direction: column;
      width: 300px;
      height: 160px;
      background: #11141c;
      background: var(--primary-color);
      margin: 25px;
      padding: 20px;
      border-bottom: 4px solid white;
      border-radius: 6px;
    }

    .v-card-actions {
        margin: 10px 10px 0 10px;
    }
</style>