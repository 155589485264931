<script setup>
import { ref, getCurrentInstance } from 'vue'
import { DriverStore } from '../pinia/Driver.js'

const Driver = DriverStore()
const { proxy } = getCurrentInstance()

const mail = ref("" + Driver.user.EMAIL)
const password = ref('')
const snackbar = ref(false)
const error = ref('')

function save() {
    if(mail.value != Driver.user.EMAIL) {
        proxy.$http.post("", JSON.stringify({ serviceName: "account", methodName: "UpdateMail", parameters: [mail.value] })
        ).then(response => {

            error.value = ''
            Driver.user.EMAIL = mail.value
            snackbar.value = true

        }).catch(function (response) {
            error.value = response.response.data.error
        })
    }

    if(password.value.length > 0) {

        proxy.$http.post("", JSON.stringify({ serviceName: "account", methodName: "UpdatePass", parameters: [password.value] })
        ).then(response => {

            error.value = ''
            password.value = ''

        }).catch(function (response) {
            error.value = response.response.data.error
        })

    }

}
</script>

<template>
<div class="accountForm">
<v-snackbar
      :timeout="2000"
      color="deep-purple-accent-4"
      elevation="24"
      v-model="snackbar"
    >
      Your informations is saved.
    </v-snackbar>

    <v-alert class="error" v-if="error" :text="error" type="error"></v-alert>

    <v-form>
        <v-container>
            <v-row>
                <div class="flexform">
                    <v-text-field prepend-icon="mdi-email" v-model="mail" label="E-Mail" variant="outlined"></v-text-field>

                    <v-text-field prepend-icon="mdi-lock" v-model="password" label="New password" variant="outlined" hint="Password must contain at least 8 characters and must contain at least one capital letter and one numeric character."
                    persistent-hint type="password" autocomplete="new-password"></v-text-field>

                    <v-btn class="save" variant="tonal" @click="save">Save</v-btn>
                </div>
            </v-row>
        </v-container>
    </v-form>
</div>
</template>

<style scoped>
.flexform {
    display: flex;
    flex-direction: column;
    flex: 1;
}
.save {
    margin: 20px 0 0 0;
}

.accountForm {
    margin: 10px 0;
}

.error {
    margin-bottom: 25px!important;
}
</style>
<style>
.v-input__details {
    padding-inline-start: 2px!important;
}
</style>