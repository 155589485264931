<script setup>

</script>

<template>
    <div style="padding: 20px;">
        <p>Here you will find all the descriptions of the parts that are involved.</p>

        <br>
        <h3>Engines</h3>
        <v-divider></v-divider><br>
        <p>The engine is the heart of the car, provides the biggest change resulting in a win or loss.</p>

        <center>
            <img src="https://cdn.nightriderz.world/images/website/moonset/parts/perf/R1_ENGINE_SPEC.png">
            <img src="https://cdn.nightriderz.world/images/website/moonset/parts/perf/R2_ENGINE_SPEC.png">
            <img src="https://cdn.nightriderz.world/images/website/moonset/parts/perf/R3_ENGINE_SPEC.png">
            <img src="https://cdn.nightriderz.world/images/website/moonset/parts/perf/R4_ENGINE_SPEC.png">
            <img src="https://cdn.nightriderz.world/images/website/moonset/parts/perf/R5_ENGINE_BLACK.png">

            <p>The AmerikonSS/Gromlen/Medion/Transtek and QuartzSpeedPackages engines are all about delivering the
                middle of the road power output. Think of this as a Ram Air Intake upgrade, which increases engine power
                in the lower-mid range. It's usually muscle cars such as a Dodge Viper SRT10 or its full-size relative,
                the Dodge Charger SRT8, that benefit from this engine, but it will also work for exotic cars like the
                Ford GT and the SLR McLaren.</p>

            <img src="https://cdn.nightriderz.world/images/website/moonset/parts/perf/R1_ENGINE_B.png">
            <img src="https://cdn.nightriderz.world/images/website/moonset/parts/perf/R2_ENGINE_B.png">
            <img src="https://cdn.nightriderz.world/images/website/moonset/parts/perf/R3_ENGINE_B.png">
            <img src="https://cdn.nightriderz.world/images/website/moonset/parts/perf/R4_ENGINE_B.png">
            <img src="https://cdn.nightriderz.world/images/website/moonset/parts/perf/R5_ENGINE_B.png">

            <p>Considered to be the best engine you can get in Need for Speed: World, the
                Dawndraft/Kestrel/Velocicom/ZeroTuningMods and StickMotorsport engine is a high-torque powerplant
                designed to pack quite a punch, allowing for quicker acceleration. What this would be suitable for is a
                low-RPM car, i.e. many of the classic muscle cars in the game (Chevelle SS especially), or in a
                real-life application, a high-torque AMG Mercedes.</p>

            <img src="https://cdn.nightriderz.world/images/website/moonset/parts/perf/R1_ENGINE_R.png">
            <img src="https://cdn.nightriderz.world/images/website/moonset/parts/perf/R2_ENGINE_R.png">
            <img src="https://cdn.nightriderz.world/images/website/moonset/parts/perf/R3_ENGINE_R.png">
            <img src="https://cdn.nightriderz.world/images/website/moonset/parts/perf/R4_ENGINE_R.png">
            <img src="https://cdn.nightriderz.world/images/website/moonset/parts/perf/R5_ENGINE_R.png">

            <p>The Nova-T/Richter/Ventura/AttackMotorsport and TearPerformance engine, considered to be the worst brand
                for power parts according to the NFSW community, is the lighter version of AmerikonSS/Gromlen/Medion.
                The difference between the two is that this is NOT designed for V10 engines; rather they're more Inline
                oriented (Supra, Lancer Evolution and others of this nature). This is a must for every turbocharged
                engine, as too much horsepower will kill the car's torque, and too much torque will hold it back from
                its true potential.</p>

            <img src="https://cdn.nightriderz.world/images/website/moonset/parts/perf/R1_ENGINE_G.png">
            <img src="https://cdn.nightriderz.world/images/website/moonset/parts/perf/R2_ENGINE_G.png">
            <img src="https://cdn.nightriderz.world/images/website/moonset/parts/perf/R3_ENGINE_G.png">
            <img src="https://cdn.nightriderz.world/images/website/moonset/parts/perf/R4_ENGINE_G.png">
            <img src="https://cdn.nightriderz.world/images/website/moonset/parts/perf/R5_ENGINE_G.png">

            <p>Often noted as being the "bugged" engine, the collection of engines from
                OmniaRacegruppe/Norset/URSAMotorsport/Speedsciens and DynamoAftermarketKits are made for those high
                revving automobiles. This engine is tuned to provide high end hp with pretty bad low end torque,
                requiring an induction 9 times out of 10 to help out. Do note that this should be used only on cars with
                the peak near the high range (Nissan Skyline GT-R), rather than cars that still have quite a ways to go
                between their peak power and redline (Mazda RX-7).</p>


        </center>
        <br>

        <h3>Forced inductions</h3>
        <v-divider></v-divider><br>
        <p>On some cars, it's optional. On others, it's a matter of winning (and losing) a race. In this part, we talk
            about forced inductions and where they deliver the majority of torque.</p>
        <br>
        <h4>Superchargers</h4>
        <v-divider></v-divider><br>
        <center>
            <img src="https://cdn.nightriderz.world/images/website/moonset/parts/perf/R1_FORCEDIND_SPEC.png">
            <img src="https://cdn.nightriderz.world/images/website/moonset/parts/perf/R2_FORCEDIND_SPEC.png">
            <img src="https://cdn.nightriderz.world/images/website/moonset/parts/perf/R3_FORCEDIND_SPEC.png">
            <img src="https://cdn.nightriderz.world/images/website/moonset/parts/perf/R4_FORCEDIND_SPEC.png">

            <p>Somewhere in your life you've heard and/or read about a big blower in a muscle car. Such is the case
                here, as the Gold Induction delivers raw power widespread across the RPM ranges in engines that are more
                HP-oreinted (Exige Cup 260, Shelby GT500 SS to name a few). It improves both acceleration and top speed,
                although it has a bit of a bias toward the horsepower side of things. It takes a while to make power,
                but the boost is massive once it does.</p>

            <img src="https://cdn.nightriderz.world/images/website/moonset/parts/perf/R1_FORCEDIND_B.png">
            <img src="https://cdn.nightriderz.world/images/website/moonset/parts/perf/R2_FORCEDIND_B.png">
            <img src="https://cdn.nightriderz.world/images/website/moonset/parts/perf/R3_FORCEDIND_B.png">
            <img src="https://cdn.nightriderz.world/images/website/moonset/parts/perf/R4_FORCEDIND_B.png">
            <img src="https://cdn.nightriderz.world/images/website/moonset/parts/perf/R5_FORCEDIND_B.png">

            <p>The "other" favorite among the community is the Dawndraft/Kestrel/Velocicom Induction. This is more or
                less the inverse of the Gold, tuned for better response at low-RPM. What this means is that it has
                smaller components and as a result is capable of making more power quicker than the Golf. The drawback
                with this induction, however, is that you get less power up top. This is suitable for torquier engines,
                namely those of classic muscle cars.</p>

        </center>
        <br>
        <h4>Turbochargers</h4>
        <v-divider></v-divider><br>
        <center>
            <img src="https://cdn.nightriderz.world/images/website/moonset/parts/perf/R1_FORCEDIND_R.png">
            <img src="https://cdn.nightriderz.world/images/website/moonset/parts/perf/R2_FORCEDIND_R.png">
            <img src="https://cdn.nightriderz.world/images/website/moonset/parts/perf/R3_FORCEDIND_R.png">
            <img src="https://cdn.nightriderz.world/images/website/moonset/parts/perf/R4_FORCEDIND_R.png">
            <img src="https://cdn.nightriderz.world/images/website/moonset/parts/perf/R5_FORCEDIND_R.png">

            <p>As weak as this turbo may seem, the Nova-T/Richter/Ventura Induction is, in actuality, not made to be
                strong. It is a turbo system that actually spools quickly up to speed, compromising top end boost in the
                process. This turbo is optional to install, but if you were to install one, it is best applicalbe to
                high-RPM cars, the Murcielago LP640 and the Porsche 911 GT3 RS being prime examples.</p>

            <img src="https://cdn.nightriderz.world/images/website/moonset/parts/perf/R1_FORCEDIND_G.png">
            <img src="https://cdn.nightriderz.world/images/website/moonset/parts/perf/R2_FORCEDIND_G.png">
            <img src="https://cdn.nightriderz.world/images/website/moonset/parts/perf/R3_FORCEDIND_G.png">
            <img src="https://cdn.nightriderz.world/images/website/moonset/parts/perf/R4_FORCEDIND_G.png">
            <img src="https://cdn.nightriderz.world/images/website/moonset/parts/perf/R5_FORCEDIND_G.png">

            <p>Screaming like a banshee, the Green turbo delivers sheer power at high-RPM and is thus the perfect brand
                for many turbocharged and/or naturally-aspirated cars like the RX-7 or the Corvette Z06 (yes, the Z06
                carries a turbo, not a supercharger). It takes much longer to spool up than the Red, but it does deliver
                more boost once it reaches the top. Usually, the more peaky the engine, the more Boost it would need,
                but DO NOT apply this to a Green Engine as the car would die off in the lower range.</p>


        </center>
        <br>

        <h3>Transmissions</h3>
        <v-divider></v-divider><br>
        <p>Remember the Game Guide stating that the higher price, the better the transmission? It doesn't always work.
            Here is where we explain what every transmission does, as well as what it should be coupled with and why.
        </p>
        <br>
        <center>
            <img src="https://cdn.nightriderz.world/images/website/moonset/parts/perf/R1_TRANS_GOLD.png">
            <img src="https://cdn.nightriderz.world/images/website/moonset/parts/perf/R2_TRANS_GOLD.png">
            <img src="https://cdn.nightriderz.world/images/website/moonset/parts/perf/R3_TRANS_GOLD.png">
            <img src="https://cdn.nightriderz.world/images/website/moonset/parts/perf/R4_TRANS_GOLD.png">
            <img src="https://cdn.nightriderz.world/images/website/moonset/parts/perf/R5_TRANS_BLACK.png">


            <p>This transmission contains a balanced set of gears and is suitable for anything equipped with either a
                Gold engine or a Gold Transmission. It is also slightly heavier so the handling may or may not (this is
                based on the layout of the car) take a hit.</p>


            <img src="https://cdn.nightriderz.world/images/website/moonset/parts/perf/R1_TRANS_B.png">
            <img src="https://cdn.nightriderz.world/images/website/moonset/parts/perf/R2_TRANS_B.png">
            <img src="https://cdn.nightriderz.world/images/website/moonset/parts/perf/R3_TRANS_B.png">
            <img src="https://cdn.nightriderz.world/images/website/moonset/parts/perf/R4_TRANS_B.png">
            <img src="https://cdn.nightriderz.world/images/website/moonset/parts/perf/R5_TRANS_B.png">

            <p>Need a drivetrain to keep your high-horsepower machine in top form? Look no further than the blue
                transmission. Featuring a higher final drive ratio and shorter gears, this transmission is made with the
                pure intent of making your high-RPM racer a top contender.</p>

            <img src="https://cdn.nightriderz.world/images/website/moonset/parts/perf/R1_TRANS_R.png">
            <img src="https://cdn.nightriderz.world/images/website/moonset/parts/perf/R2_TRANS_R.png">
            <img src="https://cdn.nightriderz.world/images/website/moonset/parts/perf/R3_TRANS_R.png">
            <img src="https://cdn.nightriderz.world/images/website/moonset/parts/perf/R4_TRANS_R.png">
            <img src="https://cdn.nightriderz.world/images/website/moonset/parts/perf/R5_TRANS_R.png">

            <p>As per usual, the red drivetrain is the lightweight version of its rivals, AmerikonSS/Gromlen/Medion.
                Like the yellow, the red features balanced gears to manage the moderate power increases from the other
                power parts; however, in contrast, the red also features extra handling via the increase of rigidity,
                settling down cars that are known to be tail-happy a la Mitsubishi Lancer Evolution IX.</p>

            <img src="https://cdn.nightriderz.world/images/website/moonset/parts/perf/R1_TRANS_G.png">
            <img src="https://cdn.nightriderz.world/images/website/moonset/parts/perf/R2_TRANS_G.png">
            <img src="https://cdn.nightriderz.world/images/website/moonset/parts/perf/R3_TRANS_G.png">
            <img src="https://cdn.nightriderz.world/images/website/moonset/parts/perf/R4_TRANS_G.png">
            <img src="https://cdn.nightriderz.world/images/website/moonset/parts/perf/R5_TRANS_G.png">

            <p>In order to take advantage of a car's great low-RPM torque, longer gears must be used, and this is where
                this green transmission comes in. This drivetrain features extra-long gears, longer than those found in
                both the Medion and Ventura setups. Best used on an aforementioned low-RPM car, such as the Nissan GT-R
                and the Volkswagen Scirocco, this transmission allows the car to use its powerful low revs to its
                advantage in the straights, as well as a huge improvement in top speed.</p>
        </center>
        <br>
        <h3>Suspensions</h3>
        <v-divider></v-divider><br>
        <p>One crucial point in any car in any race is how it handles (which is a challenge for many of the cars that
            handle like crap when they shouldn't), and getting the right suspension for the car makes all the difference
            from spinning out or slamming the wall from taking a turn too wide. It's a delicate balance that needs to be
            finely tuned.</p>
        <br>
        <center>
            <img src="https://cdn.nightriderz.world/images/website/moonset/parts/perf/R1_SUSP_SPEC.png">
            <img src="https://cdn.nightriderz.world/images/website/moonset/parts/perf/R2_SUSP_SPEC.png">
            <img src="https://cdn.nightriderz.world/images/website/moonset/parts/perf/R3_SUSP_SPEC.png">
            <img src="https://cdn.nightriderz.world/images/website/moonset/parts/perf/R4_SUSP_SPEC.png">

            <p>This suspension is set to a soft setting. The resulting behavior is better traction and stability off
                road and on highways but understeer in turns and cause the car to jump higher on any smooth hills. The
                car will feel more stable going over tiny bumps such as apex's and sidewalks and will loose less speed
                from collisions. Cars that would benefit from this suspension are muscle cars, which, in World, are well
                known for having either no stability, no traction, or having neither.</p>

            <img src="https://cdn.nightriderz.world/images/website/moonset/parts/perf/R1_SUSP_B.png">
            <img src="https://cdn.nightriderz.world/images/website/moonset/parts/perf/R2_SUSP_B.png">
            <img src="https://cdn.nightriderz.world/images/website/moonset/parts/perf/R3_SUSP_B.png">
            <img src="https://cdn.nightriderz.world/images/website/moonset/parts/perf/R4_SUSP_B.png">
            <img src="https://cdn.nightriderz.world/images/website/moonset/parts/perf/R5_SUSP_B.png">

            <p>Designed for drag and rally cars, this suspension semi-stiffens the car, reducing body roll and helping
                the car accelerate, but in turn causing turning oversteer from. Cars that would benefit from this are
                front-heavy all-wheel-drive cars which are known to, according to the physics of World, have a lot of
                understeer and at times even have trouble accelerating.</p>

            <img src="https://cdn.nightriderz.world/images/website/moonset/parts/perf/R1_SUSP_R.png">
            <img src="https://cdn.nightriderz.world/images/website/moonset/parts/perf/R2_SUSP_R.png">
            <img src="https://cdn.nightriderz.world/images/website/moonset/parts/perf/R3_SUSP_R.png">
            <img src="https://cdn.nightriderz.world/images/website/moonset/parts/perf/R4_SUSP_R.png">
            <img src="https://cdn.nightriderz.world/images/website/moonset/parts/perf/R5_SUSP_R.png">

            <p>Possibly the best suspension in the bracket, this stiffens up the car, thereby provoking more oversteer
                and delivering a crisper turn-in. Works well with many cars, whether they be drifters or have a hard
                time cornering. Note: This should be used only with rear-wheel drive as all-wheel drive cars don't
                respond well to this as they would the blue suspension.</p>

            <img src="https://cdn.nightriderz.world/images/website/moonset/parts/perf/R1_SUSP_G.png">
            <img src="https://cdn.nightriderz.world/images/website/moonset/parts/perf/R2_SUSP_G.png">
            <img src="https://cdn.nightriderz.world/images/website/moonset/parts/perf/R3_SUSP_G.png">
            <img src="https://cdn.nightriderz.world/images/website/moonset/parts/perf/R4_SUSP_G.png">
            <img src="https://cdn.nightriderz.world/images/website/moonset/parts/perf/R5_SUSP_G.png">

            <p>As with the Yellow suspension, the Green suspension has a semi-soft tuned setup, though in contrast, it
                provides a great deal of stability as the car gains speed and is therefore an exceptional choice for a
                highway-tuned car. However, as Omnia/Norset/URSA fails in the handling department, be prepared to face
                major understeer.</p>
        </center><br>

        <h3>Brakes</h3>
        <v-divider></v-divider><br>
        <p>Yes, your car can achieve high speeds but eventually you will need to stop because a 60-0 time is just as
            important as a 0-60 time. You need a great set of brakes. Which type is right for your car, well... that's
            up to what you're looking for.</p>
        <br>
        <center>
            <img src="https://cdn.nightriderz.world/images/website/moonset/parts/perf/R1_BRAKE_SPEC.png">
            <img src="https://cdn.nightriderz.world/images/website/moonset/parts/perf/R2_BRAKE_SPEC.png">
            <img src="https://cdn.nightriderz.world/images/website/moonset/parts/perf/R3_BRAKE_SPEC.png">
            <img src="https://cdn.nightriderz.world/images/website/moonset/parts/perf/R4_BRAKE_SPEC.png">

            <p>A set of front and rear brakes tuned for a slight front bias. If no car you own seems to fit the Ventura
                brakes, you can go for these.</p>

            <img src="https://cdn.nightriderz.world/images/website/moonset/parts/perf/R1_BRAKE_B.png">
            <img src="https://cdn.nightriderz.world/images/website/moonset/parts/perf/R2_BRAKE_B.png">
            <img src="https://cdn.nightriderz.world/images/website/moonset/parts/perf/R3_BRAKE_B.png">
            <img src="https://cdn.nightriderz.world/images/website/moonset/parts/perf/R4_BRAKE_B.png">
            <img src="https://cdn.nightriderz.world/images/website/moonset/parts/perf/R5_BRAKE_B.png">

            <p>A set of unbiased front and rear brakes with the purpose of quicker deceleration. These brakes do
                increase the risk of understeer due to the fact that they are very powerful and will lock your tires up,
                requiring earlier braking.</p>

            <img src="https://cdn.nightriderz.world/images/website/moonset/parts/perf/R1_BRAKE_R.png">
            <img src="https://cdn.nightriderz.world/images/website/moonset/parts/perf/R2_BRAKE_R.png">
            <img src="https://cdn.nightriderz.world/images/website/moonset/parts/perf/R3_BRAKE_R.png">
            <img src="https://cdn.nightriderz.world/images/website/moonset/parts/perf/R4_BRAKE_R.png">
            <img src="https://cdn.nightriderz.world/images/website/moonset/parts/perf/R5_BRAKE_R.png">

            <p>A set of front and rear brakes tuned to have the rear lock up slightly before the front. Causes braking
                oversteer and makes the car's rear more likely to slide. Best used on rear-wheel-drive cars with drift
                tunings as well as known track cars like the Elise.</p>

            <img src="https://cdn.nightriderz.world/images/website/moonset/parts/perf/R1_BRAKE_G.png">
            <img src="https://cdn.nightriderz.world/images/website/moonset/parts/perf/R2_BRAKE_G.png">
            <img src="https://cdn.nightriderz.world/images/website/moonset/parts/perf/R3_BRAKE_G.png">
            <img src="https://cdn.nightriderz.world/images/website/moonset/parts/perf/R4_BRAKE_G.png">
            <img src="https://cdn.nightriderz.world/images/website/moonset/parts/perf/R5_BRAKE_G.png">

            <p>A set of unbiased front and rear brakes tuned kinda loosely. This was done to provide stable braking at
                high speeds(avoid locking up). The downfall is that the brakes have to be applied a bit earlier coming
                into a turn. These brakes should be paired up with Medion tires as the grip should slow you down.</p>

        </center><br>

        <h3>Tires</h3>
        <v-divider></v-divider><br>
        <p>You can ask any professional racer, whether it be in FIA, FD, Le Mans, etc. if the type of tires makes a
            difference in the behavior of a car's handling, and you'll get a resounding "yes" from each one. Same
            applies in World and the tires from the four brands in the game. Pick wisely, the wrong set of rubber may
            bork your ride considerably.</p>

        <br>
        <center>
            <img src="https://cdn.nightriderz.world/images/website/moonset/parts/perf/R1_TIRE_SPEC.png">
            <img src="https://cdn.nightriderz.world/images/website/moonset/parts/perf/R2_TIRE_SPEC.png">
            <img src="https://cdn.nightriderz.world/images/website/moonset/parts/perf/R3_TIRE_SPEC.png">
            <img src="https://cdn.nightriderz.world/images/website/moonset/parts/perf/R4_TIRE_SPEC.png">


            <p>These tires improve the handling of a car all across the board by boosting grip by a considerable margin.
                By doing this, your car gets improved traction and improved stability and is recommended for any car
                that lacks in both fields. You do get some mild understeer, but it doesn't make much of a difference to
                be considered car-breaking.</p>

            <img src="https://cdn.nightriderz.world/images/website/moonset/parts/perf/R1_TIRE_B.png">
            <img src="https://cdn.nightriderz.world/images/website/moonset/parts/perf/R2_TIRE_B.png">
            <img src="https://cdn.nightriderz.world/images/website/moonset/parts/perf/R3_TIRE_B.png">
            <img src="https://cdn.nightriderz.world/images/website/moonset/parts/perf/R4_TIRE_B.png">
            <img src="https://cdn.nightriderz.world/images/website/moonset/parts/perf/R5_TIRE_B.png">

            <p>These are a set of tires that boost acceleration considerably by increasing traction. Their tendency to
                cause moderate oversteer, however, should be noted (although at times, if you know how, you can force
                power oversteer). ~ Credit to legor17 for the insight on power oversteer. Anecdotal information.</p>

            <img src="https://cdn.nightriderz.world/images/website/moonset/parts/perf/R1_TIRE_R.png">
            <img src="https://cdn.nightriderz.world/images/website/moonset/parts/perf/R2_TIRE_R.png">
            <img src="https://cdn.nightriderz.world/images/website/moonset/parts/perf/R3_TIRE_R.png">
            <img src="https://cdn.nightriderz.world/images/website/moonset/parts/perf/R4_TIRE_R.png">
            <img src="https://cdn.nightriderz.world/images/website/moonset/parts/perf/R5_TIRE_R.png">

            <p>Designed for drifting, Nova-T/Richter/Ventura tires are perfect for known driftable cars (i.e. RX-7,
                240SX, 350Z, 370Z, Silvia S15, AE116, etc.) as they help the car slide out in turns, basically the same
                function brakes with a rear bias have. These are also useful for track cars; however, as with the
                suspension of the same brand, this should be left to rear-wheel-drive vehicles.</p>

            <img src="https://cdn.nightriderz.world/images/website/moonset/parts/perf/R1_TIRE_G.png">
            <img src="https://cdn.nightriderz.world/images/website/moonset/parts/perf/R2_TIRE_G.png">
            <img src="https://cdn.nightriderz.world/images/website/moonset/parts/perf/R3_TIRE_G.png">
            <img src="https://cdn.nightriderz.world/images/website/moonset/parts/perf/R4_TIRE_G.png">
            <img src="https://cdn.nightriderz.world/images/website/moonset/parts/perf/R5_TIRE_G.png">
            <p>These are a set of tires that benefit cars that rule on the highways. While the stat increases don't tell
                you, they increase stability at high speeds, so in a way, these tires improve top speed. As with the
                blue tires, however, this causes turning understeer and, as a result, require precision turning from the
                driver.</p>
        </center><br>

        <h3>Final Notes</h3>
        <v-divider></v-divider><br>
        <p>1. As countless people have said before, different drivers have different preferences, thus different setups
            will apply. You do not have to follow guides' suggestions word by word, just use this guide as a base for
            what parts do and tune them for a setup that fits your driving style.</p>
        <p>2. The top speed is not affected by the Top Speed stat itself, but by the overall stat. With that, it is not
            advisable to go for simply the best overall, as rarely does one ever reach top speed on any race except for
            any highway race.</p>
        <p>3. Remember, this guide is like science, it can always be proven wrong.</p>
        <p>4. If you have trouble figuring out which part to use for a certain car, you can always install a Gold part
            until you do find said part.</p>
        <p>5. Enjoy!</p><br>
        <a href="https://docs.google.com/spreadsheets/d/1Um13J-5RsuCeh1NDo01izdHdDbuYY8qM44THnqUEUJw/edit?usp=sharing"
            target="_blank">Performance Guide (Google Docs)</a>
        <br><br>
        <h4>Credit to founders Kawashi, LamboGTR for this parts guide.</h4>
    </div>
</template>

<style scoped>
img {
        width: 70px;
        height: 70px;
        margin: 10px;
    }
</style>