<script setup>
import { ref, getCurrentInstance } from 'vue'

const { proxy } = getCurrentInstance()
const code = ref('')
const useds = ref([])
const error = ref('')

getUsed()

async function getUsed() {
    const res = await proxy.$api({ serviceName: "account", methodName: "codeUsed" })
    useds.value = res
}

function save() {
    proxy.$http.post("", JSON.stringify({ serviceName: "account", methodName: "redeemcode", parameters: [code.value] })
        ).then(response => {

            if(response.data.state == "error") {
                error.value = response.data.message
            } else {
                error.value = ""
                code.value = ""
                getUsed()
            }
           

        }).catch(function (error) {
        })
}
</script>

<template>
    <v-alert class="error" v-if="error" :text="error" type="error"></v-alert>
    <v-form>
        <v-container>
            <v-row>
                <div class="flexform">
                    <v-text-field prepend-icon="mdi-wallet-giftcard" v-model="code" label="TYPE YOUR CODE HERE" variant="outlined"></v-text-field>

                    <v-btn class="save" variant="tonal" @click="save">USE</v-btn>
                </div>
            </v-row>
        </v-container>
    </v-form>
    <hr>

    <div class="codes">
        <v-alert class="used" v-for="used in useds" variant="tonal" :key="used.code">
            <b>{{ used.code }}</b><br>{{ used.name }}
        </v-alert>
    </div>
</template>

<style scoped>
    .flexform {
        display: flex;
        flex-direction: column;
        flex: 1;
    }
    .save {
        margin: 0 0 20px 0;
    }

    .codes {
        height: 189px;
        overflow-y: scroll;
    }

    .used {
        margin-right: 6px!important;
    }

    hr {
        margin-bottom: 10px;
    }

    .error {
        margin-bottom: 25px!important;
    }
</style>