<script setup>
import { toRefs, ref } from 'vue'
import { useRoute } from 'vue-router'
import { ServerStore } from '../pinia/Server.js'
const route = useRoute()
const Server = ServerStore()
const props = defineProps({
  data: Object
})
const {data} = toRefs(props)

function getBackground() {
    if(data.value.thumb_version == 0)
        return "https://cdn.nightriderz.world/images/website/district-thumb/" + data.value.districtid + ".jpg"
    else
        return 'https://cdn.nightriderz.world/images/website/crew/' + data.value.crewid + '-thumb-v' + data.value.thumb_version + '.png'
}
</script>

<template>
    <RouterLink :to="'/crew/headquarters/' + data.crewid" tag="div" class="crewcard" style="background-color: #11141c;" :style="{background: data.color}">
            <div class="crewcover" :style="'background-image: url(' + getBackground() + ');background-size:350px auto;'"></div>
            <v-spacer></v-spacer>
            <div :style="{background: data.color}">
                <v-spacer></v-spacer>
                <v-card style="margin-bottom: 6px;margin-left: 7px;margin-right: 7px;padding-left: 6px;box-shadow: none;margin-bottom: 7px;">
                    <div style="text-transform: uppercase;"><b>[{{data.tag}}] {{data.name}}</b></div>
                    <div class="district">{{data.city}} - {{data.district}}</div>
                    <div class="level">LEVEL {{data.level}} - {{number_format(data.nb_member)}} MEMBERS - {{number_format(data.nb_points)}} POINTS</div>
                </v-card>
            </div>
    </RouterLink>
</template>

<style scoped>
    .crewcover {
        width: 350px; /* Largeur du conteneur */
        height: 180px; /* Hauteur du conteneur */
        margin-left: auto;
        margin-right: auto;
        margin-top: 7px;
        border-radius:4px;
        background-repeat: repeat;
    }

    .crewcard {
        border-radius: 6px;
        overflow: hidden;
        width: 364px;
        flex-direction: column;
        display: flex;
        margin: 20px;
        height: 264px;
    }
    .textcard {
        height: 76px;
        padding-left:15px;
        padding-top:8px;
        text-shadow: 0px 0px 6px rgb(0 0 0);
    }

    .district,
    .level {
        font-size: 13px;
    }
</style>