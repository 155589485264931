<script setup>
import { ref, getCurrentInstance} from 'vue'
import Login from "./Login.vue"
import menus from "../configs/menu.json"
import { useRoute } from 'vue-router'
import Download from '../pages/Download.vue'
import Account from '../pages/Account.vue'
import Persona from '../pages/Persona.vue'
import { DriverStore } from '../pinia/Driver.js'
import Premium from '../pages/Premium.vue'

const Driver = DriverStore()

const route = useRoute()
const showModal = ref(false)

function subIsActive(input) {
    const paths = Array.isArray(input) ? input : [input]

    return paths.some(path => {
        return route.path.indexOf(path) === 0
    })
}
</script>

<template>

<div v-for="menu in menus" :key="menu.name">
    <Download v-if="menu.icon == 'file_download'"/>
    <Premium v-else-if="menu.icon == 'star'"/>
    <routerLink v-else :to="menu.path" id="menu" :class="{'router-link-active': subIsActive(menu.active)}">
        <v-icon :icon="'mdi-' + menu.icon"></v-icon>
        <v-tooltip activator="parent" location="right">{{menu.name}}</v-tooltip>
    </routerLink>

</div>

<div class="spacer"></div>

<Persona v-if="Driver.logged" />
<Account v-if="Driver.logged" />
<Login />
</template>

<style scoped>
#menu {
    font-weight: bold;
    color: white;
    text-decoration: none;
    border-radius: 6px;
    margin-bottom: 10px;
    height: 40px;
    width: 40px;
    background: transparent;
    transition: all 0.15s ease-in-out;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    cursor: pointer;
}

#menu i {
    margin: 0 7px;
}

.router-link-active {
    font-weight: bold;
    border-left: 2px solid white;
    background: var(--tertiary-color) !important;
    color: white;
    vertical-align: middle;
}

#menu:hover {
    font-weight: bold;
    background: var(--quaternary-color);
    color: white;
    vertical-align: middle;
}


</style><style scoped>
::v-deep(.modal-container) {
    display: flex;
    justify-content: center;
    align-items: center;
}

::v-deep(.modal-content) {
    position: relative;
    display: flex;
    flex-direction: column;
    max-height: 90%;
    margin: 0 1rem;
    padding: 1rem;
    border-radius: 0.25rem;
    background: var(--primary-color);
}

.modal__title {
    display: flex;
    justify-content: center;
    margin: 20px 0;
    flex-direction: column;
    font-size: 14px;
    flex-wrap: wrap;
    max-width: 500px;
}

.title {
    font-size: 25px;
}

.modal__content {
    flex-grow: 1;
    overflow-y: auto;
    margin: 10px;
}

.modal__action {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    padding: 1rem 0 0;
}

.modal__close {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
}

::v-deep(.modal-content) {
    border-color: #2d3748;
    background-color: #1a202c;
}
</style>
