<script setup>
import {
    getCurrentInstance,
    ref
} from 'vue'

const {
    proxy
} = getCurrentInstance()
const rotations = ref([])

getRotation()

async function getRotation() {
    const res = await proxy.$api({
        "serviceName": "leaderboard",
        "methodName": "rotation"
    })
    rotations.value = res
}

function getCurrentWeek() {
    var currentDate = new Date();
    var startDate = new Date(currentDate.getFullYear(), 0, 1);
    var days = Math.floor((currentDate - startDate) / (24 * 60 * 60 * 1000));
         
    return Math.ceil(days / 7);
}
</script>

<template>
<v-app-bar :elevation="2">
    <v-icon icon="mdi-go-kart-track"></v-icon>
    <v-app-bar-title>Rotations</v-app-bar-title>
    <v-spacer></v-spacer>
</v-app-bar>

<div class="grid">
    <v-card v-for="rotation in rotations" :style="'Week ' + getCurrentWeek() == rotation.rotation.name ? 'background: var(--tertiary-color)' : ''">
        <v-card-title>
            {{ rotation.rotation.name }}
        </v-card-title>
        <v-card-text>
            <div class="row" v-for="race in rotation.races">
                <img :src="'https://cdn.nightriderz.world/images/website/moonset/modes/' + race.eventModeId + '.png'" width="20">
                {{ race.name }}
                <v-spacer></v-spacer>
                <img :src="'https://cdn.nightriderz.world/images/website/moonset/classes/' + carClasshHash(race.carClassHash) + '.png'" height="20">
            </div>
        </v-card-text>
    </v-card>
</div>
</template>

<style scoped>
.grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 20px;
}

.row {
    display: flex;
    align-items: center;
    height: 25px;
}

.row img {
    margin-right: 10px;
}

.v-card {
    background: var(--primary-color);
    margin: 10px;
}
</style>
