<script setup>

    import { ref, toRefs } from 'vue'

    const props = defineProps({
    data: Object
    })
    const {data} = toRefs(props)

    const emit = defineEmits(['dialogData'])
    const dialogData = () => emit('dialogData', data.value.id)

</script>

<template>
  <v-card
    class="card"
    width="310"
    @click="dialogData"
  >

  <v-btn
      class="carsettings"
      variant="outlined"
      icon
    >
      <v-icon>mdi-car-cog</v-icon>
    </v-btn>

    <v-img
      :src="'https://cdn.nightriderz.world/images/website/moonset/cars/' + data.name + '.png'"
      height="135" style="margin-top: 6px;margin-left: 6px;margin-right: 6px;border-radius: 4px;"
    ></v-img>


    <v-card-subtitle style="background: #1f2430;margin-left: 6px;margin-right: 6px;margin-top: 6px;border-radius:4px">
      {{ data.manufactor }} {{ data.model }}
    </v-card-subtitle>

  </v-card>
</template>

<style scoped>

.carsettings {
  opacity: 0;
  position: absolute;
  width: 100%!important;
  height: 136px!important;
  background: #00000020!important;
  border-radius: initial;
  z-index: 1;
  transition: 0.2s;
}

.carsettings:hover {
  opacity: 1;
  position: absolute;
  width: 100%;
  height: 136px;
  background: #00000020!important;
  border-radius: initial;
  z-index: 1;
}

.v-card-subtitle {
    background: var(--primary-color);
    opacity: 1;
    display: flex;
    justify-content: center;
    padding: 10px 0;
    font-size: 12px;
    font-weight: bold;
    margin-bottom: 6px;
}
    .card {
        background: var(--primary-color);
        margin: 25px;
    }

    .v-card-actions {
        margin: 10px 10px 0 10px;
    }
</style>