<script setup>
import {
    toRefs
} from 'vue'

const props = defineProps({
    activity: Object
})
const {
    activity
} = toRefs(props)
</script>

<template>
<!--
    <v-timeline class="timeline" side="end" align="start">
        <v-timeline-item
        dot-color="white"
        icon="mdi-car"
        size="x-small"
        v-for="(act, index) in activity"
        >
        <v-card>
        <strong class="me-4"><Timeago :datetime="act.date" /></strong>
                <div><b>{{ act.pseudo}} </b> {{ act.value}} <b>{{ act.racename }}</b></div>
                <v-spacer></v-spacer>
                <div>{{ act.points }}</div>
        </v-card>
        </v-timeline-item>

    </v-timeline>-->

<div v-for="(act, index) in activity" size="small" class="activity">
    <v-alert>
        <div class="body">
            <div class="time" :title="act.date"><strong class="me-4">
                    <timeago :datetime="act.date" /></strong></div>
            <div><b>{{ act.pseudo}} </b> {{ act.value}} <b>{{ act.racename }}</b></div>
            <v-spacer></v-spacer>
            <div>{{ act.points }}</div>
        </div>
    </v-alert>
</div>
</template>

<style scoped>
.v-timeline::v-deep(.v-timeline-divider__before),
.v-timeline::v-deep(.v-timeline-divider__after) {
    background: white;
}

.v-timeline {
    justify-content: left;
}

.time {
    width: 150px;
}

.v-alert {
    background: var(--primary-color);
    color: white;
    padding: 20px;
}

.body {
    display: flex;
}

.activity {
    margin: 8px !important;
}
</style><style>
.v-alert__content {
    width: 100% !important;
}
</style>
