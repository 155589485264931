<script setup>
import {
    getCurrentInstance,
    ref
} from 'vue'
import {
    useRoute,
    useRouter
} from 'vue-router'

const {
    proxy
} = getCurrentInstance()
const route = useRoute()
const Password1 = ref('')
const Password2 = ref('')
const error = ref('')

async function save() {
    if(Password1.value == Password2.value) {
        const res = await proxy.$api({"serviceName":"session","methodName":"update_password","parameters": [route.params.token, Password1.value] })
        error.value = res
    } else {
        error.value = "Passwords not match"
    }

}
</script>

<template>
<div class="p404">
    <v-card width="500px" variant="tonal" style="padding: 20px;">

        <v-alert class="error" :text="error" v-if="error" type="info"></v-alert>

        <v-text-field prepend-icon="mdi-lock" type="password" v-model="Password1" name="new-password" label="Password" variant="outlined"></v-text-field>

        <v-text-field prepend-icon="mdi-lock" type="password" v-model="Password2" name="new-password" label="Password" variant="outlined"></v-text-field>

        <div class="d-flex">
            <v-spacer></v-spacer>
            <v-btn variant="tonal" @click="save">Change</v-btn>
        </div>

    </v-card>

</div>
</template>

<style scoped>
    .p404 {
        display: flex;
        justify-content: center;
        padding: 40px;
    }
</style>
