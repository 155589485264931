<script setup>

</script>

<template>

      <v-card class="challenge_card" style="width:280px;">
        <Skeletor width="100%" height="500px"/> 
      </v-card>

</template>
<style scoped>
    .challenge_card {
        background: var(--primary-color);
        margin: 25px;
    }

    .custom {
        margin: 0;
        border-radius: 0px;
        padding: 0;
        font-size: 14px;
        font-weight: 500;
        text-transform: uppercase;
        line-height: 36px;
        background: transparent;
      }



    .challenge_card {
        min-height: 20px;
        margin-bottom: 20px;
        box-shadow: 0 3px 4px 0 rgb(0 0 0 / 14%), 0 3px 3px -2px rgb(0 0 0 / 20%), 0 1px 8px 0 rgb(0 0 0 / 12%);
        overflow: hidden;
        background: #0b131e;
        color: white;
        display: flex;
        flex-direction: column;
        height: initial;
      }

      .conditions .text {
        display: block;
        width: 180px;
        margin: auto;
      }
      .conditions div {
        display: flex;
      }
      .conditions {
        padding: 10px 5px;
        position: relative;
        flex-grow: 1;
      }

</style>