<script setup>
import { ref } from 'vue'
const data = ref([])

oui()

function oui() {
    data.value.push({'websiteVersion': document.querySelectorAll('meta')[1].getAttribute('version')})
    data.value.push({'userAgent': navigator.userAgent})
    data.value.push({'lang': navigator.language})
}

</script>

<template>

<div>
    <br>
    {{ JSON.stringify(data) }}
</div>


</template>

<style scoped>
    div {
        display: flex;
        justify-content: center;
    }
</style>