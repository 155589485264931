<script setup>
import {
    ref,
    getCurrentInstance
} from 'vue'
import DriverCard from '../components/driverCard.vue'
import KonopabankForm from '../components/konopabankForm.vue'
import PersonaForm from '../components/personaForm.vue'
import RedeemcodeForm from '../components/redeemcodeForm.vue'
import prestigeForm from '../components/prestigeForm.vue'
import {
    DriverStore
} from '../pinia/Driver.js'

const {
    proxy
} = getCurrentInstance()

const Driver = DriverStore()

var dialog = ref(false)
var dialogswitch = ref(false)
var model = ref()
const modelswitch = ref()
const error = ref("")

const avatar = ref("")
const name = ref("")

async function save() {

    error.value = false

    if (!!avatar.value && !!name.value) {
        const res = await proxy.$api({
            serviceName: "account",
            methodName: "create_persona",
            parameters: [name.value, avatar.value]
        })

        if (res == "error") {
            error.value = true
        } else {
            Driver.setEasharpptr_p(res)
            GetUserInfo()
            dialogswitch.value = false
            modelswitch.value = 1
            name.value = ""
            avatar.value = ""
        }
    }
}

async function GetUserInfo() {
    const res = await proxy.$api({
        serviceName: "session",
        methodName: "GetUserInfo"
    })

    Driver.setPersona(res.persona)
    Driver.setUser(res.user)
    Driver.setCrew(res.crew)
    Driver.setCurrentCrew(res.crew.current)
    Driver.setOthers(res.others)
    Driver.setLogged(true)
}
</script>

<template>
<v-btn id="menu" icon="mdi">
    <v-avatar @click="dialog = true" :image="'https://cdn.nightriderz.world/images/website/icon-persona/' + Driver.persona.iconIndex + '.jpg'"></v-avatar>
</v-btn>

<v-btn id="menu" @click="dialogswitch = true" icon="mdi-account-switch" title="Switch Driver">
</v-btn>

<v-dialog v-model="dialogswitch" :width="520" scrollable>

    <v-toolbar dark color="primary">
        <v-toolbar-title>My Drivers</v-toolbar-title>
        <v-spacer></v-spacer>
        <template v-slot:extension>
            <v-tabs v-model="modelswitch" centered>
                <v-tab value="1">
                    SWITCH DRIVER
                </v-tab>
                <v-tab value="2" v-if="Driver.user.premium == '1'">
                    CREATE DRIVER
                </v-tab>
            </v-tabs>
        </template>

    </v-toolbar>

    <v-card>
        <v-card-text>

            <v-window v-model="modelswitch">
                <v-window-item value="1">
                    <v-card>
                        <v-card-text>
                            <DriverCard :data="Driver.persona" :changebis="true" />
                            <DriverCard v-for="driver in Driver.others" :data="driver" :change="true" @closeDialog="dialogswitch = false" />
                        </v-card-text>
                    </v-card>
                </v-window-item>
                <v-window-item value="2">
                    <v-card>
                        <v-card-text>

                            <span v-if="Driver.others.length + 1 == 6">
                                <v-alert color="info" icon="mdi-information">You have reached the maximum number of drivers.</v-alert>
                                <br>
                            </span>

                            <v-text-field :error-messages="error ? 'This name is unavailable.': ''" v-model="name" label="Driver Name" variant="outlined"></v-text-field>

                            <div class="grid">
                                <span :class="'item' + i" v-for="i in 72" style="position: relative">

                                    <label :for="'label' + i">
                                        <img v-if="i != 26 || i === 0" :src="'https://cdn.nightriderz.world/images/website/icon-persona/' + i + '.jpg'" width="60" height="60">
                                    </label>
                                    <input type="radio" :ID="'label' + i" name="avatar" @click="avatar = i">
                                </span>
                            </div>
                            <v-btn variant="tonal" @click="save">Create</v-btn>

                        </v-card-text>
                    </v-card>
                </v-window-item>
            </v-window>

        </v-card-text>
    </v-card>
</v-dialog>

<v-dialog transition="dialog-bottom-transition" :width="520" v-model="dialog" scrollable>
    <v-card>
        <v-toolbar dark color="primary">
            <v-avatar @click="dialog = true" :image="'https://cdn.nightriderz.world/images/website/icon-persona/' + Driver.persona.iconIndex + '.jpg'"></v-avatar>
            <v-toolbar-title>{{Driver.persona.name}}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn @click="dialogswitch = true" icon="mdi-account-switch" title="Switch Driver"></v-btn>
            <template v-slot:extension>
                <v-tabs v-model="model" centered>
                    <v-tab value="1">
                        Driver
                    </v-tab>
                    <v-tab value="4">
                        Prestige
                    </v-tab>
                    <v-tab value="2">
                        Redeem code
                    </v-tab>
                    <v-tab value="3">
                        Konopa Bank
                    </v-tab>
                </v-tabs>
            </template>
        </v-toolbar>

        <v-window v-model="model">
            <v-window-item value="1">
                <v-card>
                    <v-card-text>
                        <PersonaForm />
                    </v-card-text>
                </v-card>
            </v-window-item>
            <v-window-item value="2">
                <v-card>
                    <v-card-text>
                        <RedeemcodeForm />
                    </v-card-text>
                </v-card>
            </v-window-item>
            <v-window-item value="3">
                <v-card>
                    <v-card-text>
                        <KonopabankForm />
                    </v-card-text>
                </v-card>
            </v-window-item>
            <v-window-item value="4">
                <v-card>
                    <v-card-text>
                        <prestigeForm />
                    </v-card-text>
                </v-card>
            </v-window-item>
        </v-window>

    </v-card>
</v-dialog>
</template>

<style>
.v-list-item__prepend {
    font-size: 30px !important;
    margin-top: 10px;
}
</style><style scoped>
#menu {
    margin-bottom: 10px !important;
}

.downloadbtn {
    display: flex;
    justify-content: center;
    margin: 10px 0;
}

button {
    margin: initial !important;
}

.grid {
    display: flex;
    flex-wrap: wrap;
    height: 200px;
    overflow-y: scroll;
    overflow-x: hidden;
    width: 100%;
    margin-bottom: 20px;
    margin-top: 5px;
}

.item26 {
    display: none;
}

[type='radio'] {
    position: absolute;
    left: 2px;
    top: 2px;
}

.grid span {
    margin: 5px;
}
</style><style>
.v-list-item-title {
    margin: 10px 0 !important;
}
</style>
