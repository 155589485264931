<script setup>
import {
    ref,
    toRefs,
    computed,
    getCurrentInstance
} from 'vue'
import CarDetails from './CarDetails.vue'

const props = defineProps({
    id: Number,
    dialog: Boolean,
    hash: String,
    race: Object
})
const {
    id,
    dialog,
    hash,
    race
} = toRefs(props)


const emit = defineEmits(['closeDialog'])
const closeDialog = () => emit('closeDialog')
</script>

<template>
    <v-dialog transition="dialog-bottom-transition" v-model="dialog" @click:outside="closeDialog" :width="500" :height="1000">
  <CarDetails :hash="hash"/>
  </v-dialog>
</template>
<!--
<template>
<div class="text-center">
    <v-dialog transition="dialog-bottom-transition" v-model="dialog" @click:outside="closeDialog" :width="1100" :height="550">

        <v-toolbar dark color="primary">
            <v-icon>mdi-car-cog</v-icon>
            <v-toolbar-title class="test">{{ race.car.manufactor + ' ' + race.car.model }}</v-toolbar-title>
            <v-spacer></v-spacer>
           
        </v-toolbar>
        <v-card class="card" :style="'background: url(' + 'https://cdn.nightriderz.world/images/website/moonset/cars/' + race.car.name + '.png' + ') center center;background-size:cover;'">
            <div class="parts d-flex">
              <div class="d-flex flex-column" v-if="hash">
                    <v-spacer></v-spacer>
                    <div v-for="perf in data.SKILLMODPART">
                      <v-tooltip
                        activator="parent"
                        location="end"
                      >{{ perf.longDescription }}</v-tooltip>
                        <img class="icon" :src="'https://cdn.nightriderz.world/images/website/moonset/parts/skill/' + perf.icon + '.png'">
                    </div>
                    <div class="cat">
                      <img src="https://cdn.nightriderz.world/images/website/moonset/parts/SKILLMOD.png">
                    </div>
                </div>
                <div class="d-flex flex-column" v-if="hash">
                    <v-spacer></v-spacer>
                    <div v-for="perf in data.VISUALPART">
                      <v-tooltip
                        activator="parent"
                        location="end"
                      >{{ perf.entitlementTag.replaceAll('_', ' ') }}</v-tooltip>
                        <img class="icon" :src="'https://cdn.nightriderz.world/images/website/moonset/parts/visual/' + perf.icon + '.png'">
                    </div>
                    <div class="cat">
                      <img src="https://cdn.nightriderz.world/images/website/moonset/parts/AFTERMARKETPART.png">
                    </div>
                </div>
                <div class="d-flex flex-column" v-if="hash">
                    <v-spacer></v-spacer>
                    <div v-for="perf in data.PERFORMANCEPART">
                      <v-tooltip
                        activator="parent"
                        location="end"
                      >{{ perf.longDescription }}</v-tooltip>
                        <img class="icon" :src="'https://cdn.nightriderz.world/images/website/moonset/parts/perf/' + perf.icon + '.png'">
                    </div>
                    <div class="cat">
                      <img src="https://cdn.nightriderz.world/images/website/moonset/parts/PERFORMANCEPART.png">
                    </div>
                </div>
                <div class="nohash" v-if="!hash">
                  <div>The time made with this car has been done<br>before we started saving the setup of cars.</div>
                </div>
            </div>

            <v-spacer></v-spacer>

            <div class="d-flex flex-column">
              <v-spacer></v-spacer>
              <div class="class">
                <img :src="'https://cdn.nightriderz.world/images/website/moonset/classes/' + carClasshHash(data.carClassHash ?? race.car.class) + '.png'" height="45" width="45">
                <v-tooltip
                        activator="parent"
                        location="start"
                      >Class {{ carClasshHash(data.carClassHash ?? race.car.class) }}</v-tooltip>
                <v-spacer></v-spacer>
                <span>{{ data.carRating ?? race.car.rating }}</span>
                <v-spacer></v-spacer>
              </div>
            </div>
            

        </v-card>

    </v-dialog>
</div>
</template>
-->
<!--
<style scoped>

.nohash {
  position: absolute;
  bottom: 5px;
  left: 10px;
}
.v-card {
  flex-direction: row!important;
}
.card {
    height: 550px;
}

.parts {
    margin-left: 8px;
    height: 100%;
}

.parts .icon {
    height: 45px;
    padding: 2px;
    margin: 0 5px;
    background: var(--primary-color);
    border-radius: 0.250rem;
}

.cat {
  padding: 2px;
  margin: 5px;
  background: var(--primary-color);
  border-radius: 0.250rem;
  height: 45px;
  width: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.class {
  background: var(--primary-color);
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
    padding: 2px;
    margin: 5px 0px 5px 5px;
    display: flex;
    align-items: center;
    width: 115px;
    font-size: 22px;
}

</style>
-->