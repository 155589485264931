<script setup>
import {
    ref,
    getCurrentInstance
} from 'vue'
import {
    DriverStore
} from '../pinia/Driver.js'

const Driver = DriverStore()
const {
    proxy
} = getCurrentInstance()

function PrestigeUp() {
    proxy.$http.post("", JSON.stringify({
        serviceName: "account",
        methodName: "PrestigeUp",
        parameters: []
    })).then(response => {

        if (response.data) {
        }

    }).catch(function (response) {

    })
}
</script>

<template>
<v-form>
    <p>Here you can see your prestige and prestige up when you can. The current maximum prestige level is <b>5</b>.</p>
    <br>
    <v-container>
        <v-row><p></p>
            <div class="flexform">
                <div>
                    Prestige level: <b>{{ Driver.persona.prestige }}</b>
                    <br>
                    Next prestige level in <b>{{ 100 - Driver.persona.level }} LEVEL{{(100 - Driver.persona.level) > 1 ? 'S' : ''}}</b>
                </div>
                <v-spacer></v-spacer>
                <div>
                    <v-btn :loading="false" variant="tonal" :disabled="Driver.persona.level == 100 && Driver.persona.prestige < 5 ? false : true" @click="PrestigeUp()">PRESTIGE UP</v-btn>
                </div>

            </div>
        </v-row>
    </v-container>
</v-form>
</template>

<style scoped>
.flexform {
    margin-top: 10px;
    display: flex;
    align-items: center;
    flex: 1;
}

.error {
    margin-bottom: 25px !important;
}

hr {
    margin: 20px 0;
}

.grid {
    display: flex;
    flex-wrap: wrap;
    height: 200px;
    overflow-y: scroll;
    overflow-x: hidden;
    width: 100%;
    margin-bottom: 20px;
    margin-top: 5px;
}

.item26 {
    display: none;
}

[type='radio'] {
    position: absolute;
    left: 2px;
    top: 2px;
}

.grid span {
    margin: 5px;
}
</style><style>
.v-input__details {
    padding-inline-start: 2px !important;
}
</style>
