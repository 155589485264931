<script setup>

</script>

<template>
<v-card class="card">

    <Skeletor width="100%" height="145" />

</v-card>
</template>

<style scoped>

.card {
    background: var(--primary-color);
    overflow: hidden;
    width: 340px!important;
    background-color: var(--primary-color);
    margin: 11px;
    height: 145px;
}
</style>
