<script setup>
import {
    ref,
    getCurrentInstance,
    watch
} from 'vue'

import {
    useRoute,
    useRouter
} from 'vue-router'
import statsDriver from "../components/statsDriver.vue"
import carCardDrive from "../components/carCardDriver.vue"
import CarDialog from "../pages/CarDialog.vue"
import AchievementDriver from '../components/AchievementDriver.vue'
import ActivityDriver from '../components/ActivityDriver.vue'
import DriverCard from '../components/driverCard.vue'
import SKcarCard from '../components/skeletons/SKcarCard.vue'
import PayPalOffer from '../components/PayPalOffer.vue'
import { DriverStore } from '../pinia/Driver.js'
import LeaderboardDriver from '../components/LeaderboardDriver.vue'
import LeaderboardRaceDriver from '../components/LeaderboardRaceDriver.vue'
import rankedDriver from '../components/rankedDriver.vue'

const Driver = DriverStore()
const route = useRoute()
const router = useRouter()
const {
    proxy
} = getCurrentInstance()
const driver = ref([])
const activity = ref([])
const cars = ref([])
const friends = ref([])
const achievements = ref([])
const car_id = ref(0)
const tab = ref(route.params.tab ? route.params.tab : '')
const dialog = ref(false)
const paypal = ref(false)
const driverid = ref()

watch(route, (newValue, oldValue) => {
    if (newValue.params.name != driver.value.name) {
        getPlayer()
        tab.value = 'stats'
    }
})
getPlayer()

async function getPlayer() {
    const res = await proxy.$api({
        "serviceName": "players",
        "methodName": "GetPlayerNext",
        "parameters": [route.params.name]
    })
    driver.value = res
    driverid.value = res.ID
    getActivity(res.ID)
    getCars(res.ID)
    getAchievements(res.ID)
    getFriends(res.ID)
}

async function getActivity(id) {
    const res = await proxy.$api({
        "serviceName": "players",
        "methodName": "GetActivityNext",
        "parameters": [id]
    })
    activity.value = res
}

async function getFriends(id) {
    const res = await proxy.$api({
        "serviceName": "players",
        "methodName": "getFriends",
        "parameters": [id]
    })
    friends.value = res
}

async function getCars(id) {
    const res = await proxy.$api({
        "serviceName": "players",
        "methodName": "GetCarsNext",
        "parameters": [id]
    })
    cars.value = res
}

async function getAchievements(id) {
    const res = await proxy.$api({
        "serviceName": "players",
        "methodName": "GetAchievements",
        "parameters": [id]
    })
    achievements.value = res
}

function goback() {
    router.push('/drivers')
}

function dialogData(e) {
    car_id.value = parseInt(e)
    dialog.value = true
}

function tabChanged() {
    router.push('/driver/' + driver.value.name + '/' + tab.value)
}

async function capture(data) {

    const res = await proxy.$api({
        serviceName: "paypal",
        methodName: "verifyOffer",
        parameters: [data.orderID, driver.value.ID]
    })
    if (res == "ok") {
        paypal.value = false
        getPlayer()
    }

}
async function deleteall() {
    if (confirm("Confirm delete all times?")) {
        const res = await proxy.$api({
            serviceName: "players",
            methodName: "DeleteTimes",
            parameters: [driver.value.ID]
        })
        callCards()
    }
}
</script>

<template>
<v-app-bar :elevation="2">
    <v-avatar :image="'https://cdn.nightriderz.world/images/website/icon-persona/' + driver.iconIndex + '.jpg'"></v-avatar>
    <v-app-bar-title>                            {{ driver.tag !== null ? '[' + driver.tag + '] ' : '' }}
                            {{ driver.name }}</v-app-bar-title>
    <v-spacer></v-spacer>
    <td class="align-end" style="margin-right:1%" v-if="Driver.user.isAdmin == '1'"><v-btn variant="tonal" color="error" @click="deleteall()">DELETE ALL TIMES</v-btn></td>
    <router-link :to="'/driver/' + other.name" v-for="other in driver.otherpersona">
        <v-btn class="other" variant="tonal">
            {{ other.name }}
        </v-btn>
    </router-link>
    <template v-slot:extension>
        <v-tabs v-model="tab" @click="tabChanged">
            <v-tab value="stats">Statistics</v-tab>
            <v-tab value="times">Times</v-tab>
            <v-tab value="ranked">Ranked</v-tab>
            <v-tab value="activity">Activity</v-tab>
            <v-tab value="friends">Friends ({{ friends.length }})</v-tab>
            <v-tab value="cars">Cars ({{ driver.cars }})</v-tab>
            <v-tab value="achievements">Achievements ({{ achievements.length }})</v-tab>
        </v-tabs>
        <v-spacer></v-spacer>
        <v-btn v-if="driver.premium == '0' && Driver.logged" @click="paypal = true" prepend-icon="mdi-gift-outline" id="menu">Gift Premium
        </v-btn>
        
        <v-dialog transition="dialog-bottom-transition" :width="500" v-model="paypal" scrollable>
            <v-card><v-card-text>
                <PayPalOffer @capture="capture" />
        </v-card-text>
            </v-card>
        </v-dialog>
    </template>
</v-app-bar>

<CarDialog v-if="dialog" :dialog="dialog" :id="car_id" @close-dialog="dialog = false" />

<div class="driver">

    <div class="right">

        <v-window v-model="tab">
            <v-window-item value="stats">
                <statsDriver :data="driver" />
            </v-window-item>
            <v-window-item value="times">
                <LeaderboardRaceDriver v-if="driverid && route.params.race_id" :data="driverid" :race_id="route.params.race_id"/>
                <span v-else><LeaderboardDriver v-if="driverid" :data="driverid"/></span>
                
                
            </v-window-item>
            <v-window-item value="ranked">
                <rankedDriver :data="driver"/>
            </v-window-item>
            <v-window-item value="activity">
                <ActivityDriver v-if="activity.length > 0" :activity="activity" style="padding-top: 24px;padding-bottom: 24px;padding-left: 24px;padding-right: 24px;"/>
            </v-window-item>
            <v-window-item value="friends">
                <div class="grid" style="max-width: initial;margin-top: 20px;">
                    <DriverCard v-for="friend in friends" :data="friend" />
                </div>
            </v-window-item>
            <v-window-item value="cars">
                <transition name="fade" mode="out-in">
                    <div key=1 class="grid" v-if="!cars.length">
                        <SKcarCard v-for="i in 15" />
                    </div>

                    <div key=2 class="grid" v-else>
                        <carCardDrive v-for="car in cars" :data="car" @dialogData="dialogData" />
                    </div>
                </transition>
            </v-window-item>

            <v-window-item value="achievements">
                <div v-if="cars.length > 0" class="grid" style="max-width: initial;margin-top: 20px;">
                    <AchievementDriver v-for="achievement in achievements" :data="achievement" />
                </div>
            </v-window-item>
        </v-window>

    </div>

</div>
</template>

<style scoped>

#menu {
    background: linear-gradient(90deg, rgb(255 165 0) 0%, rgb(255 235 0) 100%) !important;
}
.other {
    margin-left: 5px;
}

.back {
    margin-right: 10px;
}

.driver {
    display: flex;
}

.v-card {
    background-color: var(--primary-color);
}

.v-timeline::v-deep(.v-timeline-divider__before),
.v-timeline::v-deep(.v-timeline-divider__after) {
    background: white;
}

.align-right {
  display: flex;
  justify-content: flex-end;
}

.v-timeline {
    justify-content: left;
}

.left {
    width: 300px;
}

.right {
    flex: 1;
}

.grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 20px;
    margin: 0 auto;
}
</style>