<script setup>
import { toRefs, computed } from 'vue'

// Define the component's props
const props = defineProps({
    activity: Array // Assuming activity is an array based on your loop in the template
})

// Destructure activity from props and convert it into a reactive reference
const { activity } = toRefs(props)

// Create a computed property that processes each item in the activity array
// It removes content inside parentheses from act.t3
const removeParentheses = computed(() => {
  return activity.value.map(act => ({
    ...act,
    t3: act.t3.replace(/\(.*?\)/g, '') // Use a regular expression to remove text within parentheses
  }));
});
</script>

<template>
  <div>
    <v-row>
      <v-col cols="2" md="2" v-for="act in removeParentheses" :key="act.id" style="width: 100px!important;">
        <!-- Conditionally apply the href only if act.t4 is 'race' -->
        <v-card :style="{ background: '#11141c' }" :href="act.t4 === 'race' ? '/leaderboard/' + act.t5 : undefined">
          <v-card-title>
            <v-icon style="margin-top: -5px;">
              mdi-car
            </v-icon>
            <span style="margin-left: 8px; margin-top: 20px;font-size: 18px;"><timeago :datetime="act.t2" /></span>
          </v-card-title>
          <v-card-text>
            <div class="d-flex justify-start align-center">
              <strong class="me-2"></strong>
              <div style="font-size: 12px;">
                <!-- Use act.t3 from removeParentheses which has the parentheses content removed -->
                <span v-if="act.t4 == 'race'">Finished <b>{{ act.t3 }}</b></span>
                <span v-else>Bought the car <b>{{ act.t1 }}</b></span>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<style scoped>
</style>
