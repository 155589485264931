<script setup>
import { ref } from 'vue'
import accountForm from '../components/accountForm.vue'
import DiscordForm from '../components/discordForm.vue'
import PremiumForm from '../components/premiumForm.vue'
import benefits from '../configs/premium.json'
import {
    DriverStore
} from '../pinia/Driver.js'
const Driver = DriverStore()

var dialog = ref(false)
var model = ref()
</script>

<template>

<v-btn
    @click="dialog = true"
        id="menu"
        icon="mdi-cog">
    </v-btn>

  <v-dialog transition="dialog-bottom-transition" :width="500" v-model="dialog" scrollable>
    <v-card>
      <v-toolbar dark color="primary">
        <v-icon>mdi-cog</v-icon>
        <v-toolbar-title>Settings</v-toolbar-title>
        <v-spacer></v-spacer>

        <template v-slot:extension>
          <v-tabs v-model="model" centered>
            <v-tab value="1">
              Account
            </v-tab>
            <v-tab value="2">
              Discord
            </v-tab>
            <v-tab value="3">
              Premium
            </v-tab>
          </v-tabs>
        </template>

      </v-toolbar>

      <v-window v-model="model">
        <v-window-item value="1">
          <v-card>
            <v-card-text>
              <accountForm />
            </v-card-text>
          </v-card>
        </v-window-item>
        <v-window-item value="2">
          <v-card>
            <v-card-text>
              <DiscordForm />
            </v-card-text>
          </v-card>
        </v-window-item>
        <v-window-item value="3">
          <v-card>
            <v-card-text v-if="Driver.user.premium == '0'" style="font-size:0.7rem">
              <v-list lines="one">
                            <v-list-item v-for="benefit in benefits" :title="benefit.title">
                                <span v-html="benefit.text"></span>
                                <template v-slot:prepend>
                                    <v-icon :icon="'mdi-' + benefit.icone"></v-icon>
                                </template>
                            </v-list-item>
                        </v-list>
                        <br>
             <PremiumForm />
            </v-card-text>
            <v-card-text v-else="Driver.user.premium == '1'" style="font-size:0.7rem">
              <p>The following benefits have been applied and are active as you have Premium membership on this account.</p>
              <v-list lines="one">
                            <v-list-item v-for="benefit in benefits" :title="benefit.title">
                                <span v-html="benefit.text"></span>
                                <template v-slot:prepend>
                                    <v-icon :icon="'mdi-' + benefit.icone"></v-icon>
                                </template>
                            </v-list-item>
                        </v-list>
            </v-card-text>
          </v-card>
        </v-window-item>
      </v-window>

    </v-card>
  </v-dialog>
</template>
<style>
.v-list-item__prepend {
  font-size: 30px!important;
  margin-top: 10px;
}
</style>
<style scoped>

#menu {
  margin-bottom: 10px!important;
}
.downloadbtn {
  display: flex;
  justify-content: center;
  margin: 10px 0;
}

span {
  margin: 0 0px;
}

button {
  margin: initial !important;
}
</style>

<style>
.v-list-item-title {
  margin: 10px 0 !important;
}
</style>