<script setup>
import {
    ref,
    getCurrentInstance
} from 'vue'
import {
    DriverStore
} from '../pinia/Driver.js'
const Driver = DriverStore()

const {
    proxy
} = getCurrentInstance()
const premium = ref(false)
const crew = ref(false)

async function unlinkdiscord() {
    const res = await proxy.$api({
        serviceName: "account",
        methodName: "unlinkdiscord"
    })
    Driver.user.discord = null
}

async function getRolePremium() {
    premium.value = true
    premium.value = await getRole('premium')
}

async function getRoleCrew() {
    crew.value = true
    crew.value = await getRole('crew')
}

async function getRole(role) {
    const res = proxy.$api({
        serviceName: "account",
        methodName: "getRole",
        parameters: [role]
    })
    return false
}
</script>

<template>
<div class="discord">
    <div v-if="Driver.user.discord == null">
        <a onclick="open('https://api.nightriderz.world/discord-moonset.php?key=' + localStorage.getItem('easharpptr_u'), 'Discord', 'scrollbars=1,resizable=1,height=700,width=500')">
            <v-btn variant="tonal">Login with Discord</v-btn>
        </a>
    </div>

    <div v-else>

        <div v-if="Driver.user.discord != null">
            <v-text-field prepend-icon="mdi-discord" v-model="Driver.user.discord_name" label="Discord" variant="outlined" disabled></v-text-field>

            <h5>Roles</h5>
            <div class="roles d-flex">
                <v-btn v-if="Driver.user.premium" :loading="premium" :disabled="premium" @click="getRolePremium" variant="tonal">
                    <p style="font-size:1.7rem;margin-top: -0.2vh;color:#f0ab00">●</p> Premium
                </v-btn>
                <span v-if="Driver.currentCrew">
                    <v-btn v-if="Driver.currentCrew.ownerUserId == Driver.persona.ID" :loading="crew" :disabled="crew" @click="getRoleCrew" variant="tonal">
                    <p style="font-size:1.7rem;margin-top: -0.2vh;color:#3ba370">●</p> Crew Owner
                    </v-btn>
                </span>
            </div>
            <span style="opacity:0.15;font-size:0.65rem">Click on the roles to enable them on Discord.</span>

            <h5>Manage connection</h5>
            <div class="d-flex">
                <v-btn @click="unlinkdiscord" variant="tonal">unlink my account</v-btn>
            </div>

        </div>

    </div>

</div>
</template>

<style scoped>
.discord {
    margin: 10px 0;
}

.roles button {
    margin-right: 10px;
}

@keyframes loader {
    from {
        transform: rotate(0);
    }

    to {
        transform: rotate(360deg);
    }
}
</style>
