<script setup>
import {
  getCurrentInstance,
  ref,
  computed
} from 'vue'
import crewCard from '../components/crewCard.vue'
import CreateCrew from './CreateCrew.vue'
import { DriverStore } from '../pinia/Driver.js'

const Driver = DriverStore()
const {
  proxy
} = getCurrentInstance()

const inputsearch = ref("")
var crews = ref([])

function search(e) {
  inputsearch.value = e
}

callCards()

async function callCards() {
  const res = await proxy.$api({
    serviceName: "crew",
    methodName: "GetCards",
    parameters: []
  })
  crews.value = res
}

const computedCrews = computed(() => {
  var tempCrew = crews.value

  if (inputsearch.value)
    return tempCrew.filter(i => i.name.toLowerCase().includes(inputsearch.value.toLowerCase()))
  else
    return crews.value
})

const showingMoulaPopup = ref(false)

function showMoulaPopup() {
  showingMoulaPopup.value = true
}
</script>
<template>
  <v-app-bar :elevation="2">
    <v-icon icon="mdi-account-group"></v-icon>
    <v-app-bar-title>Crews</v-app-bar-title>
    <v-spacer></v-spacer>
    <CreateCrew
      v-if="Driver.persona.boost >= 40000 && Driver.crew.can_join && Driver.persona.level >= 30 && Driver.persona.personaAge >= 30" />
    <v-btn prepend-icon="mdi-help-circle" variant="tonal"
      v-if="(Driver.persona.boost < 40000 || Driver.persona.level < 30 || Driver.persona.personaAge < 30) && Driver.crew.can_join"
      @click="showMoulaPopup">
      HOW TO CREATE A CREW
    </v-btn>
    <v-btn :to="'/crew/headquarters/' + Driver.crew.current.id" prepend-icon="mdi-account-group" variant="tonal" v-if="(Driver.crew.can_join == false)">
      {{Driver.crew.current.name}}'s CREW PAGE
    </v-btn>
  </v-app-bar>

  <div class="d-flex" style="margin: 30px 30px 0px 30px; justify-content: center;align-items: center;">
    <v-text-field @update:model-value="search" style="flex:1;margin-right: 20px;" label="Search a crew"
      prepend-icon="mdi-magnify" variant="underlined"></v-text-field>
  </div>

  <div class="grid">
    <crewCard :key="crew.crewid" :data="crew" v-for="crew in computedCrews" />
  </div>

  <v-dialog v-model="showingMoulaPopup" transition="dialog-bottom-transition" :width="500">
    <v-card>
      <v-toolbar dark color="primary">
        <v-icon>mdi-help-circle</v-icon>
        <v-toolbar-title>How to create a crew ?</v-toolbar-title>
      </v-toolbar>
      <v-card-text style="font-size: 14px;">
        In order to create a crew you have to fill all the conditions below.
      </v-card-text>
      <v-card-text style="font-size: 14px;">
        <v-icon v-if="Driver.persona.boost >= 40000" style="color:green">mdi-check-circle</v-icon><v-icon v-if="Driver.persona.boost < 40000" style="color:red">mdi-close-circle</v-icon> - Have minimum <b>40,000 SB</b> on your current driver wallet.
        <br>
        <v-icon v-if="Driver.persona.level >= 30" style="color:green">mdi-check-circle</v-icon><v-icon v-if="Driver.persona.level < 30" style="color:red">mdi-close-circle</v-icon> - Be at least <b>level 30</b> on your current driver.
        <br>
        <v-icon v-if="Driver.persona.personaAge >= 30" style="color:green">mdi-check-circle</v-icon><v-icon v-if="Driver.persona.personaAge < 30" style="color:red">mdi-close-circle</v-icon> - Your current driver profile must exist since at least <b>30 days</b>.
        <br>
        <br>
        <br>
        Once all these requirements are validated, you will see the button <b>CREATE CREW</b> in the top right corner of
        this page.
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<style scoped>
.grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  background: var(--secondary-color);
  margin-top: 20px;
}
</style>
  