<script>
import {
    loadScript
} from '@paypal/paypal-js'

export default {
    name: 'Checkout-Payment',
    beforeCreate: function () {
        loadScript({
            'client-id': CLIENT_ID,
            'disable-funding': "credit,card,venmo,sepa,bancontact,eps,giropay,ideal,mercadopago,mybank,p24,sofort",
            'currency': "EUR",
            'components': "buttons"
        }).then((paypal) => {
            paypal
                .Buttons({
                    createOrder: this.createOrder,
                    onApprove: this.onApprove,
                    style: {
                        color: "black"
                    }
                }).render('#paypal-button-container');
        });
    },
    data() {
        return {
            paid: false
        };
    },
    props: {
        cartTotal: {
            type: Number,
            default: 5.99,
        },
    },
    methods: {
        createOrder: function (data, actions) {
            return actions.order.create({
                purchase_units: [{
                    amount: {
                        value: this.cartTotal,
                    },
                    description: 'NIGHTRIDERZ / PREMIUM',
                    soft_descriptor: 'NIGHTRIDERZ / PREMIUM'
                }],
                application_context: {
                    "shipping_preference": "NO_SHIPPING"
                }
            });
        },
        onApprove: function (data, actions) {
            return actions.order.capture().then(() => {
                this.$emit('capture', data)
            });
        },
    },
};
const CLIENT_ID = 'ASW5Xo3DdqlQpGgX0Is_tdsfnB2nbL5xxW-kuJkdcJjN1f9lKBkH1SNKxGapUwdKfuxbnovjSW-1Z3hX';
</script>

<template>
<div v-if="!paid" id="paypal-button-container"></div>
<div v-else id="confirmation">Order complete!</div>
</template>

<style>

</style>